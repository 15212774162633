const Sentry = require('@sentry/browser');

/*eslint "angular/document-service": 0, "angular/timeout-service": 0, "angular/window-service": 0, angular/component-limit: ['error', 2]*/
(function () {
    'use strict';

    angular.module('salesflare', [
        'ngMaterial',
        'ngSanitize',
        'ngMessages',
        'ngCookies',
        'ui.router',
        'ui.router.upgrade',
        'ngDraggable',
        'as.sortable',
        'ngPassword',
        'ngFileUpload',
        'md.data.table',
        'md.time.picker',
        'salesflare.components.activityPerRep',
        'salesflare.components.addresses',
        'salesflare.components.avatar',
        'salesflare.components.card',
        'salesflare.components.closingPercentage',
        'salesflare.components.closingPercentagePerRep',
        'salesflare.components.contextualHelp',
        'salesflare.components.credits',
        'salesflare.components.bulkEditAccounts',
        'salesflare.components.bulkEditContacts',
        'salesflare.components.bulkEditOpportunities',
        'salesflare.components.bulkEditTasks',
        'salesflare.components.bulkToolbar',
        'salesflare.components.chart',
        'salesflare.components.chartBuilder',
        'salesflare.components.chipClass',
        'salesflare.components.createAccount',
        'salesflare.components.creditPackageConfirmationDialog',
        'salesflare.components.customfield',
        'salesflare.components.customfields',
        'salesflare.components.customfieldsDisplay',
        'salesflare.components.deals',
        'salesflare.components.dashboard',
        'salesflare.components.dashboardEdit',
        'salesflare.components.dashboards',
        'salesflare.components.dashboards.filterDialog',
        'salesflare.components.discardDialog',
        'salesflare.components.email',
        'salesflare.components.downgradeConfirmationDialog',
        'salesflare.components.emailCompose',
        'salesflare.components.emailDisplay',
        'salesflare.components.emptystate',
        'salesflare.components.fakeSticky',
        'salesflare.components.files',
        'salesflare.components.filesCustomfield',
        'salesflare.components.filesCustomfieldDisplay',
        'salesflare.components.filters',
        'salesflare.components.formErrorIndicator',
        'salesflare.components.funnelAnalysis',
        'salesflare.components.import',
        'salesflare.components.largerprospectfollowup',
        'salesflare.components.onLongPress',
        'salesflare.components.opportunity',
        'salesflare.components.passwordmeter',
        'salesflare.components.person',
        'salesflare.components.phoneNumber',
        'salesflare.components.pivotTable',
        'salesflare.components.planflag',
        'salesflare.components.plans',
        'salesflare.components.mainmenu',
        'salesflare.components.messageBox',
        'salesflare.components.moveReportDialog',
        'salesflare.components.mrr',
        'salesflare.components.notifications',
        'salesflare.components.permissions',
        'salesflare.components.pricingSwitch',
        'salesflare.components.quotaPerRep',
        'salesflare.components.reportEdit',
        'salesflare.components.reportFilterDialog',
        'salesflare.components.reportView',
        'salesflare.components.reorderDashboardsDialog',
        'salesflare.components.restrictedDialog',
        'salesflare.components.revenueVsGoal',
        'salesflare.components.revenueVsPreviousPeriod',
        'salesflare.components.scorecard',
        'salesflare.components.setup',
        'salesflare.components.setupPanel',
        'salesflare.components.sidebar',
        'salesflare.components.slippingDeals',
        'salesflare.components.socialFeed',
        'salesflare.components.socialProfiles',
        'salesflare.components.stageUpdates',
        'salesflare.components.statusBar',
        'salesflare.components.tags',
        'salesflare.components.task',
        'salesflare.components.tasks',
        'salesflare.components.templates.templateSelection',
        'salesflare.components.templates.templateManager',
        'salesflare.components.topEarningAccounts',
        'salesflare.components.topLeadSources',
        'salesflare.components.topLostReasons',
        'salesflare.components.upgradeConfirmationDialog',
        'salesflare.components.upgradeDialog',
        'salesflare.components.walkthrough',
        'salesflare.components.workflowUtils.exitWorkflowRecordButton',
        'salesflare.components.workflowCompose',
        'salesflare.components.workflow',
        'salesflare.components.workflows',
        'salesflare.components.workflowAnalytics',
        'salesflare.components.workflowStepAnalytics',
        'salesflare.components.emailActionCompose',
        'salesflare.downgraded.services.matDialogService',
        'salesflare.downgraded.services.zoneService',
        'minicolors',
        'angular-loading-bar',
        'chart.js',
        'uiCropper',
        'swipe',
        'swipe.counter',
        'ngFileUpload',
        'zxcvbn'
    ]).config(function appConfig($provide, $mdThemingProvider, $compileProvider, cfpLoadingBarProvider, $mdDateLocaleProvider, $locationProvider, dateFilterProvider, $mdInkRippleProvider, config) {

        // Put it back like 1.5 so all urls keep working
        $locationProvider.hashPrefix('');

        $compileProvider.debugInfoEnabled(config.debugInfoEnabled);

        if (config.sentry.enabled) {
            $provide.decorator('$exceptionHandler', ['$delegate', function exceptionHandlerSentryDecorator($delegate) {

                return function (exception, cause) {

                    // Skip not useful error
                    if (angular.isString(exception)) {
                        if (exception === 'Possibly unhandled rejection: undefined') {
                            return $delegate(exception, cause);
                        }

                        // These get logged when the api is down/unreachable but they spam Sentry on staging during deploying so ignoring for now
                        if (exception.includes('Possibly unhandled rejection') && exception.includes('"status":-1')) {
                            return $delegate(exception, cause);
                        }
                    }
                    else if (exception instanceof Error) {
                        // Sentry ignore draggable error on pipeline settings page as we can ignore it but is spams
                        if (exception.message.includes('asSortableItemHandle')) {
                            return $delegate(exception, cause);
                        }

                        // Sentry ignore filter service error where model.me is undefined because it spams
                        if (exception.message.includes('Model not available in filter service')) {
                            return $delegate(exception, cause);
                        }
                    }

                    Sentry.withScope(function (scope) {

                        if (cause) {
                            scope.setExtra('cause', cause);
                        }

                        // Attach extra properties we set on error objects to the scope
                        // Otherwise they don't show up in Sentry
                        // Object.keys negates the default message and stack property since they are on the prototype
                        // Negate anything that is not an object since doing Object.keys on a string returns an array of numbers equal to the length of the string, which kills Sentry on a long string
                        if (angular.isObject(exception)) {
                            scope.setExtras(exception);
                        }

                        Sentry.captureException(exception);
                    });

                    return $delegate(exception, cause);
                };
            }]);
        }

        $mdThemingProvider
            .theme('default')
            .primaryPalette('light-blue')
            .accentPalette('light-blue', {
                default: '600'
            })
            .warnPalette('red');

        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            $mdInkRippleProvider.disableInkRipple();
        }

        $mdDateLocaleProvider.formatDate = function (date) {

            return date ? dateFilterProvider.$get()(date, 'MMMM d, y') : '';
        };

        cfpLoadingBarProvider.includeBar = false;
        cfpLoadingBarProvider.includeSpinner = true;
    }).run(function appRun($http, config, $mdUtil, utils, stripeService, draftsService) {

        // Temp fix to prevent browsers from using `position: sticky`
        $mdUtil.checkStickySupport = angular.noop;

        // Set publishable Stripe key depending on environment
        if (window.Stripe) {
            stripeService.init(config.stripe.key);
        }

        // Temp workaround for iOS/Cordova issue that leaves the viewport shifted when hiding keyboard. Can be removed if actual issue is fixed.  (also see routing.js)
        // See https://github.com/apache/cordova-ios/issues/417
        // TODO: remove this when actual fix is implemented
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            document.addEventListener('focusout', function () {

                // Do on next tick so it doesn't interfere with other code that is handling this event
                // e.g. the md-autocomplete in the md-chips shows a pop out with the options.
                // When not doing this on next tick it will interfere with clicking on one of the options and cause it to not select anything.
                setTimeout(function () {

                    window.scrollTo(0, 0);
                });
            });
        }

        // Default includes a wildcard (*/*), the app by default only handles json
        // see https://docs.angularjs.org/api/ng/service/$http#setting-http-headers
        $http.defaults.headers.common.Accept = 'application/json';

        // Make sure to send the session cookie on all $http requests
        $http.defaults.withCredentials = true;

        // Initialize google analytics
        if (config.google && config.google.appTrackingId) {
            ga('create', config.google.appTrackingId, 'auto');

            // Disable to allow tracking in cordova via file:// protocol
            if (window.isMobile) {
                ga('set', 'checkProtocolTask', null);
                ga('set', 'checkStorageTask', null);
            }
        }

        if (config.segment && config.segment.key) {
            analytics.load(config.segment.key);
        }

        // ClipboardJS somehow needs to be defined before Intercom boot to work
        const clipboard = new ClipboardJS('[data-clipboard-text]');

        clipboard.on('success', function () {

            return utils.showSuccessToast('Copied to clipboard!');
        });

        if (config.intercom) {
            // Make sure Intercom is always there
            return Intercom('boot', {
                app_id: config.intercom.appId,
                custom_launcher_selector: '#intercom-chat-button',
                hide_default_launcher: true // We only ever want to open Intercom via our custom launcher, this prevents the bubble from showing for second and then hiding
            });
        }

        // Get tracking data for the Outlook add-in
        if (config.mode === 'outlook') {
            window.getTeamTrackingData();
        }

        const chartJsAfterRenderPlugin = {
            id: 'afterRender',

            afterRender: function (chart, options) {
                if (!chart.$rendered) {
                    chart.$rendered = true;

                    options.callback();
                }
            }
        };

        Chart.plugins.register(chartJsAfterRenderPlugin);

        // Clean up outdated (older than 3 days) drafts
        draftsService.clearOldDrafts();
    });

    /**
     * Detect if browser does supports touch events or not
     * this is used mainly for disabling hover states that changes the visibility or display property on ios
     * see https://www.nczonline.net/blog/2012/07/05/ios-has-a-hover-problem/ for more info
     *
     * I (Adri) noticed that touch is more logical to work with but I could only get no-touch working for editContacts
     * since it also uses ng-hide which has an !important and messes with the whole thing
     */
    if (('ontouchstart' in document.documentElement) || (window.DocumentTouch && document instanceof DocumentTouch)) {
        document.documentElement.className += ' touch';
        window.touch = true;
    }
    else {
        document.documentElement.className += ' no-touch';
        window.touch = false;
    }

    function onReady() {

        // Let plugins know the event listener is started
        window.parent.postMessage({
            'function': 'eventListenerReady',
            'ready': 'true'
        }, '*');
    }

    if (window.isMobile) {
        return document.addEventListener('deviceready', onReady, true);
    }

    return document.addEventListener('DOMContentLoaded', function () {

        return onReady();
    });
})();

// The event listener is to catch the messages from the Chrome extension (Gmail + LinkedIn)!
addEventListener('message', receiveMessage, false);

// Used to debounce multiple messages if necessary, create a new property for each function that needs debouncing
const debounceTimeouts = {};

function receiveMessage(event) {
    'use strict';

    const functionName = event.data.function;
    const params = [];
    let noFunctionFound = false;

    const debounceObject = {
        debounce: false,
        duration: 500 // Default debounce waiting period
    };

    switch (functionName) {
        case 'openContactByEmail':
            params.push(event.data.email);
            break;
        case 'classifyContacts':
            // We receive a classifyContacts event for every email in a thread, so we only process the last call, otherwise we would potentially get multiple info toasts popping up
            debounceObject.debounce = true;
            params.push(angular.fromJson(event.data.contactInfo));
            break;
        case 'saveMail':
            params.push(event.data.guid, event.data.gmail_message_id);
            break;
        case 'getMergableContact':
            params.push(event.data.email, event.data.reference);
            break;
        case 'openTemplateManager':
            params.push(event.data.templates, event.data.createNew);
            break;
        case 'notificationClick':
            params.push(event.data.id);
            break;
        case 'syncAccount':
            // We use the debounce implementation to replicate the previous implementation of the syncAccount function
            // It just did an extra timeout with duration 0
            // https://github.com/Salesflare/Client/commit/f9a3dfbf0a07f36b18582b7135bbcaf654ec01d8
            debounceObject.debounce = true;
            debounceObject.duration = 0;
            break;
        case 'navigate':
            params.push(event.data.state, event.data.params);
            break;
        case 'handleContextLinkedIn':
        case 'handleContextSidebar':
            debounceObject.debounce = true;
            params.push(angular.fromJson(event.data.contextData));
            break;
        case 'setAutoFindEmailLinkedInSetting':
            params.push(event.data.value);
            break;
        case 'getAutoFindEmailLinkedInSetting':
            break;
        case 'isEventListenerReady':
            break;
        case 'getTeamTrackingData':
            break;
        case 'getTrackingDomains':
            break;
        case 'pluginPreSend':
            params.push(event.data.body, event.data.guid, event.data.trackingEnabled);
            break;
        default:
            noFunctionFound = true;
            break;
    }

    // If the message didn't contain a known function, do nothing
    if (noFunctionFound) {
        return;
    }

    // Debouncing will only handle the last event of a burst, within a certain period of time after the most recently received event was seen
    if (debounceObject.debounce) {
        if (debounceTimeouts[functionName]) {
            clearTimeout(debounceTimeouts[functionName]);
        }

        debounceTimeouts[functionName] = setTimeout(() => {

            callFunctionOnScope(functionName, params);
        }, debounceObject.duration);
    }
    else {
        callFunctionOnScope(functionName, params);
    }
}

// NOTE Global functions are needed for the Outlook Plugin!
const DEFAULT_TIMEOUT = 250;
let angularBody = angular.element(document.body);
let rootScope;

function bodyHasScope() {
    'use strict';

    // Make sure angularBody is there
    if (!angularBody || angularBody.length === 0) {
        angularBody = angular.element(document.body);
    }

    rootScope = angularBody.injector() && angularBody.injector().get('$rootScope');

    return angular.isDefined(rootScope);
}

// Wrapper for functions called by plugins to ensure that the rootScope is available
function callFunctionOnScope(functionName, params) {
    'use strict';

    // Check if scope is available yet
    if (bodyHasScope()) {
        return rootScope[functionName].apply(null, params);
    }

    return setTimeout(() => {

        return callFunctionOnScope(functionName, params);
    }, DEFAULT_TIMEOUT);
}

// NOTE This function is needed for the Outlook Plugin!
let classifyDebounce;
window.classifyContacts = function classifyContacts(contacts) {
    'use strict';

    if (classifyDebounce) {
        clearTimeout(classifyDebounce);
    }

    // We receive a classifyContacts event for every email in a thread, so we only process the last call, otherwise we would potentially get multiple info toasts popping up
    classifyDebounce = setTimeout(() => {

        if (bodyHasScope()) {
            return rootScope.classifyContacts(angular.fromJson(contacts));
        }

        return setTimeout(function () {

            return window.classifyContacts(contacts);
        }, DEFAULT_TIMEOUT);
    }, 500);
};

// NOTE This function is needed for the Outlook Plugin!
window.notificationClick = function notificationClick(id) {
    'use strict';

    if (bodyHasScope()) {
        return rootScope.notificationClick(id);
    }

    return setTimeout(function () {

        return window.notificationClick(id);
    }, DEFAULT_TIMEOUT);
};

// NOTE This function is needed for the Outlook Plugin!
window.getTeamTrackingData = function getTeamTrackingData() {
    'use strict';

    if (bodyHasScope()) {
        return rootScope.getTeamTrackingData();
    }

    return setTimeout(function () {

        return window.getTeamTrackingData();
    }, DEFAULT_TIMEOUT);
};

// NOTE This function is needed for the Outlook Plugin!
window.getTrackingDomains = function getTrackingDomains() {
    'use strict';

    if (bodyHasScope()) {
        return rootScope.getTrackingDomains();
    }

    return setTimeout(function () {

        return window.getTrackingDomains();
    }, DEFAULT_TIMEOUT);
};

// NOTE This function is needed for the Outlook Plugin!
// To make this work so that Outlook can await this function, an object needs to be returned instead of a string
window.pluginPreSend = function pluginPreSend(body, guid, trackingEnabled) {
    'use strict';

    const trackingEnabledBool = trackingEnabled ? trackingEnabled.toLowerCase() === 'true' : false;
    if (bodyHasScope()) {
        return { body: rootScope.pluginPreSend(body, guid, trackingEnabledBool) };
    }

    // Outlook needs this function to be fully sync, so we let Outlook handle the timeout if the rootScope is not available yet
    return { error: 'noScope' };
};
