(function () {
    'use strict';

    angular
        .module('salesflare.components.chart', [
            'salesflare.components.filters'
        ])
        .component('sfChart', {
            templateUrl: 'app-ajs/components/dashboards/chart/chart.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                chartId: '<',
                type: '<',
                pipelineName: '<', // When this is set we are only filtering on 1 specific pipeline, otherwise on all pipelines
                chartData: '<',
                viewBy: '<', // The report field id we're viewing by
                viewByDate: '<',
                viewByDatePeriod: '<',
                segmentBy: '<', // The report field id we're segmenting by
                segmentByDate: '<',
                segmentByDatePeriod: '<',
                stackChart: '<',
                emptyStateText: '<',
                afterRender: '&'
            }
        });

    function controller($mdMedia, $state, $filter, $q, utils, pipelines) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        // Colors of the standard reports (migrated insights) are defined in the SCSS variables
        // @see https://coolors.co/303c4f-2a9d8f-e9c46a-f4a261-e76f51-996ea9
        const palette = ['#303c4f', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51', '#996ea9'];

        // Objects to help with the mapping of labels/data points the x/y axis for different chart types
        const chartLabelMap = {
            bar: 'y',
            column: 'x',
            line: 'x'
        };
        const chartDataMap = {
            bar: 'x',
            column: 'y',
            line: 'y'
        };
        const MAX_VIEW_BY_LABELS = 366;
        const defaultEmptyStateText = 'No data available';
        const unSpecifiedString = 'Not specified';
        const groupOtherSegmentString = 'Other';
        let stagesCache;

        const defaultChartOptions = {
            maintainAspectRatio: false, // Needed to fill up our chart container fully.
            tooltips: {
                titleFontFamily: '\'Source Sans 3\', \'sans-serif\'',
                bodyFontFamily: '\'Source Sans 3\', \'sans-serif\'',
                enabled: true,
                mode: 'single',
                // By default chart.js shows the bottom segment of the chart first in the tooltip
                // We don't want this so we reverse the default sorting order
                itemSort: function (tooltipItemA, tooltipItemB) {

                    return tooltipItemB.datasetIndex - tooltipItemA.datasetIndex;
                },
                callbacks: {
                    title: (tooltipItems) => {
                        return formatNumber(tooltipItems[0].label);
                    },
                    label: (tooltipItem, data) => {

                        const labelToFormat = vm.type === 'bar' ? tooltipItem.xLabel : tooltipItem.yLabel;
                        const datasetLabelToFormat = data.datasets[tooltipItem.datasetIndex].label;

                        let formattedLabel = labelToFormat;

                        if (!Number.isNaN(Number.parseInt(labelToFormat))) {
                            formattedLabel = $filter('numberDecimalsWhenThere')(Number.parseFloat(labelToFormat));
                        }

                        const formattedDatasetLabel = formatNumber(datasetLabelToFormat);

                        if (formattedDatasetLabel) {
                            return (formattedDatasetLabel + ': ' + formattedLabel);
                        }

                        return formattedLabel;
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontFamily: '\'Source Sans 3\', \'sans-serif\'',
                        callback: (value) => {
                            return formatNumber(value);
                        }
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontFamily: '\'Source Sans 3\', \'sans-serif\'',
                        callback: (value) => {
                            return formatNumber(value);
                        }
                    }
                }]
            }, plugins: {
                afterRender: {
                    callback: function () {
                        vm.afterRender();
                    }
                }
            }
        };

        if ($state.current.name === 'printDashboard') {
            defaultChartOptions.animation = false;
            defaultChartOptions.tooltips.enabled = false;
        }
        else {
            defaultChartOptions.plugins.deferred = {
                yOffset: '50%', // Defer until 50% of the canvas height are inside the viewport
                delay: 300      // Delay of 300 ms after the canvas is considered inside the viewport
            };
        }

        const chartTypeMap = {
            bar: 'horizontalBar',
            column: 'bar',
            pie: 'pie',
            line: 'line'
        };

        let tempColors = [];
        const baseShadeSteps = [ // Also used in resetChartColors
            0,
            20, -20,
            40, -40,
            60, -60
        ];
        let currentShadeStep = 0;
        let shadeSteps = angular.copy(baseShadeSteps);

        // Set 1 initial color so charts with 1 segment have the same color throughout.
        resetChartColors();

        function getChartColor() {

            // If we're not stacking always return the same color, so the whole line/all the columns get the same color.
            if (!isStackedChart()) {
                return vm.chartColors[0];
            }

            // If there are no more colors left to cycle through, reset them and move on to the next shade step
            if (tempColors.length === 0) {
                tempColors = angular.copy(palette);

                currentShadeStep = shadeSteps.shift();
            }

            // If we run out of shade steps let the lib generate random colors, in practice you'd need more than 42 (6 colors * 7 shade steps) stacks before this happens.
            if (angular.isUndefined(currentShadeStep)) {
                return null;
            }

            let color = tempColors.shift();

            // Don't re-use the color we already used as the initial color.
            // Ideally we would not provide an initial color and just use this function but the lib doesn't work that way.
            if (currentShadeStep === 0 && color === vm.chartColors[0].backgroundColor) {
                // When no colors left, recall to start next shade
                if (tempColors.length === 0) {
                    return getChartColor();
                }

                color = tempColors.shift();
            }

            color = utils.adjustColor(color, currentShadeStep);

            // Provide full object to prevent lib from adding opacity
            return colorToChartColorObject(color);
        }

        vm.$onInit = function () {
            if (vm.type === 'scorecard' || vm.type === 'table' || vm.type === 'predefined' || vm.showEmptyState()) {
                vm.afterRender();
            }
        };

        vm.$onChanges = function (changes) {

            // If only the type changes reset the chart as the data won't be correct anymore
            // This happens when type is update before the data is done fetching and being passed down
            if (changes.type && !changes.chartData) {
                return resetChart();
            }

            // If pipelineName changes wait for the chartData to be updated
            if (
                changes?.pipelineName &&
                changes.pipelineName.currentValue !== changes.pipelineName.previousValue
            ) {
                return;
            }

            // Clone to break '2-way binding' due to pass by reference
            // @see https://github.com/toddmotto/angularjs-styleguide#one-way-dataflow-and-events
            if (changes.chartData) {
                vm.chartData = angular.copy(vm.chartData);
            }

            // Reset chart when incoming data is nothing
            if (!vm.chartData || vm.chartData.length === 0) {
                return resetChart();
            }

            vm.chartJsType = chartTypeMap[vm.type];

            // FormatChartData must be completed, since we need vm.chartJsData to get the correct amount of color entries for the chart
            // If it isn't completed, charts may load twice or get faulty colors
            formatChartData().then(function () {

                // It is possible we need different colors depending on whether we're segmenting/stacking.
                // We check this using `isStackedChart`, which only relies on `chartData`.
                // We could reset on data changes only but this is in practice on every change.
                // `resetChart` also does a color reset.
                resetChartColors();
            });


            if (vm.chartLabels && vm.chartLabels.length > MAX_VIEW_BY_LABELS) {
                vm.emptyStateText = 'Too many data points. Please adapt your time intervals.';
            }
            else {
                vm.emptyStateText = defaultEmptyStateText;
            }
        };

        /**
         * We return a promise, since formatBarChartData might fetch pipelines
         * it's important to be able to wait for the formatting to finish, since we can't reset chart colors if chartJsData is not available yet.
         *
         * @returns {Promise.<undefined>}
         */
        function formatChartData() {

            switch (vm.type) {
                case 'column':
                case 'bar':
                case 'line':
                    return $q.resolve(formatBarChartData());
                case 'pie':
                    formatPieChartData();
                    break;
                case 'scorecard':
                    formatScorecardChartData();
                    break;
                case 'table':
                    formatPivotTableData();
                    break;
                default:
                    resetChart();
                    break;
            }

            return $q.resolve();
        }

        vm.showEmptyState = function () {

            return vm.type !== 'scorecard' && vm.type !== 'predefined' &&
                (!vm.chartData || vm.chartData.length === 0 || (vm.chartData.data && vm.chartData.data.length === 0) || (vm.chartLabels && vm.chartLabels.length > MAX_VIEW_BY_LABELS));
        };

        function formatBarChartData() {

            // We return a promise, since formatBarChartData might fetch pipelines
            // it's important to be able to wait for the formatting to finish, since we can't reset chart colors if chartJsData is not available yet.
            return $q(function (resolve) {

                const clonedChartOptions = angular.copy(defaultChartOptions);

                // Make sure the data axis starts at 0
                clonedChartOptions.scales.xAxes[0].ticks.beginAtZero = chartDataMap[vm.type] === 'x';
                clonedChartOptions.scales.yAxes[0].ticks.beginAtZero = chartDataMap[vm.type] === 'y';

                if (isStackedChart()) {
                    // Construct label array by getting all unique y-values across all segments
                    sortChartLabels(utils.unique(
                        vm.chartData.flatMap(function (segment) {

                            return segment.data.map(function (dataPoint) {

                                return removePipelineNameFromLabel(dataPoint[chartLabelMap[vm.type]]);
                            });
                        })
                    ), vm.chartData).then(function (chartLabels) {

                        vm.chartLabels = chartLabels;

                        // Construct an array per label
                        // Each entry in the array represents a different segment, identified by the id in the array
                        // Return 0 for segments that don't have a value for the label
                        sortSegments(vm.chartData).then(function (segments) {

                            vm.chartJsData = segments.map(function (segment) {

                                return vm.chartLabels.map(function (chartLabel) {

                                    const foundDataPoint = segment.data.find(function (dataPoint) {

                                        return removePipelineNameFromLabel(dataPoint[chartLabelMap[vm.type]]) === chartLabel;
                                    });

                                    // Our dataPoint data can be `null` which will result in `NaN` in the chart, so we return 0 here instead
                                    if (!foundDataPoint || !foundDataPoint[chartDataMap[vm.type]]) {
                                        return 0;
                                    }

                                    return foundDataPoint[chartDataMap[vm.type]];
                                });
                            });

                            if (vm.viewByDate) {
                                fillEmptyDateLabels(vm.chartLabels, vm.chartJsData, vm.viewByDatePeriod);
                            }

                            // Add the segment label to each data set (this shows in the tooltip)
                            vm.chartDatasetOverride = vm.chartData.map(function (segment) {

                                return {
                                    label: vm.segmentByDate ? formatDateLabel(segment.label, vm.segmentByDatePeriod) : removePipelineNameFromLabel(segment.label),
                                    lineTension: 0, // Straight lines
                                    fill: false
                                };
                            });

                            if (vm.stackChart) {
                                // Remove tooltip tooltip.mode key to show all segment labels for a certain view by value in the tooltip
                                delete clonedChartOptions.tooltips.mode;
                            }

                            // Configure the chart as stacked if the setting is enabled and we aren't drawing a line chart
                            clonedChartOptions.scales.xAxes[0].stacked = vm.stackChart && vm.type !== 'line';
                            clonedChartOptions.scales.yAxes[0].stacked = vm.stackChart && vm.type !== 'line';
                            // Don't show grid lines on the label axis except for bar/column charts of which the segments are not stacked (to make it clearer which segment belongs to which label)
                            clonedChartOptions.scales.xAxes[0].gridLines = { display: chartLabelMap[vm.type] !== 'x' || (!vm.stackChart && vm.type !== 'line') };
                            clonedChartOptions.scales.yAxes[0].gridLines = { display: chartLabelMap[vm.type] !== 'y' || (!vm.stackChart && vm.type !== 'line') };

                            vm.chartOptions = clonedChartOptions;

                            return resolve();
                        });
                    });
                }
                else {
                    vm.chartLabels = vm.chartData.map(function (dataPoint) {

                        return removePipelineNameFromLabel(dataPoint[chartLabelMap[vm.type]]);
                    });
                    vm.chartJsData = vm.chartData.map(function (dataPoint) {

                        return dataPoint[chartDataMap[vm.type]];
                    });

                    if (vm.viewByDate) {
                        fillEmptyDateLabels(vm.chartLabels, vm.chartJsData, vm.viewByDatePeriod);
                    }

                    // Don't show grid lines on the label axis
                    clonedChartOptions.scales.xAxes[0].gridLines = { display: chartLabelMap[vm.type] !== 'x' };
                    clonedChartOptions.scales.yAxes[0].gridLines = { display: chartLabelMap[vm.type] !== 'y' };

                    vm.chartOptions = clonedChartOptions;
                    vm.chartDatasetOverride = angular.merge(
                        {
                            lineTension: 0, // Straight lines
                            fill: false
                        },
                        // We merge in the color options here, it seems otherwise it doesn't apply the line colors etc.
                        // Since this is for non stacked line/bar charts, `getChartColor` will return the same color as the initial one. Which is important to make sure all points and lines have the same color.
                        getChartColor()
                    );

                    return resolve();
                }
            });
        }

        /**
         * Sort segments by total measure by value in descending order
         *
         * @param {Array.<Object>} segments
         * @returns {Promise.<Array.<Object>>}
         */
        function sortSegments(segments) {

            if (vm.segmentBy === 'opportunity.stage' && !stagesCache) {
                return pipelines.get().then(function (response) {

                    stagesCache = response.data.flatMap(function (pipeline) {

                        return pipeline.stages.map(function (stage) {

                            stage.pipeline = {
                                id: pipeline.id,
                                name: pipeline.name
                            };

                            return stage;
                        });
                    });

                    return sortSegments(segments);
                });
            }

            return $q.resolve(segments.sort(function (segmentA, segmentB) {

                // Set "Other" as the last segment
                if (segmentB.label === groupOtherSegmentString) {
                    return -1;
                }

                if (segmentA.label === groupOtherSegmentString) {
                    return 1;
                }

                if (vm.segmentBy === 'opportunity.stage') {
                    const stageA = stagesCache.find((stage) => {
                        const label = `${stage.name} - ${stage.pipeline.name}`;
                        return label === vm.pipelineName ? `${segmentA.label} - ${vm.pipelineName}` : segmentA.label;
                    });

                    const stageB = stagesCache.find((stage) => {
                        const label = `${stage.name} - ${stage.pipeline.name}`;
                        return label === vm.pipelineName ? `${segmentB.label} - ${vm.pipelineName}` : segmentB.label;
                    });

                    if (stageA.order < stageB.order) {
                        return -1;
                    }

                    if (stageA.order > stageB.order) {
                        return 1;
                    }

                    return 0;
                }

                const totalMeasureByValueSegmentA = segmentA.data.reduce(function (total, dataPoint) {

                    return total + (dataPoint[chartDataMap[vm.type]] || 0);
                }, 0);
                const totalMeasureByValueSegmentB = segmentB.data.reduce(function (total, dataPoint) {

                    return total + (dataPoint[chartDataMap[vm.type]] || 0);
                }, 0);

                if (totalMeasureByValueSegmentA < totalMeasureByValueSegmentB) {
                    return 1;
                }

                if (totalMeasureByValueSegmentA > totalMeasureByValueSegmentB) {
                    return -1;
                }

                return 0;
            }));
        }

        /**
         *
         * @param {String[]} labels
         * @param {{}[]} data
         * @returns {Promise.<Array.<Object>>}
         */
        function sortChartLabels(labels, data) {

            if (vm.viewBy === 'opportunity.stage' && !stagesCache) {
                return pipelines.get().then(function (response) {

                    stagesCache = response.data.flatMap(function (pipeline) {

                        return pipeline.stages.map(function (stage) {

                            stage.pipeline = {
                                id: pipeline.id,
                                name: pipeline.name
                            };

                            return stage;
                        });
                    });

                    return sortChartLabels(labels, data);
                });
            }

            return $q.resolve(labels.sort(function (labelA, labelB) {

                // Sort dates ascending
                if (vm.viewByDate) {
                    if (labelA < labelB) {
                        return -1;
                    }

                    if (labelA > labelB) {
                        return 1;
                    }

                    return 0;
                }

                if (vm.viewBy === 'opportunity.stage') {
                    const stageA = stagesCache.find((stage) => {
                        const label = `${stage.name} - ${stage.pipeline.name}`;
                        return label === vm.pipelineName ? `${labelA} - ${vm.pipelineName}` : labelA;
                    });

                    const stageB = stagesCache.find((stage) => {
                        const label = `${stage.name} - ${stage.pipeline.name}`;
                        return label === vm.pipelineName ? `${labelB} - ${vm.pipelineName}` : labelB;
                    });

                    if (stageA.order < stageB.order) {
                        return -1;
                    }

                    if (stageA.order > stageB.order) {
                        return 1;
                    }

                    return 0;
                }

                // Otherwise sort by highest measure by value
                const totalMeasureByValueLabelA = getTotalMeasureByValueForViewByLabel(labelA, data);
                const totalMeasureByValueLabelB = getTotalMeasureByValueForViewByLabel(labelB, data);

                if (totalMeasureByValueLabelA < totalMeasureByValueLabelB) {
                    return 1;
                }

                if (totalMeasureByValueLabelA > totalMeasureByValueLabelB) {
                    return -1;
                }

                return 0;
            }));
        }

        /**
         * @param {String} viewByLabel
         * @param {{}[]} data
         * @returns {Number}
         */
        function getTotalMeasureByValueForViewByLabel(viewByLabel, data) {

            if (isStackedChart()) {
                return data.reduce(function sum(total, segment) {

                    const foundDataPoint = segment.data.find(function (dataPoint) {

                        return removePipelineNameFromLabel(dataPoint[chartLabelMap[vm.type]]) === viewByLabel;
                    });

                    return foundDataPoint ? (total + foundDataPoint[chartDataMap[vm.type]]) : total;
                }, 0);
            }

            return data.find(function (dataPoint) {

                return removePipelineNameFromLabel(dataPoint[chartLabelMap[vm.type]]) === viewByLabel;
            })[chartDataMap[vm.type]] || 0;
        }

        /**
         * Pie chart data consists of segments but each segment only contains 1 data point
         */
        function formatPieChartData() {

            vm.chartLabels = vm.chartData.map(function (segment) {

                if (vm.segmentByDate) {
                    return formatDateLabel(segment.label, vm.segmentByDatePeriod);
                }

                return removePipelineNameFromLabel(segment.label);
            });
            vm.chartJsData = vm.chartData.map(function (segment) {

                return segment.data;
            });

            // Remove the scales property from the options, otherwise we're drawing axi for a pie chart which doesn't make sense
            const clonedChartOptions = angular.copy(defaultChartOptions);
            delete clonedChartOptions.scales;
            clonedChartOptions.tooltips.mode = 'label';
            clonedChartOptions.tooltips.callbacks = {
                label: function (tooltipItem, data) {

                    const valueToFormat = data.datasets[0].data[tooltipItem.index];
                    const formattedValue = Number.isNaN(valueToFormat) ? valueToFormat : $filter('currency')(valueToFormat, '', valueToFormat % 1 === 0 ? 0 : 2);

                    return data.labels[tooltipItem.index] + ': ' + formattedValue + '';
                }
            };
            vm.chartOptions = clonedChartOptions;

            vm.chartDatasetOverride = {
                lineTension: 0, // Straight lines
                fill: false
            };
        }

        /**
         * Scorecard is a special type as we don't draw it with chart.js but with our own sf-scorecard component
         */
        function formatScorecardChartData() {

            if (vm.chartData.current_period_data) {
                vm.chartLabels = vm.chartData.current_period_data.label;
                vm.chartJsData = vm.chartData.current_period_data.data;
                vm.chartJsDataPreviousPeriod = vm.chartData.previous_period_data.data;
            }
            else {
                vm.chartLabels = vm.chartData.label;
                vm.chartJsData = vm.chartData.data;
                vm.chartJsDataPreviousPeriod = undefined;
            }

            vm.differenceUnit = vm.chartData.difference_unit;
            vm.inverseTrendColor = vm.chartData.inverse_trend_color;
        }

        function formatPivotTableData() {

            vm.chartLabels = vm.chartData.label;
            vm.chartJsData = {
                columns: vm.chartData.columns.map(function (column) {

                    if (vm.segmentByDate) {
                        return formatDateLabel(column, vm.segmentByDatePeriod);
                    }

                    return removePipelineNameFromLabel(column);
                }),
                rows: vm.chartData.rows.map(function (row) {

                    if (vm.viewByDate) {
                        return formatDateLabel(row, vm.viewByDatePeriod);
                    }

                    return removePipelineNameFromLabel(row);
                }),
                values: vm.chartData.data
            };
        }

        function resetChart() {

            vm.chartJsData = undefined;
            vm.chartJsDataPreviousPeriod = undefined;
            vm.chartLabels = undefined;
            vm.chartSeries = undefined;
            vm.chartDatasetOverride = undefined;
            vm.chartOptions = undefined;

            resetChartColors();

            // Scorecard specific props
            vm.differenceUnit = undefined;
            vm.inverseTrendColor = undefined;

            vm.emptyStateText = vm.emptyStateText || defaultEmptyStateText; // We don't want to always reset to default as it is possible our parent has given us a special empty state text.
        }

        function resetChartColors() {

            const colors = [colorToChartColorObject(palette[0])];

            shadeSteps = angular.copy(baseShadeSteps);

            // Make sure to reset the current shade and temp colors, otherwise colors keep cycling on property change
            currentShadeStep = 0;
            tempColors = [];
            if (vm.chartJsData && (colors.length < vm.chartJsData.length)) {
                while (colors.length < vm.chartJsData.length) {
                    colors.push(getChartColor());
                }
            }

            vm.chartColors = colors;
        }

        function removePipelineNameFromLabel(label) {

            if (!vm.pipelineName || !angular.isString(label)) {
                return label;
            }

            return label.split((' - ' + vm.pipelineName))[0];
        }

        /**
         *
         * @param {Array.<String>} chartLabels
         * @param {Array.<Object>} chartData
         * @param {'daily'|'monthly'|'weekly'|'quarterly'|'yearly'} datePeriod
         */
        function fillEmptyDateLabels(chartLabels, chartData, datePeriod) {

            const chartLabelsCopy = angular.copy(chartLabels);
            const chartDataCopy = angular.copy(chartData);
            const firstLabel = chartLabels[0];
            const lastLabel = chartLabels[chartLabels.length - 1];
            const startDate = firstLabel.includes('Q') ? moment().year(firstLabel.split('-Q')[0]).quarter(firstLabel.split('-Q')[1]) : moment(firstLabel);
            const endDate = lastLabel.includes('Q') ? moment().year(lastLabel.split('-Q')[0]).quarter(lastLabel.split('-Q')[1]) : moment(lastLabel);
            const periodUnitMap = {
                daily: 'days',
                weekly: 'weeks',
                monthly: 'months',
                quarterly: 'quarters',
                yearly: 'years'
            };

            const newDate = startDate.clone();
            let newLabel;
            let previousLabel;

            if (!datePeriod) {
                throw new Error('No `datePeriod` provided for `fillEmptyDateLabels`, aborting as this could cause an infinite loop.');
            }

            if (!newDate || !endDate) {
                throw new Error('Either `newDate` or `endDate` isn\'t there, aborting as this could cause an infinite loop');
            }

            // Loop from start to end date in steps of 1 periodUnit
            while (newDate.add(1, periodUnitMap[datePeriod]).diff(endDate) < 0) {

                switch (periodUnitMap[datePeriod]) {
                    case 'days':
                        newLabel = newDate.format('YYYY-MM-DD');
                        break;
                    case 'weeks':
                        newLabel = newDate.format('gggg-[W]WW');
                        break;
                    case 'months':
                        newLabel = newDate.format('YYYY-MM');
                        break;
                    case 'quarters':
                        newLabel = (newDate.year() + '-Q' + newDate.quarter());
                        break;
                    case 'years':
                        newLabel = newDate.format('YYYY');
                        break;
                }

                if (!chartLabelsCopy.includes(newLabel)) {
                    // If we don't have a previousLabel yet we start inserting right after the startDate (which is index 0, so we start at 1).
                    const newLabelIndex = previousLabel ? chartLabelsCopy.indexOf(previousLabel) + 1 : 1;
                    chartLabelsCopy.splice(newLabelIndex, 0, newLabel); // Fill missing labels

                    // Add an empty data entry (0) at the right index
                    // Do this for every data segment if the data is segmented
                    if (angular.isArray(chartDataCopy[0])) {
                        chartDataCopy.forEach(function (segment) {

                            segment.splice(newLabelIndex, 0, 0);
                        });
                    }
                    else {
                        chartDataCopy.splice(newLabelIndex, 0, 0);
                    }
                }

                previousLabel = newLabel;
            }

            vm.chartLabels = chartLabelsCopy.map(function (chartLabel) {

                return formatDateLabel(chartLabel, datePeriod);
            });
            vm.chartJsData = chartDataCopy;
        }

        /**
         * Daily: 2021-01-01 -> Jan 1 '21
         * weekly: 2021-W01 -> W1 '21
         * monthly: 2021-01 -> Jan '21
         * quarterly: 2021-Q1 -> Q1 '21
         * yearly: 2021 -> 2021
         *
         * @param {String} dateLabel
         * @param {'daily'|'monthly'|'weekly'|'quarterly'|'yearly'} datePeriod
         * @returns {String}
         */
        function formatDateLabel(dateLabel, datePeriod) {

            if (dateLabel === unSpecifiedString || dateLabel === groupOtherSegmentString) {
                return dateLabel;
            }

            // All date periods have a valid ISO representation except quarters, we use YYYY-Q[1-4]
            const date = dateLabel.includes('Q') ? moment().year(dateLabel.split('-Q')[0]).quarter(dateLabel.split('-Q')[1]) : moment(dateLabel);

            switch (datePeriod) {
                case 'daily':
                    return date.format('MMM D \'YY');
                case 'weekly':
                    return date.format('[W]W \'gg');
                case 'monthly':
                    return date.format('MMM \'YY');
                case 'quarterly':
                    return ('Q' + date.quarter() + date.format(' \'YY'));
                case 'yearly':
                    return date.format('YYYY');
            }
        }

        /**
         * Helper method to properly format numbers into a readable format, using the existing thousandSuffix filter.
         *
         * @param {Number} n The number to format.
         * @returns {Number} The formatted number
         */
        function formatNumber(n) {
            return Number.isNaN(Number(n)) ? n : $filter('thousandSuffix')(n);
        }

        /**
         * Stacked chart when chart data is a list of segments
         *
         * @returns {Boolean}
         */
        function isStackedChart() {

            return angular.isArray(vm.chartData) && !!vm.chartData[0].data;
        }

        /**
         * @param {String} color
         * @returns {{ backgroundColor: String, pointBackgroundColor: String, pointHoverBackgroundColor: String, borderColor: String, pointBorderColor: String, pointHoverBorderColor: String }}
         */
        function colorToChartColorObject(color) {

            return {
                backgroundColor: color,
                pointBackgroundColor: color,
                pointHoverBackgroundColor: color,
                borderColor: color,
                pointBorderColor: '#fff',
                pointHoverBorderColor: color
            };
        }
    }
})();
