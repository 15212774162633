/**
 * @typedef {('task'|'account'|'contact'|'opportunity')} EntityType
 */

(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('filterService', filterService);

    function filterService($injector, $filter, $q, model, utils, sfHttp, config, flagsService) {

        const self = this;

        let currentPipelineId;
        const currentFilterFields = {};

        this.operators = {
            text: [
                { key: 'contains', value: 'contains' },
                { key: 'not_contains', value: 'doesn\'t contain' },
                { key: 'equal', value: 'is' },
                { key: 'not_equal', value: 'is not' },
                { key: 'begins_with', value: 'starts with' },
                { key: 'not_begins_with', value: 'doesn\'t start with' },
                { key: 'ends_with', value: 'ends with' },
                { key: 'not_ends_with', value: 'doesn\'t end with' },
                { key: 'is_null', value: 'is empty' },
                { key: 'is_not_null', value: 'is not empty' }
            ],
            integer: [
                { key: 'equal', value: 'is' },
                { key: 'not_equal', value: 'is not' },
                { key: 'less', value: 'less than' },
                { key: 'less_or_equal', value: 'less or equal' },
                { key: 'greater', value: 'greater than' },
                { key: 'greater_or_equal', value: 'greater or equal' },
                { key: 'between', value: 'between' },
                { key: 'not_between', value: 'not between' },
                { key: 'is_null', value: 'is empty' },
                { key: 'is_not_null', value: 'is not empty' }
            ],
            select: [
                { key: 'in', value: 'is' },
                { key: 'not_in', value: 'is not' }
            ],
            date: [
                { key: 'future_more_than', value: 'more than x days from now' },
                { key: 'future_exactly', value: 'exactly x days from now' },
                { key: 'future_less', value: 'less than x days from now' },
                { key: 'past_more_than', value: 'more than x days ago' },
                { key: 'past_exactly', value: 'exactly x days ago' },
                { key: 'past_less', value: 'less than x days ago' },
                { key: 'after', value: 'after' },
                { key: 'on', value: 'on' },
                { key: 'before', value: 'before' },
                { key: 'is_null', value: 'is empty' },
                { key: 'is_not_null', value: 'is not empty' }
            ],
            boolean: [
                { key: 'true', value: 'is true' },
                { key: 'false', value: 'is false' },
                { key: 'is_null', value: 'is unknown' },
                { key: 'is_not_null', value: 'is known' }
            ]
        };

        this.filters = {};
        // Set new filters on initialization to undefined
        // Now the difference between deleted rules and new rules is known
        this.newFilters = {
            account: undefined,
            contact: undefined,
            opportunity: undefined,
            tag: undefined,
            task: undefined,
            workflow: undefined
        };
        this.savedFilters = {
            account: [],
            contact: [],
            opportunity: []
        };
        this.defaults = {
            insights: {
                from: new Date(Date.UTC(new Date().getFullYear(), 0, 1)),
                to: new Date(Date.UTC(new Date().getFullYear(), 11, 31)),
                range: 'year',
                year: new Date().getFullYear(),
                owner: false
            },
            accountFeed: ['Emails', 'Internal notes', 'Meetings', 'Phone calls', 'Link clicks', 'Website visits', 'Team updates']
        };

        /**
         *
         * @param {EntityType} entity
         * @param {Number} user
         * @param {Boolean} [serverPayload] pass `true` when passing the result directly to the server, `false` or nothing when binding to the UI first
         * @returns {*|Array}
         */
        this.getDefaultFilters = function (entity, user, serverPayload) {

            // Quick fix to prevent Sentry spam
            const entityWithRequiredUser = ['task'];
            if (entityWithRequiredUser.includes(entity) && (!user || !user.id)) {
                return [];
            }

            const defaultFilters = {
                opportunity: {
                    client: [
                        {
                            id: 'opportunity.done',
                            label: 'Done',
                            type: 'boolean',
                            input: 'binaryradio',
                            entity: 'Opportunity',
                            entity_ui: 'Opportunity',
                            value: ['false'],
                            operator: 'equal'
                        },
                        self.getCurrentPipelineFilter()[0]
                    ],
                    server: [
                        {
                            id: 'opportunity.done',
                            label: 'Done',
                            type: 'boolean',
                            input: 'binaryradio',
                            entity: 'opportunity',
                            value: ['false'],
                            operator: 'equal'
                        }
                    ]
                },
                task: {
                    server: [
                        {
                            'id': 'task.assignee.id',
                            'label': 'Assignee',
                            'type': 'integer',
                            'input': 'autocomplete',
                            'entity': 'task',
                            'value': [user?.id],
                            'operator': 'in'
                        }
                    ],
                    client: [
                        {
                            'id': 'task.assignee.id',
                            'label': 'Assignee',
                            'type': 'integer',
                            'input': 'autocomplete',
                            'entity': 'Task',
                            'entity_ui': 'Task',
                            'value': [user?.id],
                            'raw_value': [user],
                            'operator': 'in'
                        }
                    ]
                }
            };

            return (defaultFilters[entity] && defaultFilters[entity][serverPayload ? 'server' : 'client']) || [];
        };

        this.getPredefinedFilters = function (entity) {

            const predefinedFilters = {
                // Temporary base task filters needed to keep the different contact views correct
                // TODO: remove with saved filters
                person: {
                    'mycontacts': [
                        {
                            id: 'contact.type',
                            entity: 'person',
                            entity_ui: 'Contact',
                            input: 'multiselect',
                            label: 'Type',
                            operator: 'in',
                            options: [
                                {
                                    id: 1,
                                    name: 'My contact',
                                    value: 1,
                                    order: 1
                                },
                                {
                                    id: 2,
                                    name: 'Customer',
                                    value: 2,
                                    order: 2
                                }
                            ],
                            type: 'integer',
                            value: [1]
                        }
                    ],
                    'customer': [
                        {
                            id: 'contact.type',
                            entity: 'person',
                            entity_ui: 'Contact',
                            input: 'multiselect',
                            label: 'Type',
                            operator: 'in',
                            options: [
                                {
                                    id: 1,
                                    name: 'My contact',
                                    value: 1,
                                    order: 1
                                },
                                {
                                    id: 2,
                                    name: 'Customer',
                                    value: 2,
                                    order: 2
                                }
                            ],
                            type: 'integer',
                            value: [2]
                        }
                    ]
                },
                // Temporary base task filters needed to keep the different task views correct
                // TODO: remove with saved filters
                tasks: {
                    'tasks.today': [
                        {
                            id: 'task.completed',
                            entity: 'task',
                            input: 'radio',
                            label: 'Completed',
                            operator: 'equal',
                            type: 'boolean',
                            value: 'false'
                        },
                        {
                            id: 'task.reminder_date',
                            entity: 'task',
                            input: 'date',
                            label: 'Reminder date',
                            operator: 'before',
                            type: 'datetime',
                            value: moment().tz(model.me.team.time_zone || 'UTC').add(1, 'days').format('YYYY-MM-DD')
                        }
                    ],
                    'tasks.upcoming': [
                        {
                            condition: 'AND',
                            rules: [
                                {
                                    id: 'task.completed',
                                    entity: 'task',
                                    input: 'radio',
                                    label: 'Completed',
                                    operator: 'equal',
                                    type: 'boolean',
                                    value: 'false'
                                },
                                {
                                    id: 'task.reminder_date',
                                    entity: 'task',
                                    input: 'date',
                                    label: 'Reminder date',
                                    operator: 'after',
                                    type: 'datetime',
                                    value: moment().tz(model.me.team.time_zone || 'UTC').format('YYYY-MM-DD')
                                }
                            ]
                        }
                    ],
                    'tasks.completed': [
                        {
                            id: 'task.completed',
                            entity: 'task',
                            input: 'radio',
                            label: 'Completed',
                            operator: 'equal',
                            type: 'boolean',
                            value: 'true'
                        }
                    ]
                },
                // Temporary base task filters needed to keep the different contact views correct
                // TODO: remove with saved filters
                opportunity: {
                    'opportunities.timeline': [
                        {
                            id: 'opportunity.close_date',
                            entity: 'opportunity',
                            input: 'date',
                            label: 'Close Date',
                            operator: 'not_equal',
                            type: 'datetime',
                            value: 'null'
                        }
                    ],
                    'opportunities.stages': []
                }
            };

            return predefinedFilters[entity];
        };

        // NEW
        /**
         * @param {EntityType} entity
         * @param {Object} options
         * @param {Array.<String>} options.types
         * @param {Boolean} options.allPipelines
         * @param {Number} [options.pipelineId]
         * @returns {Promise.<Array.<Object>>}
         */
        this.loadFilterFields = function (entity, options) {

            if (!entity || $injector.get('sfWalkthrough').isShowing()) {
                return $q.resolve();
            }

            const types = options && options.types;
            const allPipelines = options && options.allPipelines;

            let pipelineId;
            if (entity === 'opportunity' && !allPipelines) {
                pipelineId = options.pipelineId ? options.pipelineId : currentPipelineId;
            }

            return loadFilterFieldsInternal(entity, pipelineId, options && options.includePipelineSpecificPredefinedFilterFields)
                .then(function (response) {

                    if (flagsService.get('suggestedAccountsFilterFields') === false) {
                        response.data = response.data.filter((filterField) => filterField.id !== 'contact.last_email_date' && filterField.id !== 'contact.last_meeting_date');
                    }

                    if (pipelineId) {
                        if (!currentFilterFields[entity]) {
                            currentFilterFields[entity] = {};
                        }

                        currentFilterFields[entity][pipelineId] = { fields: response.data };
                        return $q.resolve(filterFieldsOnType(angular.copy(currentFilterFields[entity][pipelineId].fields), types));
                    }
                    else {
                        currentFilterFields[entity] = { fields: response.data };
                        return $q.resolve(filterFieldsOnType(angular.copy(currentFilterFields[entity].fields), types));
                    }
                });
        };

        function loadFilterFieldsInternal(entity, pipeline, includePipelineSpecificPredefinedFilterFields) {

            const request = {
                cache: true,
                params: { pipeline }
            };

            if (includePipelineSpecificPredefinedFilterFields === false) {
                request.params.includePipelineSpecificPredefinedFilterFields = false;
            }

            return sfHttp.get(config.apiUrl + 'filterfields/' + entity, request);
        }

        function filterFieldsOnType(fields, types) {

            if (!types || !fields) {
                return fields;
            }

            return fields.filter(function (field) {

                return types.includes(field.type);
            });
        }

        this.getCurrentFilterFields = function (entity, pipeline) {

            let pipelineId;
            if (pipeline) {
                pipelineId = pipeline.id || pipeline;
            }

            if (currentFilterFields[entity]) {
                if (pipeline) {
                    if (currentFilterFields[entity][pipelineId]) {
                        return $q.resolve(angular.copy(currentFilterFields[entity][pipelineId].fields));
                    }

                    return self.loadFilterFields(entity, pipelineId).then(function (response) {

                        return $q.resolve(response);
                    });
                }
                else {
                    return $q.resolve(angular.copy(currentFilterFields[entity].fields));
                }
            }

            return self.loadFilterFields(entity, pipelineId).then(function (response) {

                return $q.resolve(response);
            });
        };

        this.setSavedFilterId = function (savedFilterId, entity) {

            checkModelAvailability();

            store.set('saved_filter_' + entity + '_' + model.me.id, savedFilterId);
        };

        this.getSavedFilterId = function (entity) {

            checkModelAvailability();

            return store.get('saved_filter_' + entity + '_' + model.me.id);
        };

        /**
         *
         * @param {EntityType} entity
         * @param {Object[]} rules
         * @param {Number} savedFilterId
         * @returns {undefined}
         */
        this.setFilter = function (entity, rules, savedFilterId) {

            checkModelAvailability();

            if (self.newFilters[entity] && self.newFilters[entity].length === 0 && rules.length === 0) {
                return;
            }

            self.newFilters[entity] = Optimus.transform({ rules }).rules;
            store.set('advanced_filter_' + entity + '_' + model.me.id, self.newFilters[entity]);

            if (savedFilterId) {
                self.setSavedFilterId(savedFilterId, entity);
            }

            if (entity === 'opportunities') {
                return self.setCurrentPipelineId();
            }

            return self.newFilters[entity];
        };

        /**
         *
         * @param {EntityType} entity
         * @param {Object} [options]
         * @param {Boolean} [options.raw=false]
         * @returns {Object[]} rules
         */
        this.getFilter = function (entity, options) {

            if ($injector.get('sfWalkthrough').isShowing()) {
                return [];
            }

            checkModelAvailability();

            if (angular.isUndefined(self.newFilters[entity])) {
                self.setFilter(entity, Optimus.transform({ rules: store.get('advanced_filter_' + entity + '_' + model.me.id) || self.getDefaultFilters(entity, model.me) }).rules);
            }

            if (!self.newFilters[entity]) {
                store.set('advanced_filter_' + entity + '_' + model.me.id, []);
                self.newFilters[entity] = [];
                return [];
            }

            // If getting the opportunity filter and no pipeline rule is there, set it first
            if (entity === 'opportunity') {
                const ruleIndex = self.newFilters.opportunity.findIndex(function (rule) {

                    return (rule.id === 'pipeline.id' || rule.id === 'opportunity.pipeline.id');
                });
                if (ruleIndex === -1) {
                    self.setCurrentPipelineId();
                }
            }

            if (options && options.raw) {
                return angular.copy(self.newFilters[entity]);
            }

            return self.cleanAdvancedFilterForHttp(self.newFilters[entity]);
        };

        this.cleanAdvancedFilterForHttp = function (filters) {

            if (!filters) {
                return;
            }

            if (filters.rules) {
                filters.rules = self.cleanAdvancedFilterForHttp(filters.rules);
                // As this function returns rules, always return an array
                // This prevents issues like https://github.com/Salesflare/Server/issues/6427
                return [filters];
            }

            return filters.map(function (filterRule) {

                if (filterRule.rules && filterRule.condition) {
                    const cleanedRules = self.cleanAdvancedFilterForHttp(filterRule.rules);

                    const cleanedRulesObject = {
                        condition: filterRule.condition,
                        rules: cleanedRules
                    };

                    return cleanedRulesObject;
                }

                const cleanedFilterRule = angular.copy(filterRule);

                if (angular.isObject(cleanedFilterRule.value) && (cleanedFilterRule.value.value || cleanedFilterRule.value.value === 0)) {
                    cleanedFilterRule.value = cleanedFilterRule.value.value;
                }

                if (angular.isObject(cleanedFilterRule.operator)) {
                    cleanedFilterRule.operator = cleanedFilterRule.operator.key;
                }

                const slimFilterRule = {
                    id: cleanedFilterRule.id,
                    operator: cleanedFilterRule.operator,
                    value: cleanedFilterRule.value
                };

                if (cleanedFilterRule.pipeline && cleanedFilterRule.id.includes('opportunity.custom')) {
                    slimFilterRule.pipeline = cleanedFilterRule.pipeline;
                }

                return slimFilterRule;
            });
        };

        this.removeDisabledRules = function (rules, entity) {

            let currentEntityFilterFieldsObject;
            if (entity === 'opportunity') {
                currentEntityFilterFieldsObject = currentFilterFields[entity][currentPipelineId];
            }
            else {
                currentEntityFilterFieldsObject = currentFilterFields[entity];
            }

            if (!currentEntityFilterFieldsObject) {
                return rules.map(function (rule) {

                    delete rule.disabled;
                    return rule;
                });
            }

            const currentEntityFilterFields = currentEntityFilterFieldsObject.fields;

            const enabledRules = rules.filter(function (rule) {

                // If the rule is archived, it means it was hydrated, and we want to show it in the client.
                // If this isn't present, just proceed to parse it as normal.
                if (rule.archived || (rule.raw_value || []).map((value) => value.archived).includes(true)) {

                    rule.disabled = true;

                    return true;
                }

                const enabledRule = currentEntityFilterFields.find(function (field) {

                    if (angular.isDefined(rule.pipeline) && angular.isDefined(field.pipeline) && rule.pipeline !== field.pipeline) {
                        return false;
                    }

                    return (field.id === rule.id || field.query_builder_id === rule.id);
                });

                if (!rule.id) {
                    rule.disabled = false;
                }
                else if (enabledRule) {
                    rule.disabled = false;
                }
                else {
                    rule.disabled = true;
                }

                if (rule.disabled) {
                    delete rule.disabled;
                    return false;
                }

                delete rule.disabled;
                return true;
            });

            return enabledRules;
        };

        this.getSavedFilters = function (entity) {

            if (self.savedFilters[entity]) {
                return sfHttp.get(config.apiUrl + 'savedfilters/' + entity, { cache: true });
            }
        };

        this.setSavedFilter = function (entity) {

            // Check if current filter is valid
            if (self.newFilters[entity]) {
                return sfHttp.post(config.apiUrl + 'savedfilters/' + entity, self.newFilters[entity], {});
            }
        };

        this.getAccountFeedTypesFilter = function () {

            checkModelAvailability();

            const returnValue = self.filters.accountFeedFilter || store.get('account_feed_filter_' + model.me.id) || angular.copy(self.defaults.accountFeed);
            self.setAccountFeedTypesFilter(returnValue);
            return returnValue;
        };

        /**
         * @param {Array<'email' | 'message' | 'meeting-live' | 'meeting-phone' | 'forward' | 'webpage' | 'user_added' | 'user_removed' | 'account_created'>} filter
         * @returns {undefined}
         */
        this.setAccountFeedTypesFilter = function (filter) {

            checkModelAvailability();

            store.set('account_feed_filter_' + model.me.id, filter);
            self.filters.accountFeedFilter = filter;
        };

        this.getAccountFeedCustomersFilter = function (accountId) {

            checkModelAvailability();

            let filter;
            if (self.filters.accountCustomersFeedFilter && self.filters.accountCustomersFeedFilter.account === accountId) {
                filter = self.filters.accountCustomersFeedFilter.filter;
            }

            const returnValue = filter || store.get('account_customers_feed_filter_' + model.me.id + '_' + accountId);
            self.setAccountFeedCustomersFilter(accountId, returnValue);
            return returnValue;
        };

        this.setAccountFeedCustomersFilter = function (accountId, filter) {

            checkModelAvailability();


            store.set('account_customers_feed_filter_' + model.me.id + '_' + accountId, filter);
            self.filters.accountCustomersFeedFilter = { account: accountId, filter };
        };

        this.isAccountFeedTypesFilterApplied = function () {

            return !angular.equals(self.getAccountFeedTypesFilter().sort(), self.defaults.accountFeed.sort());
        };

        /**
         * Function to help aid the easiness of setting and getting the currentPipelineId in the app
         * It is used in multiple places and needs to be synced across them
         * This function helps in setting the pipeline id to the filters as well so you don't need to think about it
         *
         * @param {Number?} pipelineId
         */
        this.setCurrentPipelineId = function (pipelineId) {

            if (!pipelineId || $injector.get('sfWalkthrough').isShowing()) {
                pipelineId = currentPipelineId || store.get('current_pipeline_' + model.me.id);
            }
            else {
                checkModelAvailability();
                store.set('current_pipeline_' + model.me.id, pipelineId);
            }

            if (!pipelineId) {
                return;
            }

            currentPipelineId = pipelineId;

            if (angular.isUndefined(self.newFilters.opportunity)) {
                self.getFilter('opportunity', { raw: true });
            }

            const ruleIndex = self.newFilters.opportunity.findIndex(function (rule) {

                return (rule.id === 'pipeline.id' || rule.id === 'opportunity.pipeline.id');
            });

            if (ruleIndex !== -1) {
                self.newFilters.opportunity[ruleIndex] = {
                    id: 'opportunity.pipeline.id',
                    entity: 'opportunity',
                    input: 'autocomplete',
                    label: 'Pipeline',
                    type: 'integer',
                    value: [pipelineId],
                    operator: 'in'
                };
            }
            else if (angular.isUndefined(self.newFilters.opportunity)) {
                self.newFilters.opportunity = self.getFilter('opportunity', { raw: true });
            }
            else {
                self.newFilters.opportunity.push({
                    id: 'opportunity.pipeline.id',
                    entity: 'opportunity',
                    input: 'autocomplete',
                    label: 'Pipeline',
                    type: 'integer',
                    value: [pipelineId],
                    operator: 'in'
                });
            }

            checkModelAvailability();

            store.set('advanced_filter_opportunity_' + model.me.id, self.newFilters.opportunity);
        };

        this.getCurrentPipelineFilter = function (pipelineId) {

            pipelineId = pipelineId || currentPipelineId;

            return [{
                id: 'opportunity.pipeline.id',
                entity: 'opportunity',
                input: 'autocomplete',
                label: 'Pipeline',
                type: 'integer',
                value: [pipelineId],
                operator: 'in'
            }];
        };

        /**
         * Sets the id of the last visited dashboard
         *
         * @param {Number} dashboardId
         * @returns {undefined}
         */
        this.setCurrentDashboardId = function (dashboardId) {

            checkModelAvailability();

            store.set('current_dashboard_' + model.me.id, dashboardId);
        };

        /**
         * Returns the id of the last visited dashboard
         *
         * @returns {Number}
         */
        this.getCurrentDashboardId = function () {

            checkModelAvailability();

            return store.get('current_dashboard_' + model.me.id);
        };

        this.isContactFilterApplied = function (customFilterObject) {

            if (!customFilterObject) {
                customFilterObject = self.getFilter('contact');
            }

            return isNewObjectFiltered(customFilterObject, self.getDefaultFilters('contact', model.me));
        };

        this.isAccountFilterApplied = function () {

            return isNewObjectFiltered(self.newFilters.account, self.getDefaultFilters('account', model.me));
        };

        this.isOpportunityFilterApplied = function () {

            return isNewObjectFiltered(self.newFilters.opportunity, self.getDefaultFilters('opportunity', model.me));
        };

        this.isTagFilterApplied = function () {

            return isNewObjectFiltered(self.newFilters.tag, self.getDefaultFilters('tag', model.me));
        };

        this.isTaskFilterApplied = function () {

            return isNewObjectFiltered(self.newFilters.task, self.getDefaultFilters('task', model.me));
        };

        this.isWorkflowFilterApplied = function () {

            return isNewObjectFiltered(self.newFilters.workflow, self.getDefaultFilters('workflow', model.me));
        };

        this.isFilterEqual = function (a, b) {

            if (a.length !== b.length) {
                return false;
            }

            return !a.some(function (rule, i) {

                const o1 = {
                    operator: rule.operator,
                    value: angular.isArray(rule.value) ? rule.value.sort().toString() : (rule.value || rule.value === 0 ? rule.value.toString() : rule.value),
                    id: rule.id
                };
                const o2 = {
                    operator: b[i].operator,
                    value: angular.isArray(b[i].value) ? b[i].value.sort().toString() : (b[i].value || b[i].value === 0 ? b[i].value.toString() : b[i].value),
                    id: b[i].id
                };

                return !angular.equals(o1, o2);
            });
        };

        function resetNewFilters() {

            checkModelAvailability();

            // Don't touch insights since they don't have advanced filters

            // Check if old filters are still active and empty them
            const storedAccountFilters = store.get('filter_accounts_' + model.me.id);
            const storedContactFilters = store.get('filter_contacts_' + model.me.id);
            const storedOpportunitiesFilters = store.get('filter_opportunities_' + model.me.id);
            const storedTagFilters = store.get('filter_tags_' + model.me.id);
            const storedTasksFilters = store.get('filter_tasks_' + model.me.id);
            const storedWorkflowsFilters = store.get('filter_workflows_' + model.me.id);

            if (!angular.isArray(storedAccountFilters) || !angular.isArray(storedContactFilters) || !angular.isArray(storedOpportunitiesFilters) || !angular.isArray(storedTagFilters) || !angular.isArray(storedTasksFilters) || !angular.isArray(storedWorkflowsFilters)) {
                store.set('filter_accounts_' + model.me.id, []);
                store.set('filter_contacts_' + model.me.id, []);
                store.set('filter_opportunities_' + model.me.id, []);
                store.set('filter_tags_' + model.me.id, []);
                store.set('filter_tasks_' + model.me.id, []);
                store.set('filter_campaigns_' + model.me.id, []); // We don't do campaigns anymore but when an old filter is still there make sure to still empty out the campaign one as well
                store.set('filter_workflows_' + model.me.id, []);
                // Discard all the old filters except for the insights filter
                const newBasicFilterObject = { insights: self.filters.insights };
                self.filters = newBasicFilterObject;
            }

            // Load in current pipeline from localstorage
            self.setCurrentPipelineId();
        }

        function isNewObjectFiltered(filterRules, defaultRules) {

            if (angular.isUndefined(filterRules) || (filterRules.length === 0 && defaultRules.length === 0)) {
                return false;
            }

            if (filterRules.length !== defaultRules.length) {
                return true;
            }

            let filterCopy = angular.copy(filterRules);
            let defaultsCopy = angular.copy(defaultRules);

            // Raw values might differ
            filterCopy = filterCopy.map(function (filter) {

                if (filter.value) {
                    delete filter.value.raw_value;
                }

                if (!angular.isArray(filter.value)) {
                    filter.value = [filter.value];
                }

                delete filter.raw_value;
                delete filter.query_builder_id;

                return filter;
            });

            defaultsCopy = defaultsCopy.map(function (filter) {

                if (filter.value) {
                    delete filter.value.raw_value;
                }

                delete filter.raw_value;
                delete filter.query_builder_id;

                return filter;
            });

            return !angular.equals(filterCopy, defaultsCopy);
        }

        // Still used for insights
        this.set = function (type, filter) {

            checkModelAvailability();

            self.filters[type] = angular.copy(filter);

            store.set('filter_' + type + '_' + model.me.id, self.filters[type]);

            if (type === 'opportunities') {
                return self.setCurrentPipelineId();
            }
        };

        // Still used by contacts.get
        this.sanitizeContactFilterForHttp = function (filter) {

            if (filter.account) {
                filter.account = filter.account.map(function (account) {

                    if (angular.isObject(account)) {
                        return account.id;
                    }

                    return account;
                });
            }

            if (filter['address.country']) {
                filter['address.country'] = filter['address.country'].map(function (country) {

                    if (angular.isObject(country)) {
                        return country.country;
                    }

                    return country;
                });
            }

            if (filter['address.state_region']) {
                filter['address.state_region'] = filter['address.state_region'].map(function (stateRegion) {

                    if (angular.isObject(stateRegion)) {
                        return stateRegion.state_region;
                    }

                    return stateRegion;
                });
            }

            if (filter['address.city']) {
                filter['address.city'] = filter['address.city'].map(function (city) {

                    if (angular.isObject(city)) {
                        return city.city;
                    }

                    return city;
                });
            }

            if (filter['position.role']) {
                filter['position.role'] = filter['position.role'].map(function (position) {

                    if (angular.isObject(position)) {
                        return position.role;
                    }

                    return position;
                });
            }

            if (filter.tag) {
                filter.tag = filter.tag.map(function (tag) {

                    if (angular.isObject(tag)) {
                        return tag.id;
                    }

                    return tag;
                });
            }

            if (filter.creation_after) {
                filter.creation_after = utils.getStartOfLocalDay(filter.creation_after);
            }

            if (filter.creation_before) {
                filter.creation_before = utils.getEndOfLocalDay(filter.creation_before);
            }

            sanitizeCustomFiltersForHttp(filter.custom);

            utils.stripNil(filter);

            return filter;
        };

        this.sanitizeAccountFilterForHttp = function (filter) {

            if (filter['address.country']) {
                filter['address.country'] = filter['address.country'].map(function (country) {

                    if (angular.isObject(country)) {
                        return country.country;
                    }

                    return country;
                });
            }

            if (filter['address.state_region']) {
                filter['address.state_region'] = filter['address.state_region'].map(function (stateRegion) {

                    if (angular.isObject(stateRegion)) {
                        return stateRegion.state_region;
                    }

                    return stateRegion;
                });
            }

            if (filter['address.city']) {
                filter['address.city'] = filter['address.city'].map(function (city) {

                    if (angular.isObject(city)) {
                        return city.city;
                    }

                    return city;
                });
            }

            if (filter.tag) {
                filter.tag = filter.tag.map(function (tag) {

                    if (angular.isObject(tag)) {
                        return tag.id;
                    }

                    return tag;
                });
            }

            if (filter.creation_after) {
                filter.creation_after = utils.getStartOfLocalDay(filter.creation_after);
            }

            if (filter.creation_before) {
                filter.creation_before = utils.getEndOfLocalDay(filter.creation_before);
            }

            sanitizeCustomFiltersForHttp(filter.custom);

            utils.stripNil(filter);

            return filter;
        };

        function sanitizeCustomFiltersForHttp(customFilter) {

            if (!customFilter || Object.keys(customFilter).length === 0) {
                return;
            }

            if (customFilter && Object.keys(customFilter).length > 0) {
                for (const key in customFilter) {
                    // Date custom field filters are suffixed with '_min' or '_max'
                    if (!key.endsWith('_min') && !key.endsWith('_max')) {
                        continue;
                    }

                    // Since custom fields of type Date don't have a time portion, we omit the time portion before sending to the server.
                    // Avoids time zone conflicts
                    // Number.isNaN check makes sure that min/max number custom fields don't get transformed into dates
                    const isADate = Object.prototype.hasOwnProperty.call(customFilter, key) && utils.isValidDate(new Date(customFilter[key])) && Number.isNaN(customFilter[key]);
                    if (isADate) {
                        customFilter[key] = $filter('date')(new Date(customFilter[key]), 'yyyy-MM-dd');
                    }
                }
            }
        }

        this.reset = function () {

            checkModelAvailability();

            // Insights filters are used in classic and advanced mode
            self.filters.insights = angular.merge({}, self.defaults.insights, store.get('filter_insights_' + model.me.id));

            if (self.filters.insights.from) {
                self.filters.insights.from = new Date(self.filters.insights.from);
            }

            if (self.filters.insights.to) {
                self.filters.insights.to = new Date(self.filters.insights.to);
            }

            return resetNewFilters();
        };

        /**
         *
         * @param {Object[]} rules
         * @returns {Object[]} - The flattened rules
         */
        this.flatten = (rules) => {

            return rules.reduce((acc, rule) => {

                if (rule.rules) {
                    return acc.concat(this.flatten(rule.rules));
                }

                return acc.concat(rule);
            }, []);
        };

        /**
         *
         * @param {Object[]} a
         * @param {Object[]} b
         * @returns {Boolean} - Whether both arrays contain the same values, regardless of order.
         */
        this.arraysAreEqualUnordered = (a, b) => {

            const firstArray = angular.isArray(a) ? a : [a];
            const secondArray = angular.isArray(b) ? b : [b];

            if (firstArray.length !== secondArray.length) {
                return false;
            }

            for (const value of firstArray) {

                if (!secondArray.includes(value)) {
                    return false;
                }
            }

            return true;
        };

        /**
         *
         * @param {Object[]} selectedRules
         * @param {Object[]} enrichedRules
         * @returns {Object[]} - The intersection of both filters
         */
        this.intersectAndEnrich = (selectedRules, enrichedRules) => {

            selectedRules = this.flatten(selectedRules);
            enrichedRules = this.flatten(enrichedRules);

            const intersection = [];

            for (const selectedRule of selectedRules) {

                const enrichedRule = enrichedRules.find((rule) => (rule.id === selectedRule.id)
                    && this.arraysAreEqualUnordered(rule.value, selectedRule.value)
                );

                if (enrichedRule) {

                    intersection.push(enrichedRule);
                }
            }

            return intersection;
        };

        /**
         *
         * @param {Object[]} rules
         * @returns {Object} - Object containing amount of parsed errors per entity or custom field or custom field option.
         */
        this.parseRulesErrors = (rules) => {

            const parsedResult = {
                customFields: 0,
                customFieldOptions: 0,

                entities: {}
            };

            const flattenedRules = this.flatten(rules);

            for (const rule of flattenedRules) {

                if (rule.archived) {

                    if (rule.id.toString().includes('custom.')) {

                        parsedResult.customFields++;
                    }
                }

                if (rule.raw_value) {

                    for (const value of rule.raw_value) {

                        if (value.archived) {

                            if (rule.id.toString().includes('custom.')) {
                                parsedResult.customFieldOptions++;
                            }
                            else if (parsedResult.entities[rule.search_entity || rule.entity]) {

                                parsedResult.entities[rule.search_entity || rule.entity]++;
                            }
                            else {

                                parsedResult.entities[rule.search_entity || rule.entity] = 1;
                            }
                        }
                    }
                }
            }

            return parsedResult;
        };

        this.parseErrorMessageFromRules = (rules, startOfString = null) => {

            const parsedErrors = this.parseRulesErrors(rules);

            const errorMessages = [];

            if (!startOfString) {

                startOfString = 'These filters use';
            }

            if (!angular.equals({}, parsedErrors.entities) || parsedErrors.customFields > 0 || parsedErrors.customFieldOptions > 0) {

                if (parsedErrors.customFields > 0) {

                    errorMessages.push(`${ parsedErrors.customFields > 1 ? parsedErrors.customFields : 'a' } deleted custom field${ parsedErrors.customFields > 1 ? 's' : ''}`);
                }

                if (parsedErrors.customFieldOptions > 0) {

                    errorMessages.push(`${ parsedErrors.customFieldOptions > 1 ? parsedErrors.customFieldOptions : 'a' } deleted custom field value${ parsedErrors.customFieldOptions > 1 ? 's' : ''}`);
                }

                for (const [entity, amount] of Object.entries(parsedErrors.entities)) {

                    let entityName = entity;

                    if (entity === 'opportunity' && amount > 1) {
                        entityName = 'opportunitie';
                    }

                    errorMessages.push(`${ amount > 1 ? amount : 'a' } deleted ${entityName}${ amount > 1 ? 's' : ''}`);
                }
            }

            if (errorMessages.length > 0) {

                return `${startOfString} ${errorMessages.length > 2 ? `${errorMessages.slice(0, -1).join(', ')  } and ${  errorMessages.slice(-1)}` : errorMessages.join(' and ')}.`;
            }

            return null;
        };

        function checkModelAvailability() {

            if (!model || !model.me || !model.me.id) {
                throw new Error('Model not available in filter service');
            }
        }
    }
})();
