(function () {
    'use strict';

    angular
        .module('salesflare.components.bulkEditAccounts', [])
        .component('sfBulkEditAccounts', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/bulkeditaccounts/bulkeditaccounts.html',
            bindings: {
                options: '<'
            }
        });

    function controller($rootScope, $q, $mdMedia, $mdDialog, $state, $filter, $exceptionHandler, utils, customfields, bulkService, tagsService) {

        const vm = this;
        const defaultAccount = {
            custom: {},
            added_custom: {},
            removed_custom: {}
        };

        vm.$onInit = function () {

            if (!vm.options || Object.keys(vm.options).length === 0) {
                return vm.back();
            }

            vm.$mdMedia = $mdMedia;
            vm.updating = false;
            vm.fields = [
                {
                    name: 'Website',
                    type: {}
                },
                {
                    name: 'Description',
                    type: {}
                },
                {
                    name: 'Size',
                    type: {}
                },
                {
                    name: 'Tags',
                    type: {}
                }
            ];

            return loadCustomFields();
        };

        vm.resetAccount = function () {

            vm.account = angular.copy(defaultAccount);

            if (vm.selectedField.name === 'Website') {
                vm.account.website = '';
            }

            if (vm.selectedField.name === 'Description') {
                vm.account.description = '';
            }

            if (vm.selectedField.name === 'Size') {
                vm.account.size = null;
            }

            if (['text', 'large_text', 'url'].includes(vm.selectedField.type.type)) {
                if (vm.selectedField.predefined_customfield) {
                    vm.account[vm.selectedField.api_field] = '';
                }
                else {
                    vm.account.custom[vm.selectedField.api_field] = '';
                }
            }

            if (['number', 'date', 'select'].includes(vm.selectedField.type.type)) {
                if (vm.selectedField.predefined_customfield) {
                    vm.account[vm.selectedField.api_field] = null;
                }
                else {
                    vm.account.custom[vm.selectedField.api_field] = null;
                }
            }

            if (vm.selectedField.type.type === 'boolean') {
                if (vm.selectedField.predefined_customfield) {
                    vm.account[vm.selectedField.api_field] = false;
                }
                else {
                    vm.account.custom[vm.selectedField.api_field] = false;
                }
            }

            if (['tags', 'account', 'accounts', 'contact', 'contacts', 'user', 'users'].includes(vm.selectedField.type.type)) {
                vm.account.added_custom[vm.selectedField.api_field] = [];
                vm.account.removed_custom[vm.selectedField.api_field] = [];
            }

            if (vm.selectedField.name === 'Tags') {
                vm.account.added_tags = [];
                vm.account.removed_tags = [];
            }
        };

        vm.back = function () {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(false);
            }

            return $rootScope.back();
        };

        vm.save = function () {

            if (!vm.accountForm.$valid) {
                return;
            }

            vm.updating = true;

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('These changes will be applied to all the selected accounts!')
                .ok('I\'m sure')
                .cancel('cancel');

            return $mdDialog.show(confirm).then(function () {

                const acc = angular.copy(vm.account);

                vm.fields.forEach(function (customField) {

                    if (customField.type.type === 'date') {
                        if (customField.predefined_customfield && acc[customField.api_field]) {
                            acc[customField.api_field] = $filter('date')(acc[customField.api_field], 'yyyy-MM-dd');
                        }
                        else if (acc.custom && !customField.predefined_customfield && acc.custom[customField.api_field]) {
                            acc.custom[customField.api_field] = $filter('date')(acc.custom[customField.api_field], 'yyyy-MM-dd');
                        }
                    }
                });

                // Remove non needed props
                if (acc.added_tags) {
                    acc.added_tags = acc.added_tags.map(function (tag) {

                        return { id: tag.id, name: tag.name };
                    });
                }

                if (acc.removed_tags) {
                    acc.removed_tags = acc.removed_tags.map(function (tag) {

                        return { id: tag.id };
                    });
                }

                // Remove all empty arrays
                utils.stripEmptyArray(acc);

                return bulkService.updateAccounts({ filter: vm.options, update: acc })
                    .then(function () {

                        if ($mdMedia('gt-sm')) {
                            return $mdDialog.hide(true);
                        }

                        return $state.go('accounts');
                    }).catch(function (err) {

                        vm.updating = false;

                        $exceptionHandler(err);

                        return utils.showErrorToast();
                    });
            }).catch(function () {

                vm.updating = false;
            });
        };

        vm.searchTags = function (queryString, list) {

            if (!queryString || queryString === '') {
                return [];
            }

            return $q(function (resolve) {

                return tagsService.get({ search: queryString, limit: 50 }).then(function (response) {

                    const filteredTags = response.data.filter(function (item) {

                        return !list.some(function (tag) {

                            return item.name === tag.name;
                        });
                    });

                    // eslint-disable-next-line no-var, @hapi/no-var
                    for (var i = 0; i < filteredTags.length; ++i) {
                        if (filteredTags[i].name.toLowerCase() === queryString.toLowerCase()) {
                            const matchedTag = filteredTags[i];

                            filteredTags[i] = filteredTags[0];
                            filteredTags[0] = matchedTag;
                            break;
                        }
                    }

                    if (i >= filteredTags.length || filteredTags.length === 0) {
                        filteredTags.unshift({ name: queryString });
                    }

                    return resolve(filteredTags);
                });
            });
        };

        vm.onNewCustomFieldOption = function (customField) {

            return customfields.updateAccountField(customField).then(function () {

                const customFieldId = customField.id;

                return customfields.getAllOptions(customFieldId, 'account').then(function (response) {

                    const foundCustomField = vm.fields.find((function (field) {

                        return field.id === customFieldId;
                    }));

                    foundCustomField.options = response.data.map(function (option) {

                        return { id: option.id, name: option.name, order: option.order };
                    });
                });
            });
        };


        function loadCustomFields() {

            return customfields.getAccountFields(false).then(function (response) {

                vm.fields = [...vm.fields, ...response.data].filter(function (field) {

                    return field.type.type !== 'file';
                });

                vm.fields.forEach(function (customField) {

                    if (customField.type.type === 'date') {
                        customField.min_date = utils.UTCDateStringToLocalDateObject(customField.min_date);
                        customField.max_date = utils.UTCDateStringToLocalDateObject(customField.max_date);
                    }

                    // Allow unsetting the custom field
                    if (customField.type.type === 'select') {
                        customField.options = [{ name: '' }, ...customField.options];
                    }
                });

                vm.account = angular.copy(defaultAccount);
            });
        }
    }
})();
