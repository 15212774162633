(function () {
    'use strict';

    angular
        .module('salesflare.components.customfield', [])
        .component('sfCustomfield', {
            templateUrl: 'app-ajs/components/customfields/customfield.html',
            controllerAs: 'vm',
            controller,
            bindings: {
                bulkMode: '<',
                customField: '<',
                entityForm: '<',
                entity: '<',
                onNewOptionCreation: '&'
            }
        });

    function controller($mdDialog, $q, $timeout, accounts, contactsService, model, users) {

        const vm = this;
        vm.isAdmin = model.me.is_admin;

        const typesWithMdChips = new Set(['tags', 'accounts', 'contacts', 'users']);
        const mdChipsArray = [];

        vm.toggleBoolean = function (isTriState) {

            let entityCustomField = vm.customField.predefined_customfield ? vm.entity[vm.customField.api_field] : vm.entity.custom[vm.customField.api_field];

            if (isTriState) {
                entityCustomField = entityCustomField ? null : ((entityCustomField === null) ? false : true);
            }
            else {
                entityCustomField = entityCustomField ? false : true;
            }

            if (vm.customField.predefined_customfield) {
                vm.entity[vm.customField.api_field] = entityCustomField;
            }
            else {
                vm.entity.custom[vm.customField.api_field] = entityCustomField;
            }

            vm.entityForm.$setDirty();
        };

        vm.searchOptions = function (queryString) {

            let options = vm.customField.options;
            const apiField = vm.customField.api_field;

            if (!queryString || queryString === '') {
                if (apiField) {
                    vm.entity.custom[apiField] = null;
                }

                return options || [];
            }

            return $q(function (resolve) {

                if (!options) {
                    options = [];
                }

                const filteredOptions = options.filter(function (option) {

                    return option.name.toLowerCase().includes(queryString.toLowerCase());
                });

                // When nothing found add the current queryString as an option to allow adding it as an option
                if (filteredOptions.length === 0) {
                    if (model.me.is_admin) {
                        filteredOptions.unshift({ name: queryString, displayName: 'Add ' + queryString });
                    }
                    else {
                        filteredOptions.unshift({ name: queryString, displayName: 'Ask an admin to add' + queryString + ' to the options', disabled: true });
                    }
                }

                return resolve(filteredOptions);
            });
        };

        vm.addOption = function () {

            return $timeout(function () {

                const customFieldPropertyLocation = vm.customField.predefined_customfield ? vm.entity : vm.entity.custom;

                const config = {
                    dialogId: 'customfieldOptionDialog',
                    ariaLabel: 'Add an option',
                    title: 'Add an option',
                    formId: 'customfieldOptionForm',
                    required: true,
                    inputName: 'customfieldOption',
                    requiredErrorMessage: 'An option is required',
                    cancelText: 'Cancel',
                    okText: 'Add'
                };

                return $mdDialog.show($mdDialog.sfPromptDialog(config)).then(function (optionName) {

                    const foundCustomFieldOption = vm.customField.options.find(function (customFieldOption) {

                        return optionName.toLowerCase() === customFieldOption.name.toLowerCase();
                    });

                    customFieldPropertyLocation[vm.customField.api_field] = foundCustomFieldOption || { name: optionName };

                    if (!foundCustomFieldOption) {
                        vm.customField.options.push({ name: optionName });

                        const field = angular.copy(vm.customField);

                        // Remove the first empty option before updating the custom field
                        field.options = field.options.filter(function (option) {

                            return option.name && option.name !== '';
                        });
                        delete field.api_field;
                        delete field.type;

                        return vm.onNewOptionCreation({
                            $event: {
                                customField: field
                            }
                        });
                    }
                });
            });
        };

        vm.searchEntities = (searchedEntity, fieldModel) => {

            function handler(response) {

                let entities = response.data.map((entity) => {

                    return { id: entity.id, name: entity.name, picture: entity.picture };
                });

                entities = entities.filter((entity) => {

                    if (fieldModel && angular.isArray(fieldModel)) {
                        return !fieldModel?.some(function (e) {

                            return e.id === entity.id;
                        });
                    }

                    return true;
                });

                return $q.resolve(entities);
            }

            switch (vm.customField.type.type) {
                case 'account':
                case 'accounts':
                    if (!searchedEntity || searchedEntity === '') {
                        return accounts.get(null).then(handler);
                    }
                    else {
                        return accounts.get(searchedEntity).then(handler);
                    }

                case 'contact':
                case 'contacts':
                    if (!searchedEntity || searchedEntity === '') {
                        return contactsService.get(null).then(handler);
                    }
                    else {
                        return contactsService.get(searchedEntity).then(handler);
                    }

                case 'user':
                case 'users':
                    if (!searchedEntity || searchedEntity === '') {
                        return users.get(null, true).then(handler);
                    }
                    else {
                        return users.get(searchedEntity).then(handler);
                    }
            }
        };

        vm.onEntityChange = () => {

            vm.entityForm.$setDirty();
        };

        vm.isCustomFieldValueEmpty = (customField) => {

            const customFieldPropertyLocation = vm.customField.predefined_customfield ? vm.entity : vm.entity.custom;

            return !customFieldPropertyLocation[customField.api_field];
        };

        vm.emptyOutCustomFieldValue = (customField) => {

            const customFieldPropertyLocation = vm.customField.predefined_customfield ? vm.entity : vm.entity.custom;

            customFieldPropertyLocation[customField.api_field] = null;
        };

        // To avoid having to define 2 separate inputs for predefined custom fields and normal custom fields
        // A getter and setter for the model is used, so we can do a conditional check here instead of in the template
        // https://stackoverflow.com/a/31355965
        Object.defineProperty(vm, 'customFieldModel', {
            get: function () {

                // Make sure to never return undefined for types that use chips, since that would break appending them (tags, accounts, contacts, users)
                if (vm.customField.predefined_customfield) {

                    if (angular.isUndefined(vm.entity[vm.customField.api_field]) && typesWithMdChips.has(vm.customField.type.type)) {
                        vm.customFieldModel = mdChipsArray;
                        return mdChipsArray;
                    }

                    // Const returnValue = angular.isUndefined(vm.entity[vm.customField.api_field]) && typesWithMdChips.has(vm.customField.type.type) ? mdChipsArray : vm.entity[vm.customField.api_field];
                    return vm.entity[vm.customField.api_field];
                }
                else {
                    if (angular.isUndefined(vm.entity.custom[vm.customField.api_field]) && typesWithMdChips.has(vm.customField.type.type)) {
                        vm.customFieldModel = mdChipsArray;
                        return mdChipsArray;
                    }

                    if (vm.bulkMode && vm.customField.type.type === 'select' && vm.customField.options?.[0]?.name === '') {

                        // Const returnValue =  angular.isUndefined(vm.entity.custom[vm.customField.api_field]) && typesWithMdChips.has(vm.customField.type.type) ? mdChipsArray : vm.entity.custom[vm.customField.api_field];
                        let value = vm.entity.custom[vm.customField.api_field];

                        if (!value) {
                            value = vm.customField.options?.[0];
                        }

                        vm.entity.custom[vm.customField.api_field] = value;
                        return value;
                    }

                    return vm.entity.custom[vm.customField.api_field];
                }
            },
            set: function (newValue) {

                if (vm.customField.predefined_customfield) {
                    vm.entity[vm.customField.api_field] = newValue;
                }
                else {
                    vm.entity.custom[vm.customField.api_field] = newValue;
                }
            }
        });
    }
})();
