(function () {
    'use strict';

    angular
        .module('salesflare.components.sidebar', [])
        .component('sfSidebar', {
            templateUrl: 'app-ajs/components/sidebar/sidebar.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                emptyState: '<',// 'no_company' | 'sales_navigator' | 'own_team'
                sidebarLoading: '<'
            }
        });

    function controller($state, $scope) {

        const vm = this;

        /**
         *
         * @returns {String}
         */
        vm.getTitle = () => {

            switch (vm.emptyState) {
                case 'no_company': {
                    return 'No account found';
                }

                case 'sales_navigator': {
                    return 'No related info';
                }

                case 'own_team': {
                    return 'Your company';
                }
            }
        };

        /**
         *
         * @returns {{ image: String, text: String }}
         */
        vm.getEmptyState = () => {

            switch (vm.emptyState) {
                case 'no_company': {
                    return {
                        image: 'images/archive-2.png',
                        text: 'No related account or company found.'
                    };
                }

                case 'sales_navigator': {
                    return {
                        image: 'images/pointer.png',
                        text: 'Open up a lead, account or your messaging inbox to see related info from Salesflare here.'
                    };
                }

                case 'own_team': {
                    return {
                        image: 'images/badge.png',
                        text: 'Please don\'t create an account for your own company, as it\'ll show up next to every email in the email sidebar.'
                    };
                }
            }
        };

        vm.createAccount = async () => {

            await $state.go('createAccount');

            // Wrap with apply to avoid ng-show / ng-hide problems with the fab button showing for a short time
            $scope.$apply(() => {

                vm.emptyState = undefined;
            });
        };

        vm.close = () => {

            $state.go('accounts');
        };
    }
})();
