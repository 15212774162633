(function () {
    'use strict';

    angular
        .module('salesflare.components.workflowStepAnalytics.sendEmailAudience', [])
        .component('sfWorkflowSendEmailAudience', {
            templateUrl: 'app-ajs/components/workflows/workflow/workflowstep/analytics/audience/sendEmailAudience.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                workflowId: '<',
                contacts: '<',
                viewingTab: '<',
                activeFilter: '<'
            }
        });

    function controller($mdMedia, $state) {

        const vm = this;
        vm.$mdMedia = $mdMedia;

        vm.goToContact = function (contactId) {

            return $state.go('contact', { id: contactId });
        };

        vm.goToAccount = function (account) {

            if (account && account.id) {
                return $state.go('accounts.account.feed', { id: account.id, name: account.name });
            }
        };

        vm.getWorkflowEmailStatus = function (action, contact) {

            if (action === 'sent') {
                if (vm.activeFilter.id === 'total' && !contact.email_record_created) {
                    return 'Expected';
                }

                if (contact.failed_reason) {
                    return 'Never';
                }

                return 'Pending';
            }

            return 'Never';
        };
    }
})();
