(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AppController', AppController);

    function AppController($rootScope, $scope, $state, $document, $window, $timeout, $mdMedia, $mdDialog, utils, model, contact, contactsService, accounts, company, config, email, $mdSidenav, sfWalkthrough, userEmailService, flagsService, billing, usersettings, trackingService) {

        // Hide splashscreen
        if ($window.isMobile) {
            const viewContentLoaded = $scope.$on('$viewContentLoaded', function () {

                if ($state.current.name && $state.current.name !== '') {
                    $timeout(function () {

                        $document[0].addEventListener('deviceready', function () {

                            viewContentLoaded();

                            // We want the inAppBrowser to handle our opens on mobile
                            // This is needed to make tel: links work on iOS for example
                            // This was removed from the plugin itself in https://github.com/apache/cordova-plugin-inappbrowser/pull/600
                            $window.open = function open(url, target, windowFeatures) {

                                // Open links in system browser not the apps webview
                                if (target === '_blank') {
                                    target = '_system';
                                }

                                // Noopener and noreferrer are not supported by the inAppBrowser
                                // It even crashes on Android https://github.com/apache/cordova-plugin-inappbrowser/issues/795
                                if (windowFeatures) {
                                    windowFeatures = windowFeatures.replace(/noopener|noreferrer/g, '');
                                }

                                return cordova.InAppBrowser.open(url, target, windowFeatures);
                            };

                            return navigator.splashscreen.hide();
                        }, false);
                    }, 0);
                }
            });
        }

        /**
         * We set all the helper functions and object on $rootScope so they are available everywhere e.g. dialogs and gmail plugin
         * This allows you to use $mdMedia in every view
         * Don't add more stuff here, put what you need on the local scope
         */
        $rootScope.state = $state;
        $rootScope.model = model;
        $rootScope.$mdMedia = $mdMedia;
        $rootScope.isProduction = config.env === 'production';

        if ($window.self !== $window.top) {
            const ancestorOrigins = $window.location.ancestorOrigins;

            if (ancestorOrigins && ancestorOrigins.length > 0) {
                const ancestorOriginsString = Array.from(ancestorOrigins).join(', ');

                if (ancestorOriginsString.includes('linkedin')) {
                    config.mode = 'linkedin';
                }
                else if (ancestorOriginsString.includes('mail.google')) {
                    config.mode = 'gmail';
                }
                // On Outlook Web the ancestor will be (live|office|office365).outlook.com
                // On Outlook Desktop the ancestor be the host url of the add-in, which is the same as the client url
                else if (ancestorOriginsString.includes('outlook') || ancestorOriginsString.includes(config.appUrl)) {
                    config.mode = 'outlook-web';
                }
            }

            if (['gmail', 'linkedin', 'outlook-web'].includes(config.mode)) {
                /**
                 * On Outlook desktop we want to use bearer auth, see: https://github.com/OfficeDev/office-js/issues/3896
                 * On Outlook web, Gmail and LinkedIn we use bearer auth because third party cookies are frequently blocked in modern browsers
                 */
                model.setAuthStrategy('bearer'); // On Outlook desktop we want to use bearer auth, see: https://github.com/OfficeDev/office-js/issues/3896
            }
        }

        if ($window.chrome && $window.chrome.webview) {
            $window.external = {
                notify: function (data) {

                    $window.chrome.webview.postMessage(data);
                }
            };
        }

        if (!config.mode && $window.external && 'notify' in $window.external) {
            config.mode = 'outlook';
        }

        if ($window.isMobile) {
            config.mode = 'mobile';
            $document[0].addEventListener('deviceready', initMobile, false);
        }

        if (!config.mode) {
            config.mode = 'web';
        }

        if (config.mode === 'gmail' || config.mode === 'outlook') {
            // Initialize tracking data
            trackingService.getDomains();
        }

        // When on the LinkedIn Sidebar, we don't want to show anything until the LinkedIn context processing is completed and we're on the correct state
        $scope.sidebarContextLoading = config.mode === 'linkedin';

        $document.bind('click', function (event) {

            if (config.google && config.google.appTrackingId) {
                if (event.target.getAttribute('track-id')) {
                    ga('send', 'event', $state.current.name, event.target.getAttribute('track-id'));
                }
                else {
                    const parents = angular.element(event.target).parents('[track-id]');

                    if (parents.length > 0) {
                        ga('send', 'event', $state.current.name, parents[0].getAttribute('track-id'));
                    }
                }
            }
        });

        $rootScope.goToOpportunitiesStagesView = function () {

            sfWalkthrough.advance();

            return $state.go('opportunities.stages');
        };

        $rootScope.notificationClick = function (id) {

            if (!id || (model.me && model.me.restricted)) {
                return;
            }

            $state.go('accounts.account.feed', { id }, { inherit: false });

            top.focus();
        };

        // eslint-disable-next-line no-shadow
        $rootScope.openContactByEmail = function (email) {

            if (!model.isLoggedIn || (model.me && model.me.restricted)) {
                return;
            }

            return contactsService.get(null, { email }, 1).then(function (response) {
                if (response.data.length > 0) {
                    return $state.go('contact', { id: response.data[0].id });
                }
            });
        };

        $rootScope.classifyContacts = async function (contactInfo) {

            if (!model.isLoggedIn || (model.me && model.me.restricted)) {
                return;
            }

            for (const element of contactInfo) {
                if (element.name === '') {
                    element.name = null;
                }
                else if (element.name === element.email) {
                    element.name = null;
                }
            }

            const contactResponse = await contactsService.create(contactInfo, { force: false, actor: 'system' });
            let contacts = contactResponse.data;
            if (!angular.isArray(contacts)) {
                contacts = [contacts];
            }

            const contactIds = contacts.map((contactObject) => contactObject.id);

            if (contactIds.length === 0) {
                return $state.go('tasks.today');
            }

            // When contacts passed to the company controller, more data is needed than just the ids
            // Additional data is fetched by getting the contacts, using the ids returned by the creation call
            // Meanwhile, related accounts/companies are fetched using the same ids
            const rules = [
                {
                    id: 'contact.id',
                    label: 'Id',
                    type: 'integer',
                    input: 'integer',
                    entity: 'person',
                    value: contactIds,
                    operator: 'in'
                }
            ];
            const filterGetOptions = {
                filterRules: rules,
                limit: contactIds.length
            };

            const [relatedAccounts, contactsResponse] = await Promise.all([accounts.getRelated(contactIds), contactsService.filterGet(filterGetOptions)]);

            let enrichedContacts;
            if (contactsResponse) {
                enrichedContacts = contactsResponse.data.map((contactObject) => {

                    contactObject.owned_by_user = true;
                    return contactObject;
                });
            }

            return getRelatedResponseHandler(relatedAccounts, enrichedContacts);
        };

        $rootScope.saveMail = function (guid, gmailMessageId) {

            if (!model.isLoggedIn || (model.me && model.me.restricted)) {
                return;
            }

            const mail = {
                guid,
                gmail_message_id: gmailMessageId,
                date: new Date()
            };

            return userEmailService.create(mail);
        };

        $rootScope.getAutoFindEmailLinkedInSetting = function () {

            return usersettings.get().then((response) => {

                const message = {
                    function: 'processGetAutoFindEmailLinkedInSettingResponse',
                    autoFindEmailLinkedIn: response.data.auto_find_email_linkedin
                };

                $window.parent.postMessage(message, '*');
            });
        };

        $rootScope.isEventListenerReady = function () {

            return $window.parent.postMessage({
                'function': 'eventListenerReady',
                'ready': 'true'
            }, '*');
        };

        // eslint-disable-next-line no-shadow
        $rootScope.getMergableContact = function (email, reference) {
            const filter = {
                condition: 'AND',
                rules: [
                    {
                        id: 'contact.email',
                        operator: 'equal',
                        value: email
                    }
                ]
            };

            return contact.getContactByFilter(filter, { expandAccount: true }).then(function (response) {
                const mergableContact = utils.getMergableContact(response.data);

                const message = {
                    function: 'setMergableContact',
                    data: {
                        contact: mergableContact,
                        reference
                    }
                };

                switch (config.mode) {
                    /* Not yet implemented in Outlook but added for future use */
                    case 'outlook':

                        $window.external.notify(angular.toJson(message));

                        break;
                    case 'gmail':

                        $window.parent.postMessage(message, '*');

                        break;
                }
            });
        };

        $rootScope.openTemplateManager = function (templates, createNew) {

            if (!model.isLoggedIn || (model.me && model.me.restricted)) {
                return;
            }

            const templateManagerDialogConfig = $mdDialog.templateManagerDialog();
            templateManagerDialogConfig._options.locals = {
                templates,
                createNew
            };
            templateManagerDialogConfig._options.fullscreen = true;

            return $mdDialog.show(templateManagerDialogConfig);
        };

        $rootScope.syncAccount = function () {

            if (!model.isLoggedIn || (model.me && model.me.restricted)) {
                return;
            }

            return email.syncAccount();
        };

        $rootScope.navigate = function (state, params) {

            if (!model.isLoggedIn || (model.me && model.me.restricted)) {
                return;
            }

            return $state.go(state, params);
        };

        // Sidebar functions
        async function handleSidebarContext(contextData) {

            if (!$scope.sidebarContextLoading) {
                $scope.sidebarContextLoading = true;
            }

            if (!['linkedin', 'sidebar'].includes($state.current.name)) {
                await $state.go('sidebar');
            }

            // The extension will send a context message, even if it didn't find anything on init.
            // So the loading state can be be disabled in this function
            if (!model.isLoggedIn || (model.me && model.me.restricted) || (!contextData)) {
                if ($scope.sidebarContextLoading) {
                    completeSidebarContextLoading();
                }

                return;
            }

            if (contextData.showSalesNavigatorEmptyState) {
                $scope.sidebarEmptyState = 'sales_navigator';

                if ($scope.sidebarContextLoading) {
                    completeSidebarContextLoading();
                }

                return;
            }

            if (contextData.showNoRelatedCompanyEmptyState) {
                $scope.sidebarEmptyState = 'no_company';

                if ($scope.sidebarContextLoading) {
                    completeSidebarContextLoading();
                }

                return;
            }

            const person = contextData.person;
            const companyObject = contextData.company;

            if (!companyObject || !companyObject.domain) {
                $scope.sidebarEmptyState = 'no_company';

                if ($scope.sidebarContextLoading) {
                    completeSidebarContextLoading();
                }

                return;
            }

            if (companyObject.picture) {
                companyObject.picture = await utils.imageUrlToDataUrl(companyObject.picture);
            }

            // Transform the country code to the full name of the country for the HQ address we return
            if (companyObject.addresses && companyObject.addresses.length === 1) {
                const countryResponse = await billing.getCountries({ code: companyObject.addresses[0].country }, { ignoreLoadingBar: true });

                if (countryResponse.data[0]) {
                    companyObject.addresses[0].country = countryResponse.data[0].name;
                }
            }

            if (person) {
                // Get contacts that have the current social profile
                // OR that have the same first name, last name and domain
                const response = await contactsService.getLinkedIn({
                    firstname: person.firstName,
                    lastname: person.lastName,
                    domain: companyObject.domain,
                    email: person.email,
                    linked_in_name: utils.getUsernameFromLinkedInUrl(person.linkedInUrl)
                });

                let contactObjects = [];
                // When contacts are found, use them to get related accounts/companies
                // If not, use the same strategy as we do for companies
                if (response.data?.length > 0) {
                    const salesflareContacts = response.data;
                    const contactIds = salesflareContacts.map((contactObject) => contactObject.id);

                    const relatedAccounts = await accounts.getRelated(contactIds, companyObject.domain, companyObject.linkedInUrl);
                    return getRelatedResponseHandler(relatedAccounts, salesflareContacts, null, companyObject);
                }
                else {

                    // Only use the found email if it's the same domain as the found company. This can be improved to: If it's the same domain as one of multiple current positions
                    const personEmail = person.email?.split('@')[1] === companyObject.domain ? person.email : null;
                    // Only add current positions
                    const positions = person.experience?.filter((exp) => !exp.timePeriod?.endDate).map((exp) => {
                        return {
                            organisation: exp.name,
                            role: exp.title
                        };
                    });

                    let picture;

                    if (person.picture) {
                        picture = await utils.imageUrlToDataUrl(person.picture);
                    }
                    else if (person.firstName && person.firstName.length > 0) {
                        let avatarTileLetter = person.firstName[0].toLowerCase();

                        if (/[^a-zA-Z0-9]/.test(avatarTileLetter)) {
                            avatarTileLetter = 'a';
                        }

                        picture = `https://lib.salesflare.com/avatars/avatar_tile_${avatarTileLetter}_80.png`;
                    }
                    else {
                        picture = 'https://lib.salesflare.com/avatars/avatar_tile_a_80.png';
                    }


                    // Create contact info object
                    const contactObject = {
                        firstname: person.firstName,
                        lastname: person.lastName,
                        name: `${person.firstName} ${person.lastName}`,
                        email: personEmail,
                        picture,
                        positions,
                        phone_numbers: person.phoneNumbers,
                        social_profiles: person.socialProfiles
                    };

                    contactObjects = [contactObject];
                }

                const relatedAccounts = await accounts.getRelated(null, companyObject.domain, companyObject.linkedInUrl);
                return getRelatedResponseHandler(relatedAccounts, null, contactObjects, companyObject);
            }
            else {
                // No person data available, only use found company data to get the related account
                const relatedAccounts = await accounts.getRelated(null, companyObject.domain, companyObject.linkedInUrl);
                return getRelatedResponseHandler(relatedAccounts, null, null, companyObject);
            }
        }

        $rootScope.handleContextLinkedIn = handleSidebarContext;
        $rootScope.handleContextSidebar = handleSidebarContext;

        $rootScope.setAutoFindEmailLinkedInSetting = (value) => {

            return usersettings.update({ auto_find_email_linkedin: value }).then(function () {
                model.me.auto_find_email_linkedin = value;
            });
        };

        $rootScope.toggleMenu = function () {

            return $mdSidenav('menu').toggle();
        };

        $rootScope.getTeamTrackingData = function () {

            return trackingService.getDomains().then((response) => {

                const message = {
                    function: 'tracking',
                    data: {
                        trackingEnabled: response.data.enabled,
                        teamTrackingSubdomain: model.me.team.email_tracking_subdomain,
                        teamTrackingDomain: model.me.team.email_tracking_domain,
                        allTrackingLinks: response.data.urls
                    }
                };

                // The Outlook plugin doesn't use an event handler
                if (config.mode === 'outlook') {
                    $window.external.notify(angular.toJson(message));
                    return;
                }

                $window.parent.postMessage(message, '*');
            });
        };

        $rootScope.getTrackingDomains = function () {

            return trackingService.getDomains().then((response) => {

                const message = {
                    function: 'trackingDomains',
                    data: {
                        trackingEnabled: response.data.enabled,
                        allTrackingLinks: response.data.urls
                    }
                };

                // The Outlook plugin doesn't use an event handler
                if (config.mode === 'outlook') {


                    $window.external.notify(angular.toJson(message));
                    return;
                }

                $window.parent.postMessage(message, '*');
            });
        };

        $rootScope.pluginPreSend = function (body, guid, trackingEnabled) {

            let newBody;
            const isTrackingDisabledOnTeam = flagsService.get('disableTracking');
            if (isTrackingDisabledOnTeam) {
                trackingEnabled = false;
            }

            // When not logged in or tracking is disabled, don't add tracking but still remove previous tracking
            if (model.isLoggedIn && trackingEnabled) {
                newBody = trackingService.addTrackingToBody(body, guid);
            }
            else {
                newBody = trackingService.removeTrackingFromBody(body);
            }

            // The Outlook plugin doesn't use an event handler and waits for the response
            if (config.mode === 'outlook') {

                return newBody;
            }

            const message = {
                function: 'pluginPreSend',
                data: {
                    body: newBody
                }
            };

            $window.parent.postMessage(message, '*');
        };

        $scope.hideElement = sfWalkthrough.hideElement;

        $scope.workflowsEnabled = function () {

            return flagsService.get('workflowBeta');
        };

        // Checks if the FAB should be hidden in the current state
        $scope.isFabHidden = function () {

            const statesToHideOn = ['notifications', 'settings', 'support', 'workflow', 'dashboards', 'reports', 'editReport', 'createReport', 'linkedin', 'sidebar'];
            const statesToHideOnMobile = ['accounts.company', 'accounts.account', 'contacts.customers.contact', 'contacts.my.contact', 'setup'];
            const hide = statesToHideOn.find(function (state) {

                return $state.includes(state);
            });
            const hideMobile = statesToHideOnMobile.find(function (state) {

                return $state.includes(state);
            });

            return (!$mdMedia('gt-md') && model.disableFab) || angular.isDefined(hide) || (!$mdMedia('gt-sm') && angular.isDefined(hideMobile));
        };

        /*
         * All the functions that are needed when the Salesflare application is ran on a mobile device
         */
        function initMobile() {

            /**
             * Offline handling
             */
            $document[0].addEventListener('offline', utils.showOfflineDialog, false);
            $document[0].addEventListener('online', utils.hideOfflineDialog, false);

            /**
             * Catch all link clicks to use system target
             * This will open the built in inappbrowser
             */
            $document.on('click', 'a[target="_blank"]', function (e) {

                e.preventDefault();
                $window.open(e.currentTarget.href, '_system', 'noopener');

                return false;
            });
        }

        /**
         * Functions needed for plugin events
         */

        /**
         *
         * @param {Array.<Object>} relatedResponse Related accounts or companies found by the server
         * @param {Array.<Object>} salesflareContacts Existing contacts from the team that match the LinkedIn context
         * @param {Array.<Object>} linkedinContacts Contact data straight from LinkedIn
         * @param {Object} companyObject Company data found on LinkedIn
         * @returns {Promise.<void>}
         */
        async function getRelatedResponseHandler(relatedResponse, salesflareContacts, linkedinContacts, companyObject) {

            let accountResponses;
            // When contacts with id and relatedResponse has these in suggested contacts
            // No related accounts were found and we handle different reasons in different ways https://github.com/Salesflare/Server/issues/8152
            if (angular.isString(relatedResponse.data)) {
                const noRelatedReason = relatedResponse.data;

                if (noRelatedReason === 'team_black_list_domain') {
                    // Don't show info toast, stay on same page
                    if ($scope.sidebarContextLoading) {
                        $scope.sidebarEmptyState = 'own_team';

                        completeSidebarContextLoading();
                    }

                    $scope.sidebarEmptyState = 'own_team';

                    return $state.go('sidebar');
                }
                else if (noRelatedReason === 'no_related' && companyObject) {
                    // When nothing exists yet, create a new company with the company data found on LinkedIn
                    // We intentionally don't send the picture here
                    // The picture we get from LinkedIn is not a permalink and base64 images are not supported on our POST /companies route
                    // The server will use the Clearbit logo or one of our defaults if it doesn't exist
                    // When the account gets created for the company through the LinkedIn sidebar we enrich it with the actual company logo as displayed by LinkedIn
                    const salesflareCompany = {
                        name: companyObject.name,
                        domain: companyObject.domain
                    };

                    const companyResponse = await company.create(salesflareCompany);

                    salesflareCompany.id = companyResponse?.data?.id;
                    return goToAccountOrCompany([salesflareCompany], salesflareContacts, linkedinContacts, companyObject);
                }
                else {
                    $scope.sidebarEmptyState = 'no_company';

                    if ($scope.sidebarContextLoading) {
                        completeSidebarContextLoading();
                    }
                    else {
                        return $state.go('sidebar');
                    }

                    return;
                }
            }
            else {
                accountResponses = relatedResponse.data;
            }

            return goToAccountOrCompany(accountResponses, salesflareContacts, linkedinContacts, companyObject);
        }

        async function goToAccountOrCompany(accountsOrCompanies, salesflareContacts, linkedinContacts, companyObject) {

            // Hide potential previous "No related account" toast
            // Note: this hides any toast, not just the previous "No related account" one. Related to https://github.com/Salesflare/Server/issues/7320
            utils.hideToast('NEW STATE');

            // Check on the presence of the owner property to distinguish accounts and companies
            const goToState = accountsOrCompanies[0].owner ? 'accounts.account.info' : 'accounts.company.info';

            // Only send over existing contacts to add to suggestions, when their domain is in the general blacklist
            let suggestedContacts = [];
            if (salesflareContacts?.length > 0 || linkedinContacts?.length > 0) {
                salesflareContacts = salesflareContacts ? salesflareContacts : [];
                linkedinContacts = linkedinContacts ? linkedinContacts : [];
                const blacklistedExistingContacts = accountsOrCompanies[0].blacklisted_suggestion_ids;
                suggestedContacts = [...linkedinContacts, ...salesflareContacts.filter((contactObject) => (blacklistedExistingContacts && blacklistedExistingContacts.includes(contactObject.id)))];
            }

            // The LinkedIn sidebar sometimes reloads the same state, we don't want to actually rerender the sidebar in that case
            if ($state.current.name !== goToState || $state.params.id !== accountsOrCompanies[0].id) {
                await $state.go(goToState, {
                    id: accountsOrCompanies[0].id,
                    accounts: angular.toJson(accountsOrCompanies),
                    name: accountsOrCompanies[0].name,
                    contacts: angular.toJson(suggestedContacts),
                    privateCompanyInfo: companyObject
                });

                if ($scope.sidebarContextLoading) {
                    completeSidebarContextLoading();
                }
            }
        }

        function completeSidebarContextLoading() {

            // Wrap with apply to avoid ng-show / ng-hide problems with the fab button showing for a short time
            $scope.$apply(() => {

                $scope.sidebarContextLoading = false;
            });
        }

        $scope.skipWalkthrough = function (reason) {

            // Team needs to be updated
            sfWalkthrough.stopWalkthrough('accounts', reason);
        };

        $scope.walkthroughIsShowing = () => {

            return sfWalkthrough.isShowing();
        };

        $scope.tourIsShowing = () => {

            return sfWalkthrough.isShowingTour();
        };
    }
})();
