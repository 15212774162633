(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('OpportunityController', OpportunityController);

    function OpportunityController($rootScope, $scope, $state, $stateParams, $mdMedia, $mdDialog, $q, events, opportunityService, users, accounts, companies, model, account, company, flagsService, tagsService, pipelines, sfDiscardDialog, customfields, $timeout, utils, sfWalkthrough, fileService) {

        $scope.model = model;
        $scope.currentUser = model.me;
        $scope.id = $stateParams.id;
        $scope.updating = false;
        $scope.account = $stateParams.account;
        $scope.selectedAccount = null;
        $scope.pipelines = [];
        $scope.pipeline = null;
        $scope.stages = [];
        $scope.opportunity = {};
        $scope.opportunity.tags = [];
        $scope.probabilityIsGreaterThanZero = false;
        $scope.extendedEditRights = false;
        $scope.contractDateWarning = {};
        $scope.contractDurationUnit = null;

        let loggedInUserIsPartOfAccount = false;
        let opportunityOwnerIsPartOfAccount = false;
        let showOpportunityAssigneeWarning = false;
        const broadCastOpportunityUpdated = $state.includes('opportunities.stages') || $state.includes('opportunities.timeline');
        const broadCastAccountUpdated = $state.includes('accounts.account');
        const removedTags = [];
        let clearDeleteTagsWatcher = null;
        let usersInSelectedAccount = [];
        let thread = null;
        const filesToDelete = [];

        get();

        $scope.$watch('!(opportunity.account && !opportunity.account.id && !opportunity.account.picture)', function (newValue) {

            if (newValue && angular.element('#website').is(':focus')) {
                angular.element('#value').focus();
            }
        });

        $scope.$watch('selectedAccount', function (newValue) {

            if (newValue) {
                if (newValue.title) {
                    $scope.selectedAccount = null;
                    $scope.searchAccount = $scope.opportunity.account.name;
                }
                else {
                    $scope.opportunity.account = {};
                    $scope.opportunity.account.name = newValue.name;

                    // If clearbit company create company
                    if (newValue.logo) {
                        $scope.opportunity.account.picture = newValue.logo;
                        $scope.opportunity.account.website = 'https://' + newValue.domain;
                        usersInSelectedAccount = [];
                        $scope.checkOwnerWarning();
                        $scope.checkAssigneeWarning();
                        return company.create(newValue);
                    }

                    if (newValue.id) {
                        $scope.opportunity.account = newValue;

                        return account.getUsers(newValue.id).then(function (response) {

                            usersInSelectedAccount = response.data;
                            $scope.checkOwnerWarning();
                            $scope.checkAssigneeWarning();
                        });
                    }
                }
            }
        });

        $scope.checkOwnerWarning = function () {

            if ($scope.opportunity.owner && $scope.opportunity.owner.title) {
                $scope.opportunity.owner = null;
                $scope.searchOwner = null;
                return;
            }

            if (!$scope.opportunity.owner || !$scope.opportunity.account) {
                return;
            }

            $scope.opportunityForm.owner.warning = {};

            // We are dealing with a company
            if (!$scope.opportunity.account.owner) {
                $scope.opportunityForm.owner.warning.ownerIsNotPartOfAccount = false;

                if ($scope.opportunity.owner.id !== model.me.id) {
                    opportunityOwnerIsPartOfAccount = false;
                    loggedInUserIsPartOfAccount = true;
                    $scope.opportunityForm.owner.warning.ownerIsNotPartOfAccount = {
                        accountName: $scope.opportunity.account.name,
                        userName: $scope.opportunity.owner.name
                    };
                }

                // We return because we are dealing with a new account that still needs to be created
                return;
            }

            // We are dealing with an existing account
            // Check if the opportunity owner and logged in user are part of the account team
            loggedInUserIsPartOfAccount = false;
            opportunityOwnerIsPartOfAccount = false;

            $scope.opportunityForm.owner.warning.ownerIsNotPartOfAccount = false;

            for (const user of usersInSelectedAccount) {
                if (user.id === $scope.opportunity.owner.id) {
                    opportunityOwnerIsPartOfAccount = true;
                }

                if (user.id === model.me.id) {
                    loggedInUserIsPartOfAccount = true;
                }
            }

            if (!opportunityOwnerIsPartOfAccount) {
                $scope.opportunityForm.owner.warning.ownerIsNotPartOfAccount = {
                    accountName: $scope.opportunity.account.name,
                    userName: $scope.opportunity.owner.name
                };
            }
        };

        $scope.checkAssigneeWarning = function () {

            if ($scope.opportunity.assignee && $scope.opportunity.assignee.title) {
                $scope.opportunity.assignee = null;
                $scope.searchAssignee = null;
                return;
            }

            if (flagsService.get('opportunityAssigneeWarning') === false || !$scope.opportunity.assignee || !$scope.opportunity.account) {
                return;
            }

            showOpportunityAssigneeWarning = false;

            // We are dealing with a company
            if (!$scope.opportunity.account.owner) {
                if ($scope.opportunity.assignee.id !== model.me.id) {
                    showOpportunityAssigneeWarning = true;
                }

                return;
            }

            showOpportunityAssigneeWarning = true;

            for (const user of usersInSelectedAccount) {
                if (user.id === $scope.opportunity.assignee.id) {
                    showOpportunityAssigneeWarning = false;
                    break;
                }
            }
        };

        $scope.resetSelectedAccount = function (text) {

            $scope.selectedAccount = null;

            if (text) {
                $scope.opportunity.account = {};
                $scope.opportunity.account.name = text;
            }
            else {
                $scope.opportunity.account = null;
            }
        };

        $scope.back = function () {

            if (sfWalkthrough.isShowing()) {
                return;
            }
            else if ($scope.opportunityForm.$dirty) {
                return sfDiscardDialog.show($scope.id === 'new', 'opportunity').then(close);
            }

            return close();
        };

        function close(broadcast, deleted = false) {

            if ($mdMedia('gt-sm')) {
                if (broadcast) {
                    if (broadCastAccountUpdated) {
                        $rootScope.$broadcast(events.account.updated);
                    }

                    if (broadCastOpportunityUpdated) {

                        if (deleted) {
                            $rootScope.$broadcast(events.opportunity.deleted);
                        }
                        else {
                            $rootScope.$broadcast(events.refreshData);
                        }
                    }
                }

                return $mdDialog.hide();
            }

            return $rootScope.back();
        }

        $scope.delete = function () {

            if ($scope.id === 'new') {
                return close(true);
            }
            else {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .textContent('Are you sure you want to delete the opportunity?')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    $scope.updating = true;

                    return opportunityService.delete($scope.opportunity).then(function () {

                        return close(true, true);
                    });
                }, angular.noop);

            }
        };

        $scope.save = function () {

            if (!canSave()) {

                return;
            }

            if (sfWalkthrough.isShowing()) {
                $mdDialog.hide();
                sfWalkthrough.createDummyOpportunity();
                // Make sure the opportunity shows in the account info
                $rootScope.$broadcast(events.account.updated);
                sfWalkthrough.advance();
                return;
            }

            $scope.updating = true;

            // Copy so when we make changes to send to server they to do not alter the view model so when the call fails it just displays the form as it was
            const opp = angular.copy($scope.opportunity);

            // Strip recurring properties off opportunity object if the selected pipeline is non-recurring
            if ($scope.selectedPipeline.recurring) {
                opp.contract_start_date = utils.localDateObjectToUTCDateObject(opp.contract_start_date);
                opp.contract_end_date = utils.localDateObjectToUTCDateObject(opp.contract_end_date);
            }
            else {
                delete opp.units;
                delete opp.contract_start_date;
                delete opp.contract_end_date;
                delete opp.recurring_price_per_unit;

            }

            // We re-calculate this server-side
            delete opp.calculated_value;

            opp.close_date = utils.localDateObjectToUTCDateObject(opp.close_date);

            $scope.customFields.forEach(function (customField) {

                if (customField.type.type === 'date') {
                    if (customField.predefined_customfield) {
                        opp[customField.api_field] = utils.localDateObjectToUTCDateObject(opp[customField.api_field]);
                    }
                    else if (opp.custom && Object.keys(opp.custom).length > 0) {
                        opp.custom[customField.api_field] = utils.localDateObjectToUTCDateObject(opp.custom[customField.api_field]);
                    }
                }
            });

            // Existing opportunity
            if ($scope.id !== 'new') {
                // Unbinding the tag watcher, this way it'll not call the listener when removed tag are concatenated
                // on $scope.account.tags
                clearDeleteTagsWatcher();

                if (removedTags.length > 0) {
                    opp.tags = [...opp.tags, ...removedTags];
                }

                // New account
                if (!$scope.opportunity.account.owner) {
                    return account.create($scope.opportunity.account).then(function (response) {

                        opp.account.id = response.data.id;

                        return opportunityService.update(opp).then(function () {

                            deleteFiles();

                            // Model.me is the owner of the account in this case
                            if (opp.owner.id !== model.me.id) {
                                return showAddUserToAccountDialog(opp).then(function () {

                                    return redirect(opp, true);
                                });
                            }

                            if (showOpportunityAssigneeWarning) {
                                return showOpportunityAssigneeWarningDialog().then(function () {

                                    return redirect(opp, true);
                                });
                            }

                            return redirect(opp);
                        }).catch(function () {

                            $scope.updating = false;
                        });
                    }).catch(function () {

                        $scope.updating = false;
                    });
                }

                //Existing account
                return opportunityService.update(opp).then(function () {

                    deleteFiles();

                    //Show dialog if the owner of the opportunity is not part of the account but the user is
                    if (!opportunityOwnerIsPartOfAccount && loggedInUserIsPartOfAccount) {
                        return showAddUserToAccountDialog(opp).then(function () {

                            return redirect(opp, true);
                        });
                    }

                    if (showOpportunityAssigneeWarning && (loggedInUserIsPartOfAccount || model.me.is_admin)) {
                        return showOpportunityAssigneeWarningDialog().then(function () {

                            return redirect(opp, true);
                        });
                    }

                    return redirect(opp);
                }).catch(function () {

                    $scope.updating = false;
                });
            }

            //New opportunity

            //New account
            if (!$scope.opportunity.account.owner) {
                return account.create($scope.opportunity.account).then(function (response) {

                    opp.account.id = response.data.id;

                    return opportunityService.create(opp).then(function () {

                        sfWalkthrough.advance();

                        deleteFiles();

                        if (opp.owner.id !== model.me.id) {
                            return showAddUserToAccountDialog(opp).then(function () {

                                return redirect(opp, true);
                            });
                        }

                        if (showOpportunityAssigneeWarning) {
                            return showOpportunityAssigneeWarningDialog().then(function () {

                                return redirect(opp, true);
                            });
                        }

                        return redirect(opp);
                    });
                }).catch(function () {

                    $scope.updating = false;
                });
            }

            //Existing account
            return opportunityService.create(opp).then(function () {

                sfWalkthrough.advance();

                deleteFiles();

                if (!opportunityOwnerIsPartOfAccount && loggedInUserIsPartOfAccount) {
                    return showAddUserToAccountDialog(opp).then(function () {

                        return redirect(opp, true);
                    });
                }

                if (showOpportunityAssigneeWarning) {
                    return showOpportunityAssigneeWarningDialog().then(function () {

                        return redirect(opp, true);
                    });
                }

                return redirect(opp);
            }).catch(function () {

                $scope.updating = false;
            });
        };

        $scope.searchUsers = function (queryString) {

            // If no account is selected, only search in users of team
            if (!($scope.opportunity.account && $scope.opportunity.account.id)) {

                return users.get(queryString, true).then(function (response) {

                    return response.data;
                });
            }

            // If the search field is empty, show only the users related to the selected account
            if (!queryString || queryString === '') {

                const filteredUsers = angular.copy(usersInSelectedAccount);

                if (filteredUsers.length > 0) {
                    filteredUsers.unshift({ title: 'Related to ' + $scope.opportunity.account.name });
                }

                return filteredUsers;
            }

            return $q(function (resolve) {

                $timeout.cancel(thread);
                thread = $timeout(function () {

                    return users.get(queryString, true).then(function (response) {

                        let filteredUsers = response.data.filter(function (item) {

                            for (const element of usersInSelectedAccount) {
                                if (item.id === element.id) {
                                    return false;
                                }
                            }

                            return true;
                        });

                        const filteredUsersInSelectedAccount = usersInSelectedAccount.filter(function (item) {

                            return item.name.includes(queryString) || item.email.includes(queryString);
                        });

                        if (filteredUsersInSelectedAccount.length > 0) {
                            filteredUsersInSelectedAccount.unshift({ title: 'Related to ' + $scope.opportunity.account.name });
                        }

                        if (filteredUsers.length > 0) {
                            filteredUsers.unshift({ title: 'Others' });
                        }

                        filteredUsers = [...filteredUsersInSelectedAccount, ...filteredUsers];

                        return resolve(filteredUsers);
                    });
                }, 750);
            });
        };

        $scope.getAccount = function (filter) {

            let result = [];

            return accounts.get(filter).then(function (response) {

                if (response.data.length > 0) {
                    result.push({ type: 'title', title: 'Existing accounts' });

                    result = [...result, ...response.data];
                }

                result.push({ type: 'title', title: 'Create new' });

                return companies.get(filter).then(angular.bind(this, function (companiesResponse) {

                    result = [...result, ...companiesResponse.data];

                    result.push({ name: filter });

                    return result;
                }));
            });
        };

        $scope.changePipeline = function (pipeline) {

            if ($scope.selectedPipeline === pipeline) {
                return;
            }

            $scope.opportunityForm.pipeline.$setValidity('default', true);

            $scope.selectedPipeline = pipeline;
            $scope.stages = $scope.selectedPipeline.stages;
            $scope.opportunity.currency = $scope.selectedPipeline.currency || model.me.team.currency;

            if ($scope.stages.length > 0) {
                if ($scope.opportunity.stage.fixed_stage === 1 || $scope.opportunity.stage.fixed_stage === 2) {
                    $scope.opportunity.stage = $scope.stages.find(function (stage) {

                        return stage.fixed_stage === $scope.opportunity.stage.fixed_stage;
                    });

                    if (!$scope.opportunity.stage) {
                        $scope.opportunity.stage = $scope.stages[0];
                    }
                }
                else {
                    $scope.opportunity.stage = $scope.stages[0];
                }
            }

            return $scope.stageChanged().then(function () {

                // Reset custom field data
                $scope.opportunity.custom = {};
                // eslint-disable-next-line unicorn/no-array-method-this-argument
                angular.forEach($scope.opportunity, function (value, key) {

                    const predefinedField = $scope.customFields.find(function (customField) {

                        return customField.predefined_customfield && customField.api_field === key;
                    });

                    if (predefinedField) {
                        delete $scope.opportunity[key];
                    }
                });

                if (!$scope.opportunity.id) {
                    return;
                }

                return opportunityService.getCustomFields($scope.opportunity.id, $scope.selectedPipeline.id).then(function (response) {

                    // Apply custom field data from new pipeline
                    response.data.forEach(function (customField) {

                        let tempVal;
                        if (['tags', 'autocomplete', 'select', 'multiselect'].includes(customField.type.type)) {
                            tempVal = customField.current_options;
                        }
                        else if (['file'].includes(customField.type.type)) {
                            tempVal = customField.current_files;
                        }
                        else {
                            tempVal = customField.value;
                        }

                        if (!tempVal && tempVal !== false) {
                            return;
                        }

                        if (customField.predefined_customfield) {
                            $scope.opportunity[customField.api_field] = tempVal;
                        }
                        else {
                            $scope.opportunity.custom[customField.api_field] = tempVal;
                        }
                    });
                });
            });
        };

        // Make sure we can only select valid dates
        // And if we select a lost stage we possibly need to add the lost reason to the custom field list
        $scope.stageChanged = function (oldStageId) {

            let oldStage;

            for (let i = 0; i < $scope.stages.length; ++i) {
                if (oldStageId && $scope.stages[i].id === Number.parseInt(oldStageId)) {
                    oldStage = $scope.stages[i];
                }

                if ($scope.stages[i].id === $scope.opportunity.stage.id) {
                    $scope.closeDateIsRequired = $scope.stages[i].probability > 0 || $scope.stages[i].fixed_stage === 1 || $scope.stages[i].fixed_stage === 2;

                    if ($scope.stages[i].fixed_stage === 1 || $scope.stages[i].fixed_stage === 2) {
                        $scope.minDate = null;

                        // We do not want to set the close date to today when we haven't changed the stage yet (initially showing an opportunity with won/lost stage)
                        if (angular.isDefined(oldStageId)) {
                            $scope.opportunity.close_date = utils.getTodayAtMidnight();
                            $scope.opportunityForm.stage.warning = { closeDateSetToToday: false, closeDateAndContractStartDateSetToToday: false };

                            if ($scope.selectedPipeline.recurring && $scope.stages[i].fixed_stage === 1) {
                                $scope.opportunity.contract_start_date = utils.getTodayAtMidnight();
                                $scope.opportunityForm.stage.warning = { closeDateAndContractStartDateSetToToday: true };
                                $scope.getCalculatedValue();
                            }
                            else {
                                $scope.opportunityForm.stage.warning = { closeDateSetToToday: true };
                            }
                        }
                    }
                    else if ($scope.stages[i].probability === 100 || $scope.stages[i].probability === 0) {
                        $scope.minDate = null;
                    }
                    else {
                        $scope.minDate = utils.getTodayAtMidnight();
                    }
                }
            }

            if (oldStageId) {
                if ($scope.opportunity.probability < $scope.opportunity.stage.probability || $scope.opportunity.stage.probability < oldStage.probability) {
                    $scope.opportunity.probability = $scope.opportunity.stage.probability;
                }
            }

            return loadCustomFields();
        };

        $scope.addTag = function (addedTag) {

            if (addedTag && addedTag.name) {
                for (let i = 0; i < removedTags.length; ++i) {
                    if (addedTag.name === removedTags[i].name) {
                        removedTags.splice(i, 1);
                        break;
                    }
                }
            }

            return setDirty(addedTag);
        };

        $scope.searchTags = function (queryString) {

            if (!queryString || queryString === '') {
                return [];
            }

            return $q(function (resolve) {

                return tagsService.get({ search: queryString, limit: 50 }).then(function (response) {

                    const filteredTags = response.data.filter(function (item) {

                        for (let i = 0; i < $scope.opportunity.tags.length; ++i) {
                            if (item.name === $scope.opportunity.tags[i].name) {
                                return false;
                            }
                        }

                        return true;
                    });

                    // eslint-disable-next-line no-var, @hapi/no-var
                    for (var i = 0; i < filteredTags.length; ++i) {
                        if (filteredTags[i].name.toLowerCase() === queryString.toLowerCase()) {
                            const matchedTag = filteredTags[i];

                            filteredTags[i] = filteredTags[0];
                            filteredTags[0] = matchedTag;
                            break;
                        }
                    }

                    if (i >= filteredTags.length || filteredTags.length === 0) {
                        filteredTags.unshift({ name: queryString });
                    }

                    return resolve(filteredTags);
                });
            });
        };

        $scope.adjustRecurringPricePerUnit = function () {

            if (!$scope.opportunity.frequency) {
                return;
            }

            const standardPricePerUnit = $scope.selectedPipeline['standard_price_per_unit_' + $scope.opportunity.frequency.toLowerCase()];

            if (standardPricePerUnit) {
                $scope.opportunity.recurring_price_per_unit = standardPricePerUnit;
            }
        };

        $scope.getCalculatedValue = function () {

            if (!$scope.opportunity || !$scope.selectedPipeline.recurring) {
                return;
            }

            if (!$scope.opportunity.frequency || !$scope.opportunity.recurring_price_per_unit || (($scope.opportunity.contract_start_date && $scope.opportunity.contract_end_date) && ($scope.opportunity.contract_start_date > $scope.opportunity.contract_end_date))) {
                $scope.opportunity.calculated_value = $scope.opportunity.value;
                return;
            }

            let amountOfIntervalsInContractDuration = 0;
            const oneTimeValue = $scope.opportunity.value || 0;

            const expectedCustomerLifetimeInYears = $scope.selectedPipeline.expected_customer_lifetime_months / 12;

            // This logic is also used server-side to calculate the value
            // Any change you make here, you'll probably want to change in the server too
            switch ($scope.opportunity.frequency.toLowerCase()) {
                case 'daily':
                    $scope.contractDurationUnit = 'days';

                    if ($scope.opportunity.contract_start_date && $scope.opportunity.contract_end_date) {
                        amountOfIntervalsInContractDuration = utils.getDayDifference($scope.opportunity.contract_start_date, $scope.opportunity.contract_end_date);
                    }
                    else {
                        amountOfIntervalsInContractDuration = Math.round(expectedCustomerLifetimeInYears * 365);
                    }

                    break;
                case 'weekly':
                    $scope.contractDurationUnit = 'weeks';

                    if ($scope.opportunity.contract_start_date && $scope.opportunity.contract_end_date) {
                        amountOfIntervalsInContractDuration = utils.getWeekDifference($scope.opportunity.contract_start_date, $scope.opportunity.contract_end_date);
                    }
                    else {
                        amountOfIntervalsInContractDuration = Math.ceil(expectedCustomerLifetimeInYears * 52);
                    }

                    break;
                case 'monthly':
                    $scope.contractDurationUnit = 'months';

                    if ($scope.opportunity.contract_start_date && $scope.opportunity.contract_end_date) {
                        amountOfIntervalsInContractDuration = utils.getMonthDifference($scope.opportunity.contract_start_date, $scope.opportunity.contract_end_date);
                    }
                    else {
                        amountOfIntervalsInContractDuration = Math.ceil($scope.selectedPipeline.expected_customer_lifetime_months);
                    }

                    break;
                case 'annually':
                    $scope.contractDurationUnit = 'years';

                    if ($scope.opportunity.contract_start_date && $scope.opportunity.contract_end_date) {
                        amountOfIntervalsInContractDuration = utils.getYearDifference($scope.opportunity.contract_start_date, $scope.opportunity.contract_end_date);
                    }
                    else {
                        amountOfIntervalsInContractDuration = Math.ceil(expectedCustomerLifetimeInYears);
                    }

                    break;
            }

            $scope.contractDuration = amountOfIntervalsInContractDuration;
            $scope.opportunity.calculated_value = oneTimeValue + ($scope.opportunity.units * ($scope.opportunity.recurring_price_per_unit * amountOfIntervalsInContractDuration));
        };

        $scope.setAccountEditableValidity = function () {

            $scope.opportunityForm.account.$setValidity('accountEditable', $scope.selectedAccount && $scope.selectedAccount.can_edit ? $scope.selectedAccount.can_edit : true);
        };

        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;

        $scope.$watch('opportunity.close_date', checkFutureCloseDate);
        $scope.$watch('opportunity.stage', checkFutureCloseDate);

        $scope.onFilesChange = function () {

            return $scope.opportunityForm.$setDirty();
        };

        $scope.onFilesDelete = function ($event) {

            filesToDelete.push($event.fileId);
        };

        $scope.getNotPredefinedCustomFields = function () {

            if (!$scope.customFields) {
                return [];
            }

            return $scope.customFields.filter(function (field) {

                return !field.predefined_customfield;
            });
        };

        $scope.onNewCustomFieldOption = function (customField) {

            return customfields.updateOpportunityField(customField).then(function () {

                const customFieldId = customField.id;

                return customfields.getAllOptions(customFieldId, 'opportunity').then(function (response) {

                    const foundCustomField = $scope.customFields.find((function (field) {

                        return field.id === customFieldId;
                    }));

                    foundCustomField.options = response.data.map(function (option) {

                        return { id: option.id, name: option.name, order: option.order };
                    });
                });
            });
        };


        function checkFutureCloseDate() {

            if (!$scope.opportunity || !$scope.opportunity.close_date) {
                return $scope.opportunityForm.closeDate.$setValidity('futureCloseDate', true);
            }

            if (!$scope.opportunity.stage.fixed_stage && ($scope.opportunity.stage.probability < 100 && $scope.opportunity.stage.probability > 0)) {
                return $scope.opportunityForm.closeDate.$setValidity('futureCloseDate', $scope.opportunity.close_date >= utils.getTodayAtMidnight());
            }

            return $scope.opportunityForm.closeDate.$setValidity('futureCloseDate', true);
        }

        function get() {

            return pipelines.getViewable().then(function (response) {

                $scope.pipelines = response.pipelines;

                if ($scope.id === 'new') {
                    if ($scope.pipelines.length > 0) {
                        const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                        if (currentPipelineId) {
                            $scope.selectedPipeline = $scope.pipelines.find(function (pipeline) {

                                return pipeline.id === currentPipelineId;
                            });
                        }

                        if (!$scope.selectedPipeline) {
                            $scope.selectedPipeline = $scope.pipelines[0];
                        }

                        $scope.opportunity.currency = $scope.selectedPipeline.currency || model.me.team.currency;
                        $scope.stages = $scope.selectedPipeline.stages;
                    }

                    if ($scope.stages.length > 0) {
                        $scope.opportunity.stage = $scope.stages[0];

                        $scope.stageChanged();
                    }

                    if (!$scope.opportunity.custom) {
                        $scope.opportunity.custom = {};
                    }

                    loadCustomFields();

                    const user = {
                        id: model.me.id,
                        name: model.me.name
                    };

                    $scope.opportunity.owner = user;
                    $scope.opportunity.assignee = user;
                    $scope.extendedEditRights = ($scope.opportunity.owner.id === model.me.id || ['Admin', 'Manager'].includes(model.me.role.name));
                    $scope.opportunity.units = 1;

                    // Pre-fill the walkthrough opportunity
                    if (sfWalkthrough.isShowing()) {
                        $scope.opportunity.name = 'Using Salesflare';
                        $scope.opportunity.value = 10000;
                        $scope.opportunity.close_date = new Date();
                    }

                    if ($stateParams.account) {
                        return account.get($stateParams.account).then(function (accountResponse) {

                            $scope.opportunity.account = accountResponse.data;
                            $scope.selectedAccount = $scope.opportunity.account;
                        });
                    }
                }
                else {
                    return opportunityService.get($scope.id).then(function (opportunityResponse) {

                        $scope.opportunity = opportunityResponse.data;
                        $scope.selectedAccount = $scope.opportunity.account;

                        $scope.selectedPipeline = $scope.pipelines.find(function (pipeline) {

                            return pipeline.id === $scope.opportunity.stage.pipeline;
                        });

                        $scope.stages = $scope.selectedPipeline.stages;

                        //Resetting the stage to prevent the md-select is set to dirty (fixes issue #715)
                        if ($scope.stages.length > 0) {
                            $scope.opportunity.stage = $scope.stages.find(function (stage) {

                                return stage.id === $scope.opportunity.stage.id;
                            });
                        }

                        $scope.stageChanged();

                        $scope.opportunity.close_date = utils.UTCDateStringToLocalDateObject($scope.opportunity.close_date);
                        $scope.opportunity.contract_start_date = utils.UTCDateStringToLocalDateObject($scope.opportunity.contract_start_date);
                        $scope.opportunity.contract_end_date = utils.UTCDateStringToLocalDateObject($scope.opportunity.contract_end_date);

                        if ($scope.selectedPipeline.recurring) {
                            $scope.getCalculatedValue();
                        }

                        $scope.extendedEditRights = ($scope.opportunity.owner.id === model.me.id || ['Admin', 'Manager'].includes(model.me.role.name));

                        //Adding watcher to tags model
                        clearDeleteTagsWatcher = $scope.$watch('opportunity.tags', deleteTag, true);

                        if (!$scope.opportunity.custom) {
                            $scope.opportunity.custom = {};
                        }

                        return loadCustomFields();
                    });
                }
            });
        }

        function loadCustomFields() {

            return customfields.getOpportunityFields(false, $scope.selectedPipeline.id).then(function (response) {

                if (!response.data) {
                    $scope.customFields = null;
                    return;
                }

                $scope.customFields = response.data.filter(function (customField) {

                    // Don't show lost reason when not in a lost stage
                    if (customField.api_field === 'lost_reason' && $scope.opportunity.stage.fixed_stage !== 2) {
                        return false;
                    }

                    return true;
                });

                utils.setCustomFieldsOnEntity($scope.opportunity, $scope.customFields);
            });
        }

        function canSave() {

            if ($scope.opportunityForm.$invalid) {
                return false;
            }

            if (!$scope.opportunity.account) {
                // Because autocomplete
                $scope.opportunityForm.account.$invalid = true;
                $scope.opportunityForm.account.$error.required = true;

                return false;
            }

            return true;
        }

        function showAddUserToAccountDialog(opportunity) {

            const confirm = $mdDialog.confirm()
                .textContent('Do you want to add ' + opportunity.owner.name + ' to the ' + opportunity.account.name + ' team as well?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                opportunity.owner._dirty = true;

                return account.updateUsers(opportunity.account.id, [opportunity.owner]).then(function () {

                    if (showOpportunityAssigneeWarning) {
                        return showOpportunityAssigneeWarningDialog();
                    }

                    return $q.resolve();
                });
            }, function () {

                if (showOpportunityAssigneeWarning) {
                    return showOpportunityAssigneeWarningDialog();
                }

                return $q.resolve();
            });
        }

        function showOpportunityAssigneeWarningDialog() {

            const opportunityAssigneeWarningDialog = $mdDialog.confirm()
                .textContent('When you assign an opportunity to a team member, he/she is added automatically to the account\'s team and can see the timeline.')
                .clickOutsideToClose(true)
                .ariaLabel('Opportunity assignee warning dialog')
                .ok('Don\'t show again')
                .cancel('Ok');

            return $mdDialog.show(opportunityAssigneeWarningDialog).then(function () {

                // Update the flag so we never show the dialog again
                flagsService.set('opportunityAssigneeWarning', false);
                return $q.resolve();
            }, function () {

                return $q.resolve();
            });
        }

        // Call with refresh true if we are coming from another dialog to manually broadcast the refreshData event
        function redirect(opportunity, refresh) {

            if (!opportunity) {
                return;
            }

            // Normally just hiding the dialog will cause a `refreshData` broadcast (see routing.js)
            // but since we add another dialog in the flow we need to do this manually
            if (refresh) {
                if (broadCastAccountUpdated) {
                    $rootScope.$broadcast(events.account.updated);
                }

                if (broadCastOpportunityUpdated) {
                    $rootScope.$broadcast(events.refreshData);
                }
            }

            if (opportunity.id) {
                return close(true);
            }

            if ($mdMedia('gt-sm')) {
                if ($state.current.name.startsWith('opportunities')) {
                    $rootScope.$broadcast(events.refreshData);
                    return $mdDialog.hide();
                }
                else {
                    if (broadCastAccountUpdated) {
                        $rootScope.$broadcast(events.account.updated);
                    }

                    $mdDialog.hide();
                    return $state.go('accounts.account.feed', { id: opportunity.account.id });
                }
            }

            return $state.go('accounts.account.info', { id: opportunity.account.id });
        }

        function deleteTag(newValue, oldValue) {

            if (!$scope.id !== 'new' && newValue && oldValue && oldValue.length > newValue.length) {
                for (const [i, element] of oldValue.entries()) {
                    if (!element._dirty &&
                        (angular.isUndefined(newValue[i]) || element.name !== newValue[i].name)
                    ) {
                        element._deleted = true;
                        setDirty(element);
                        removedTags.push(element);
                        break;
                    }
                }
            }
        }

        $scope.searchOptions = function (queryString, options, apiField) {

            if (!queryString || queryString === '') {
                if (apiField) {
                    $scope.opportunity.custom[apiField] = null;
                }

                return [];
            }

            return $q(function (resolve) {

                $timeout.cancel(thread);
                thread = $timeout(function () {

                    if (!options) {
                        options = [];
                    }

                    const filteredOptions = options.filter(function (option) {
                        return option.name.toLowerCase().includes(queryString.toLowerCase());
                    });

                    if (filteredOptions.length === 0) {
                        filteredOptions.unshift({ name: queryString });
                    }

                    return resolve(filteredOptions);
                }, 750);
            });
        };

        $scope.walkthroughInputCheck = function ($event) {

            if (sfWalkthrough.isShowing()) {
                // Don't propagate click events to the input fields
                $event.stopPropagation();

                $mdDialog.show({
                    controller: 'CantDoDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/cantDoDialog/cantDoDialog.html',
                    bindToController: true,
                    clickOutsideToClose: false,
                    multiple: true,
                    locals: {
                        title: 'Just click create 😀',
                        htmlContent: 'We love that you want to try out more of the app.<br>However, during the walkthrough, let\'s keep it simple.'
                    }
                });
            }
        };

        $scope.onFilesDelete = function ($event) {

            filesToDelete.push($event.fileId);
        };

        function deleteFiles() {

            filesToDelete.forEach(function (fileId) {

                fileService.deleteFile(fileId);
            });
        }

        function setDirty(item) {

            if (item) {
                item._dirty = true;
            }
        }
    }
})();
