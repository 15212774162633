(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('UpgradeDialogController', UpgradeDialogController);

    function UpgradeDialogController($scope, $state, $mdDialog, $mdMedia, $window, authentication, model, plansService, sfUpgradeConfirmationDialog, utils) {

        $scope.$mdMedia = $mdMedia;
        $scope.currency = model.me.team.billing_currency;
        $scope.transitioned = false;
        $scope.subscribedOrNonRestrictedSumo = model.me.team.subscribed || (model.me.team.discount_type === 'firstFreeThen50Off' && !model.me.restricted);
        $scope.currentlyOnPlansPage = ($state.current.name === 'plans');

        const flag = this.flag;

        init();

        $scope.$on('$destroy', function () {

            $window.removeEventListener('focus', focusEventListener);
        });

        $scope.discard = function () {

            return $mdDialog.hide(false);
        };

        $scope.close = function () {

            return $mdDialog.cancel();
        };

        $scope.upgrade = function () {

            if (model.me.is_admin) {
                if ($scope.subscribedOrNonRestrictedSumo) {
                    return sfUpgradeConfirmationDialog.show($scope.upgradePlan)
                        .then($mdDialog.hide)
                        .then(authentication.fetchMe);
                }

                return $mdDialog.hide().then(function () {

                    return $state.go('checkout', { plan: 'pro' });
                });
            }

            let action = '';
            if ($scope.subscribedOrNonRestrictedSumo) {
                action = 'upgrade';
            }
            else {
                action = 'subscribe';
            }

            const adminAlert = $mdDialog.alert()
                .title('Please ask an admin to ' + action)
                .htmlContent('<div>You don\'t have the permissions to ' + action + '.</div><div>Please ask a team admin to ' + action + ' to your desired plan.</div>')
                .multiple(true)
                .ok('Got it');

            $mdDialog.show(adminAlert);
        };

        $scope.close = function () {

            return $mdDialog.hide(false);
        };

        $scope.goToPlans = function () {

            if (!$scope.currentlyOnPlansPage) {
                if ($window.isMobile) {
                    return $state.go('plans');
                }

                // Open in a new window on desktop
                // Utils will handle the closing of the opened window when done
                // `focusEventListener` handles what happens when we did subscribe in the opened window
                return utils.popup($state.href('plans'), '_blank');
            }
        };

        function init() {

            // We need this to check if a user came back to this state after subscribing from another tab (via 'compare plans' in upgrade dialog)
            // If the user subscribed this way, reload the state only when on the billing overview, we don't want to lose potentially unsaved work.
            $window.addEventListener('focus', focusEventListener);

            $scope.upgradePlan = plansService.get().pro;

            const selectedFeatureIndex = $scope.upgradePlan.features.findIndex(function (feature) {

                return feature.flag === flag;
            });
            const titles = {
                multiStepWorkflows: 'Want to send multi-step email workflows?',
                permissions: 'Want to set permissions?',
                dashboards: 'Want to create custom reports and dashboards?'
            };

            if (flag) {
                $scope.title = titles[flag];
            }

            $scope.sliderConfig = {
                startSlide: selectedFeatureIndex === -1 ? 0 : selectedFeatureIndex,
                draggable: true,
                continuous: true,
                disableScroll: false,
                stopPropagation: false
            };
        }

        function focusEventListener() {

            if (!model.me.team.subscribed) {
                authentication.fetchMe()
                    .then(function () {

                        if (model.me.team.subscribed) {
                            $window.removeEventListener('focus', focusEventListener);

                            $mdDialog.hide().then(function () {

                                if ($state.current.name === 'settings.billingSettings.overview') {
                                    $state.reload();
                                }
                            });
                        }
                    });
            }
        }
    }
})();
