(function () {
    'use strict';

    angular
        .module('salesflare.components.bulkEditOpportunities', [])
        .component('sfBulkEditOpportunities', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/bulkeditopportunities/bulkeditopportunities.html',
            bindings: {
                options: '<',
                pipelineId: '<'
            }
        });

    function controller($rootScope, $q, $mdMedia, $mdDialog, $exceptionHandler, $filter, utils, customfields, bulkService, users, accounts, pipelines, tagsService, model) {

        const vm = this;
        const defaultOpportunity = {
            custom: {},
            added_custom: {},
            removed_custom: {}
        };
        let allFields;

        vm.currentUser = model.me;

        vm.$onInit = function () {

            if (!vm.options || Object.keys(vm.options).length === 0) {
                return vm.back();
            }

            vm.$mdMedia = $mdMedia;
            vm.updating = false;

            vm.fields = [
                {
                    name: 'Owner',
                    type: {}
                },
                {
                    name: 'Assignee',
                    type: {}
                },
                {
                    name: 'Account',
                    type: {}
                },
                {
                    name: 'Name',
                    type: {}
                },
                // We add pipelines when there are multiple pipelines
                {
                    name: 'Stage',
                    type: {}
                },
                {
                    name: 'Close Date',
                    type: {}
                },
                {
                    name: 'Tags',
                    type: {}
                },
                {
                    name: 'Done',
                    type: {}
                }
            ];
            vm.pipelineId = vm.pipelineId || store.get(`current_pipeline_${model.me.id}`);

            loadCustomFields();

            return pipelines.get().then(function (response) {

                vm.pipelines = response.data;

                if (vm.pipelines.length > 1) {
                    vm.fields.forEach(function (field, index) {

                        if (field.name === 'Stage' && vm.fields[index - 1].name !== 'Pipeline') {
                            vm.fields.splice(index, 0, { name: 'Pipeline', type: {} });
                        }
                    });
                }
            });
        };

        vm.resetOpportunity = function () {

            vm.opportunity = angular.copy(defaultOpportunity);
            vm.searchText = undefined;
            vm.lostReasonCustomField = undefined;

            if (vm.pipelineId) {
                vm.selectedPipeline = vm.pipelines.find(function (pipeline) {

                    return pipeline.id === vm.pipelineId;
                });
            }
            else {
                vm.selectedPipeline = vm.pipelines[0];
            }

            if (vm.selectedField.name === 'Stage' || vm.selectedField.name === 'Pipeline') {
                vm.opportunity.stage = vm.selectedPipeline.stages[0];
            }
            else {
                delete vm.opportunity.stage;
            }

            if (vm.selectedField.name === 'Done') {
                vm.opportunity.done = false;
            }

            if (['text', 'large_text', 'url'].includes(vm.selectedField.type.type)) {
                if (vm.selectedField.predefined_customfield) {
                    vm.opportunity[vm.selectedField.api_field] = '';
                }
                else {
                    vm.opportunity.custom[vm.selectedField.api_field] = '';
                }
            }

            if (['number', 'date', 'select'].includes(vm.selectedField.type.type)) {
                if (vm.selectedField.predefined_customfield) {
                    vm.opportunity[vm.selectedField.api_field] = null;
                }
                else {
                    vm.opportunity.custom[vm.selectedField.api_field] = null;
                }
            }

            if (vm.selectedField.type.type === 'boolean') {
                if (vm.selectedField.predefined_customfield) {
                    vm.opportunity[vm.selectedField.api_field] = false;
                }
                else {
                    vm.opportunity.custom[vm.selectedField.api_field] = false;
                }
            }

            if (['tags', 'account', 'accounts', 'contact', 'contacts', 'user', 'users'].includes(vm.selectedField.type.type)) {
                vm.opportunity.added_custom[vm.selectedField.api_field] = [];
                vm.opportunity.removed_custom[vm.selectedField.api_field] = [];
            }

            if (vm.selectedField.name === 'Tags') {
                vm.opportunity.added_tags = [];
                vm.opportunity.removed_tags = [];
            }
        };

        vm.searchUsers = function searchUsers(query) {

            if (vm.selectedField.name === 'Owner') {
                vm.opportunityForm.owner.warning = vm.opportunityForm.owner.warning || {};
                vm.opportunityForm.owner.warning.ownerAccess = !model.me.is_admin;
            }

            return users.get(query).then(function (response) {

                return $q.resolve(response.data);
            }, null, function (response) {

                return $q.resolve(response.data);
            });
        };

        vm.searchAccounts = function searchAccounts(query) {

            return accounts.get(query).then(function (response) {

                return $q.resolve(response.data);
            }, null, function (response) {

                return $q.resolve(response.data);
            });
        };

        vm.changePipeline = function (pipeline) {

            vm.selectedPipeline = pipeline;
            vm.opportunity.stage = vm.selectedPipeline.stages[0];

            if (vm.stageRequiresCloseDate(vm.opportunity.stage)) {
                vm.opportunity.close_date = new Date();
            }
        };

        vm.onStageChanged = function () {

            delete vm.opportunity.lost_reason;

            if (vm.stageRequiresCloseDate(vm.opportunity.stage)) {
                vm.opportunity.close_date = new Date();
            }

            // If stage is a lost one, find the lost reason custom field since we require a lost reason for lost stages we need this to show the selector
            if (vm.opportunity.stage.fixed_stage === 2) {
                vm.lostReasonCustomField = allFields.find(function (field) {

                    return field.predefined_customfield === 1 && field.pipeline === vm.selectedPipeline.id;
                });
            }
            else {
                vm.lostReasonCustomField = undefined;
            }
        };

        vm.onCloseDateChanged = function () {

            vm.opportunityForm.closeDate.warning = vm.opportunityForm.closeDate.warning || {};
            vm.opportunityForm.closeDate.warning.pastDate = vm.opportunity.close_date < utils.getStartOfLocalDay(new Date());
        };

        vm.back = function () {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(false);
            }

            return $rootScope.back();
        };

        vm.setAccountEditableValidity = function () {

            vm.opportunityForm.account.$setValidity('accountEditable', vm.opportunity.account ? vm.opportunity.account.can_edit : true);
        };

        vm.save = function () {

            if (!vm.opportunityForm.$valid) {
                return;
            }

            vm.updating = true;

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('These changes will be applied to all the selected opportunities!')
                .ok('I\'m sure')
                .cancel('cancel');

            return $mdDialog.show(confirm).then(function () {

                const opportunity = angular.copy(vm.opportunity);

                vm.fields.forEach(function (customField) {

                    if (customField.type.type === 'date') {
                        if (customField.predefined_customfield && opportunity[customField.api_field]) {
                            opportunity[customField.api_field] = $filter('date')(opportunity[customField.api_field], 'yyyy-MM-dd');
                        }
                        else if (opportunity.custom && !customField.predefined_customfield && opportunity.custom[customField.api_field]) {
                            opportunity.custom[customField.api_field] = $filter('date')(opportunity.custom[customField.api_field], 'yyyy-MM-dd');
                        }
                    }
                });

                if (opportunity.close_date) {
                    opportunity.close_date = $filter('date')(opportunity.close_date, 'yyyy-MM-dd');
                }

                // Remove non needed props
                if (opportunity.added_tags) {
                    opportunity.added_tags = opportunity.added_tags.map(function (tag) {

                        return { id: tag.id, name: tag.name };
                    });
                }

                if (opportunity.removed_tags) {
                    opportunity.removed_tags = opportunity.removed_tags.map(function (tag) {

                        return { id: tag.id };
                    });
                }

                // Object to ids
                if (opportunity.owner) {
                    opportunity.owner = opportunity.owner.id;
                }

                if (opportunity.assignee) {
                    opportunity.assignee = opportunity.assignee.id;
                }

                if (opportunity.account) {
                    opportunity.account = opportunity.account.id;
                }

                if (opportunity.stage) {
                    opportunity.stage = opportunity.stage.id;
                }

                if (opportunity.lead_source) {
                    opportunity.lead_source = opportunity.lead_source.id;
                }

                if (opportunity.lost_reason) {
                    opportunity.lost_reason = opportunity.lost_reason.id;
                }

                // Remove all empty arrays []
                utils.stripEmptyArray(opportunity);

                return bulkService.updateOpportunities({ filter: vm.options, update: opportunity })
                    .then(function () {

                        if ($mdMedia('gt-sm')) {
                            return $mdDialog.hide(true);
                        }

                        return vm.back();
                    }).catch(function (err) {

                        vm.updating = false;

                        $exceptionHandler(err);

                        return utils.showErrorToast();
                    });
            }).catch(function () {

                vm.updating = false;
            });
        };

        vm.searchTags = function (queryString, list) {

            if (!queryString || queryString === '') {
                return [];
            }

            return $q(function (resolve) {

                return tagsService.get({ search: queryString, limit: 50 }).then(function (response) {

                    const filteredTags = response.data.filter(function (item) {

                        return !list.some(function (tag) {

                            return item.name === tag.name;
                        });
                    });

                    // eslint-disable-next-line no-var, @hapi/no-var
                    for (var i = 0; i < filteredTags.length; ++i) {
                        if (filteredTags[i].name.toLowerCase() === queryString.toLowerCase()) {
                            const matchedTag = filteredTags[i];

                            filteredTags[i] = filteredTags[0];
                            filteredTags[0] = matchedTag;
                            break;
                        }
                    }

                    if (i >= filteredTags.length || filteredTags.length === 0) {
                        filteredTags.unshift({ name: queryString });
                    }

                    return resolve(filteredTags);
                });
            });
        };

        vm.onNewCustomFieldOption = function (customField) {

            return customfields.updateOpportunityField(customField).then(function () {

                const customFieldId = customField.id;

                return customfields.getAllOptions(customFieldId, 'opportunity').then(function (response) {

                    const foundCustomField = vm.fields.find((function (field) {

                        return field.id === customFieldId;
                    }));

                    foundCustomField.options = response.data.map(function (option) {

                        return { id: option.id, name: option.name, order: option.order };
                    });
                });
            });
        };


        function loadCustomFields() {

            return customfields.getOpportunityFields(false).then(function (response) {

                response.data = response.data.filter(function (field) {

                    return field.type.type !== 'file';
                });

                allFields = response.data;

                // Make predefined fields unique and sort them all on name
                let predefinedCustomFields = utils.unique(response.data.filter(function (field) {

                    return field.predefined_customfield && field.pipeline === vm.pipelineId;
                }), 'predefined_customfield'); // Make predefined custom fields unique
                predefinedCustomFields = $filter('orderBy')(predefinedCustomFields, 'name');

                // Add pipeline to the custom field names and remove fields from other pipelines
                response.data = response.data.filter(function (field) {

                    return !field.predefined_customfield;
                });
                response.data = response.data.filter(function (field) {

                    return field.pipeline === vm.pipelineId;
                });
                response.data = response.data.map(function (field) {

                    field.name = field.name + ' (' + field.pipelineName + ')';

                    return field;
                });
                response.data = $filter('orderBy')(response.data, ['pipeline', 'name']);

                vm.fields = [...vm.fields, ...predefinedCustomFields, ...response.data];

                vm.fields.forEach(function (customField) {

                    if (customField.type.type === 'date') {
                        customField.min_date = utils.UTCDateStringToLocalDateObject(customField.min_date);
                        customField.max_date = utils.UTCDateStringToLocalDateObject(customField.max_date);
                    }

                    // Allow unsetting the custom field
                    if (customField.type.type === 'select') {
                        customField.options = [{ name: '' }, ...customField.options];
                    }
                });

                vm.opportunity = angular.copy(defaultOpportunity);
            });
        }

        vm.stageRequiresCloseDate = (stage) => {

            return [1, 2].includes(stage?.fixed_stage);
        };
    }
})();
