(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditPersonController', EditPersonController);

    function EditPersonController($rootScope, $scope, $stateParams, $state, $q, $mdDialog, $window, $mdMedia, $timeout, $exceptionHandler, model, contact, user, me, tagsService, utils, sfDiscardDialog, customfields, network, contactsService, validateService, datasources, users, fileService, account, accounts, company, companies, flagsService, usersettings) {

        // Check where we need to get parameters from
        // This can be $stateParams when this controller gets called via a state change
        // Or the dialogParams object, when the controller was used in a $mdDialog.show()

        const params = this.dialogParams ? this.dialogParams : $stateParams;
        const returnToDialog = !!this.dialogParams;
        let resultingContactId;

        $scope.id = params.id;
        $scope.updating = false;
        $scope.isMobile = $window.isMobile;

        $scope.type = params.type ? params.type : $state.current.data.type;

        $scope.person = null;
        $scope.editable = true;
        $scope.createContact = params.createContact || $state.current.name === 'createContact';
        $scope.customFields = null;
        $scope.disableEmailField = false;
        $scope.nameClicked = false;
        $scope.isFindEmailEnabled = (flagsService.get('findEmail') === true);

        setCreditsLeftText(model.me.team.credit_quota - model.me.team.credit_usage);

        if ($scope.createContact) {
            $scope.nameClicked = true;
        }

        const removedTags = [];
        let clearDeleteTagsWatcher = null;
        let searchContactsTimeout = null;
        let searchContactsByNameTimeout = null;
        let onPhoneNumberChangedTimeout = null;

        const from = params.from;
        const accountId = params.account && params.account.id;
        const companyId = params.company && params.company.id;
        const contactName = params.contactName;
        const sideBarPersonObject = params.personObject;
        const selectedContacts = params.selectedContacts || [];
        const userEmails = [];
        const filesToDelete = [];
        let originalAccountPersonIsPartOf;

        if ($scope.createContact) {
            $scope.person = {
                name: contactName,
                email: null,
                tags: [],
                phone_numbers: [{}],
                social_profiles: [{}],
                positions: [{}],
                addresses: [{}],
                custom: {},
                picture: 'https://lib.salesflare.com/avatars/avatar_tile_a_80.png'
            };

            if (params.account) {
                $scope.person.account = params.account;
                $scope.selectedAccount = $scope.person.account;
                $scope.searchAccount = $scope.person.account.name;
            }

            if (contactName) {
                contact.splitName(contactName).then(function (result) {

                    if (result.data) {
                        $scope.person.prefix = result.data.prefix;
                        $scope.person.firstname = result.data.firstname;
                        $scope.person.middle = result.data.middle;
                        $scope.person.lastname = result.data.lastname;
                        $scope.person.suffix = result.data.suffix;

                    }

                    if ($scope.person.firstname && $scope.person.firstname.length > 0 && $scope.person.picture.includes('avatar_tile_')) {
                        let avatarTileLetter = $scope.person.firstname[0].toLowerCase();

                        if (/[^a-zA-Z0-9]/.test(avatarTileLetter)) {
                            avatarTileLetter = 'a';
                        }

                        $scope.person.picture = 'https://lib.salesflare.com/avatars/avatar_tile_' + avatarTileLetter + '_80.png';
                    }
                });
            }

            if (sideBarPersonObject) {
                Object.keys(sideBarPersonObject).map((property) => {

                    if (!sideBarPersonObject[property]) {
                        return;
                    }

                    $scope.person[property] = sideBarPersonObject[property];
                    return property;
                });
            }

            if (params.isLinkedInSuggestion && !$scope.person?.email && model.me.auto_find_email_linkedin) {
                $timeout(() => {
                    $scope.findEmail();
                }, 1000);
            }

            loadCustomFields();
        }
        else {
            get();
        }

        $scope.$on('$destroy', function () {

            if (searchContactsByNameTimeout) {
                $timeout.cancel(searchContactsByNameTimeout);
            }

            if (searchContactsTimeout) {
                $timeout.cancel(searchContactsTimeout);
            }

            if (onPhoneNumberChangedTimeout) {
                $timeout.cancel(onPhoneNumberChangedTimeout);
            }
        });

        $scope.$watch('selectedAccount', function (newlySelectedAccount) {

            if (newlySelectedAccount) {
                if (newlySelectedAccount.title) {
                    $scope.selectedAccount = null;
                    $scope.searchAccount = $scope.person.account.name;
                }
                else {
                    $scope.personForm.account.$warning.existsInNetworkAndPartOfAccount = ($scope.person.email && $scope.dupeContact && $scope.person.account && $scope.person.account.id !== $scope.dupeContact.account_id);

                    // If clearbit company create company
                    if (newlySelectedAccount.logo) {
                        $scope.person.account = {
                            name: newlySelectedAccount.name,
                            picture: newlySelectedAccount.logo,
                            website: ('https://' + newlySelectedAccount.domain)
                        };
                        return company.create(newlySelectedAccount);
                    }

                    if (newlySelectedAccount.id) {
                        $scope.person.account = newlySelectedAccount;
                        return;
                    }

                    $scope.person.account = {
                        name: newlySelectedAccount.name
                    };
                }
            }
            else {
                if ($scope.personForm && $scope.personForm.account) {
                    $scope.personForm.account.$warning = { existsInNetworkAndPartOfAccount: false };
                }

                if ($scope.person) {
                    $scope.person.account = null;
                }
            }
        });

        $scope.resetSelectedAccount = function (text) {

            $scope.selectedAccount = null;

            if (text) {
                $scope.person.account = {
                    name: text
                };
                return;
            }

            $scope.person.account = null;
        };

        $scope.getAccount = function (searchText) {

            const filterGetOptions = {
                search: searchText,
                filterRules: [
                    {
                        id: 'account.team_member.id',
                        operator: 'in',
                        value: [model.me.id]
                    }
                ]
            };

            return accounts.filterGet(filterGetOptions).then(function (response) {

                let result = [];
                const accountsResponse = response.data;

                // When an account was removed from an existing contact you should always be able to add it back even if you don't have access to the account
                if (originalAccountPersonIsPartOf && originalAccountPersonIsPartOf.name) {
                    const originalAccountNameMatchesSearch = (originalAccountPersonIsPartOf.name.toUpperCase().includes(searchText.toUpperCase()));
                    const originalAccountNameNotInResponse = (!accountsResponse.map(function (accountObject) {

                        return accountObject.name;
                    }).includes(originalAccountPersonIsPartOf.name));

                    if (originalAccountNameMatchesSearch && originalAccountNameNotInResponse) {
                        result.push({ type: 'title', title: 'Existing accounts' }, originalAccountPersonIsPartOf);
                    }
                }

                if (accountsResponse.length > 0) {
                    if (result.filter(function (r) {

                        return r.title === 'Existing accounts';
                    }).length === 0) {
                        result.push({ type: 'title', title: 'Existing accounts' });
                    }

                    result = [...result, ...accountsResponse];
                }

                result.push({ type: 'title', title: 'Create new' });

                return companies.get(searchText).then(function (companiesResponse) {

                    result = [...result, ...companiesResponse.data];
                    result.push({ name: searchText });

                    return result;
                });
            });
        };

        $scope.back = function (reload) {

            if ($scope.personForm.$dirty) {
                return sfDiscardDialog.show($scope.createContact, 'contact').then(close);
            }
            else {
                return close(reload);
            }
        };

        function setCreditsLeftText(creditsLeft) {

            $scope.creditsLeftText = (model.me.team.plan === 5 ? 'Unlimited email finder credits left' : (creditsLeft + '/' + model.me.team.credit_quota + ' email finder credits left'));
        }

        function showRanOutOfEmailCreditsDialog() {

            const emailDialogTitleAndContentMap = {
                is_not_an_admin: {
                    trial: {
                        title: 'Please ask an admin to subscribe',
                        content: 'You don\'t have the permissions to subscribe. Please ask a team admin to subscribe to your desired plan.'
                    },
                    on_pro_with_4_or_less_users: {
                        title: 'Please ask an admin to buy more credits',
                        content: 'You don\'t have the permissions to buy credits. Please ask a team admin to do this for you.'
                    },
                    on_growth_or_on_pro_with_5_or_more_users: {
                        title: 'Please ask an admin to upgrade.',
                        content: 'You don\'t have the permissions to upgrade. Please ask a team admin to upgrade to your desired plan.'
                    }
                },
                is_admin: {
                    trial: {
                        title: 'Subscribe',
                        content: 'Subscribe to find more email addresses.'
                    },
                    on_pro_with_4_or_less_users: {
                        title: 'Get more credits',
                        content: 'Get more credits to find more email addresses.'
                    },
                    on_growth_or_on_pro_with_5_or_more_users: {
                        title: 'Upgrade your plan',
                        content: 'Upgrade your plan to find more email addresses.'
                    }
                }
            };
            const adminStatus = model.me.is_admin ? 'is_admin' : 'is_not_an_admin';
            const planStatus = model.me.team.subscribed ? (model.me.team.plan === 4 && model.me.team.enabled_user_count <= 4 ? 'on_pro_with_4_or_less_users' : 'on_growth_or_on_pro_with_5_or_more_users') : 'trial';
            const title = emailDialogTitleAndContentMap[adminStatus][planStatus].title;
            const content = emailDialogTitleAndContentMap[adminStatus][planStatus].content;

            if (model.me.is_admin) {
                const getMoreCreditsDialog = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title(title)
                    .htmlContent(content)
                    .ok('Learn more')
                    .cancel('Cancel');

                return $mdDialog.show(getMoreCreditsDialog).then(function () {

                    // Teams with 5+ users are eligible for the Enterprise plan
                    if (planStatus === 'on_pro_with_4_or_less_users') {
                        return $window.open($state.href('credits'), '_blank', 'noopener');
                    }

                    return $window.open($state.href('plans'), '_blank', 'noopener');
                });
            }

            const askAdminDialog = $mdDialog.alert({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title(title)
                .htmlContent(content)
                .ok('Ok');

            return $mdDialog.show(askAdminDialog);
        }

        $scope.findEmail = function () {

            if (model.me.team.credit_usage + 1 > model.me.team.credit_quota) {
                return showRanOutOfEmailCreditsDialog();
            }

            $scope.findingEmail = true;

            return contact.findEmail({
                name: ($scope.person.firstname + ' ' + $scope.person.lastname),
                domain: $scope.person.account.website
            }).then(function (response) {

                const foundEmail = response.data.email;

                $scope.findingEmail = false;

                if (!foundEmail) {
                    return utils.showInfoToast('No quality email found. No credits used.', null, 5000);
                }

                $scope.person.email = foundEmail;

                $scope.searchContacts(); // Show warning messages when needed

                const creditsLeft = response.headers()['x-credits-remaining'];
                let message = 'Email found.';

                if (model.me.team.plan !== 5) {
                    message += (' ' + creditsLeft + '/' + model.me.team.credit_quota + ' credits remaining.');
                }

                model.me.team.credit_usage = (model.me.team.credit_quota - creditsLeft);

                setCreditsLeftText(creditsLeft);

                utils.showSuccessToast(message);

                const userCreditsUsed = response.headers()['x-user-credits-used'];

                if (!model.me.auto_find_email_linkedin && params.isLinkedInSuggestion && userCreditsUsed >= 3 && flagsService.get('showAutoFindEmailLinkedInDialog') === true) {
                    const autoFindEmailLinkedInDialog = $mdDialog.confirm()
                        .title('Automatically look for emails?')
                        .textContent('Automatically find emails for contacts you create from LinkedIn profiles. You can disable this option again via the Salesflare extension in your Chrome toolbar.')
                        .clickOutsideToClose(false)
                        .ok('Turn on')
                        .cancel('Don\'t show again');

                    return $mdDialog.show(autoFindEmailLinkedInDialog).then(function () {

                        model.me.auto_find_email_linkedin = true;

                        usersettings.update({ auto_find_email_linkedin: true });

                        return utils.showSuccessToast('Automatic email finding enabled!');
                    }).finally(function () {

                        // Update the flag so we never show the dialog again
                        return flagsService.set('showAutoFindEmailLinkedInDialog', false);
                    });
                }
            }).catch(function () {

                $scope.findingEmail = false;
            });
        };

        $scope.scanBusinessCard = function () {

            const imgOptions = {
                quality: 50,
                destinationType: Camera.DestinationType.DATA_URL,
                sourceType: Camera.PictureSourceType.CAMERA,
                allowEdit: false,
                encodingType: Camera.EncodingType.JPEG,
                targetWidth: 800,
                correctOrientation: true,
                saveToPhotoAlbum: false
            };

            return navigator.camera.getPicture(createFromBusinessCard, $exceptionHandler, imgOptions);
        };

        function createFromBusinessCard(imageData) {

            return contact.scanBusinessCard(imageData).then(function (response) {

                const person = {
                    name: null,
                    email: null,
                    tags: [],
                    phone_numbers: [{}],
                    social_profiles: [{}],
                    positions: [{}],
                    addresses: [{}],
                    custom: {},
                    picture: 'https://lib.salesflare.com/avatars/avatar_tile_a_80.png'
                };

                $scope.person = Object.assign(person, response.data);
            });
        }

        $scope.goToEmailSettings = function () {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide().then(function () {

                    return $state.go('settings.emailSettings');
                });
            }

            return $state.go('settings.emailSettings');
        };

        function close(reload) {

            // When edit person was called from an $mdDialog.show(), we need to return results via $mdMedia.hide for https://github.com/Salesflare/Server/issues/8626
            if (returnToDialog) {
                return $mdDialog.hide({ id: resultingContactId });
            }

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(reload);
            }

            // When closing the contact creation dialog in the LinkedIn flow we want to go back to the account
            // We do this via `$rootScope.back()` to easily preserve all state params like suggested contacts
            if (from && from !== 'accounts.account.info') {
                return $state.go(from, { account: accountId } );
            }

            return $rootScope.back();
        }

        $scope.save = function () {

            if (!$scope.personForm.$valid) {
                return;
            }

            if (!$scope.person.email && flagsService.get('saveContactWithoutEmailWarning') === true) {
                return $mdDialog.show({
                    controller: 'CreateContactWithoutEmailDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/createcontactwithoutemaildialog/createContactWithoutEmailDialog.html',
                    bindToController: true,
                    clickOutsideToClose: true,
                    multiple: true
                }).then(function () {

                    return savePerson();
                });
            }

            return savePerson();
        };

        function savePerson() {

            $scope.updating = true;

            const p = angular.copy($scope.person);

            // DEPRECATED
            p.addresses.forEach(function (address) {

                delete address.region;
            });

            // Filter out empty addresses
            p.addresses = p.addresses.filter(function (address) {

                return Object.keys(address).length > 0;
            });

            // Tag phone numbers that were emptied as archived
            p.phone_numbers.forEach(function (pn) {

                if (pn.id && pn._dirty && !pn.number) {
                    delete pn.number;
                    pn.archived = true;
                }

                return pn;
            });

            // Filter out empty phonenumbers
            p.phone_numbers = p.phone_numbers.filter(function (pn) {

                return !!pn.number || (pn._dirty && pn.id);
            });

            // Tag social profiles that were emptied as archived
            p.social_profiles.forEach(function (sp) {

                if (sp.id && sp._dirty && !sp.url) {
                    delete sp.url;
                    sp.archived = true;
                }

                return sp;
            });

            // Filter out empty social profiles
            p.social_profiles = p.social_profiles.filter(function (sp) {

                return !!sp.url || (sp._dirty && sp.id);
            });

            // Filter out empty positions
            p.positions = p.positions.filter(function (position) {

                return Object.keys(position).length > 0;
            });

            if ($scope.customFields) {
                $scope.customFields.forEach(function (customField) {

                    if (customField.type.type === 'select' && !customField.required) {
                        if (p.custom[customField.api_field] && !p.custom[customField.api_field].id) {
                            p.custom[customField.api_field] = null;
                        }
                    }

                    if (customField.type.type === 'date') {
                        if (customField.predefined_customfield) {
                            p[customField.api_field] = utils.localDateObjectToUTCDateObject(p[customField.api_field]);
                        }
                        else if (p.custom && Object.keys(p.custom).length > 0) {
                            p.custom[customField.api_field] = utils.localDateObjectToUTCDateObject(p.custom[customField.api_field]);
                        }
                    }
                });
            }

            if ($scope.type === 'user') {
                return updatePerson(p);
            }

            if (p.account && !p.account.id) {
                return account.create(p.account).then(function (accountCreationResponse) {

                    p.account = accountCreationResponse.data.id;

                    if ($scope.createContact) {
                        return createContact(p);
                    }

                    return updatePerson(p);
                });
            }

            if (p.account && p.account.id) {
                p.account = p.account.id;
            }
            else if (!p.account) {
                // Specifically unlink an account
                if (!$scope.createContact && originalAccountPersonIsPartOf && originalAccountPersonIsPartOf.id) {
                    p.account = null;
                }
                else {
                    // Keep account linked
                    p.account = undefined;
                }
            }

            if ($scope.createContact) {
                return createContact(p);
            }

            return updatePerson(p);
        }

        function createContact(contactObject) {

            return contact.create(contactObject).then(function (response) {

                resultingContactId = response.data.id;
                const contactStateToGoTo = (contactObject.account || ($scope.dupeContact && $scope.dupeContact.account_id)) ? 'contacts.customers.contact' : 'contacts.my.contact';

                return deleteFiles().then(function () {

                    // When edit person was called from an $mdDialog.show(), we need to return results via $mdMedia.hide for https://github.com/Salesflare/Server/issues/8626
                    if (returnToDialog) {
                        return $mdDialog.hide({ id: resultingContactId });
                    }

                    if ($mdMedia('gt-sm')) {
                        $mdDialog.hide(false);

                        if (from) {
                            if (contactObject.account !== accountId) {
                                selectedContacts.push(resultingContactId);
                            }

                            return $state.go(from, { account: accountId, selectedContacts });
                        }

                        if ($state.includes('contacts')) {
                            return $state.go(contactStateToGoTo, { id: resultingContactId }, { reload: true });
                        }

                        return utils.showSuccessToast('Contact created.', 'VIEW').then(function (toastResponse) {

                            if (toastResponse === 'VIEW') {
                                return $state.go(contactStateToGoTo, { id: resultingContactId });
                            }
                        });
                    }

                    if (from) {
                        if (contactObject.account !== accountId) {
                            selectedContacts.push(resultingContactId);
                        }

                        // This to support going back to account info when creating a contact via the LinkedIn flow
                        if (from === 'accounts.account.info' && accountId) {
                            return $state.go(from, { id: accountId });
                        }

                        // This to support going back to company info when creating a contact via the LinkedIn flow
                        if (from === 'accounts.company.info' && companyId) {
                            return $state.go(from, { id: companyId });
                        }

                        return $state.go(from, { account: accountId, selectedContacts });
                    }

                    return $state.go(contactStateToGoTo, { id: resultingContactId });
                });
            }).catch(function () {


                $scope.updating = false;
            });
        }

        function updatePerson(personObject) {

            // Unbinding the tag watcher, this way it'll not call the listener when removed tag are concatenated
            // on $scope.account.tags
            clearDeleteTagsWatcher();

            // Needed when returning to a dialog
            resultingContactId = personObject.id;

            if (removedTags.length > 0) {
                personObject.tags = [...personObject.tags, ...removedTags];
            }

            if (personObject.custom) {
                for (const key in personObject.custom) {
                    if (angular.isArray(personObject.custom[key]) && personObject.custom[key].length === 0) {
                        personObject._dirty = true;
                    }
                }
            }

            if ($scope.type === 'contact') {
                // Filter files on person object, don't send the files that are to be deleted
                personObject.files = personObject.files?.filter(function (file) {

                    return !this.includes(file.file_id);
                }, filesToDelete);

                return contact.update(personObject).then(deleteFiles).then(function () {

                    return close(true);
                }).catch(function () {

                    $scope.updating = false;
                });
            }

            if ($scope.type === 'user') {
                return me.update(personObject).then(deleteFiles).then(function () {

                    return close(true);
                }).catch(function () {

                    $scope.updating = false;
                });
            }
        }

        function redirectAfterDelete() {

            if ($mdMedia('gt-sm')) {
                $mdDialog.hide(false);

                if ($state.includes('contacts.my')) {
                    return $state.go('contacts.my', { ignoreCacheOnFetchContact: true }, { reload: true });
                }

                if ($state.includes('contacts.customers')) {
                    return $state.go('contacts.customers', { ignoreCacheOnFetchContact: true }, { reload: true });
                }

                return $state.reload();
            }

            const fromStateName = $rootScope.history[$rootScope.history.length - 2].fromState.name;
            const fromStateParams = $rootScope.history[$rootScope.history.length - 2].fromParams || params;

            if (fromStateName === 'accounts.account.info') {
                return $state.go(fromStateName, angular.merge({}, fromStateParams, { id: $scope.person.account.id }));
            }

            return $state.go(fromStateName, fromStateParams);
        }

        $scope.deleteContact = function () {

            const deleteContactDialog = ({
                clickOutsideToClose: true,
                escapeToClose: true,
                multiple: true,
                controller: 'DeleteContactDialogController',
                templateUrl: 'partials/deletecontactdialog.html'
            });

            return $mdDialog.show(deleteContactDialog).then(function (result) {

                if (result && result.showPermanentDeleteConfirmationDialog) {
                    const confirmPermanentDeleteDialog = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .textContent('Are you sure you want to permanently delete the contact?')
                        .ok('Yes')
                        .cancel('No');

                    return $mdDialog.show(confirmPermanentDeleteDialog).then(function () {

                        $scope.updating = true;

                        return contact.delete($scope.person.id, true).then(redirectAfterDelete);
                    });
                }

                $scope.updating = true;

                return contact.delete($scope.person.id).then(redirectAfterDelete);
            });
        };

        $scope.activeItems = function (items) {

            if (items) {
                return items.filter(function (item) {

                    return (!item.archived);
                });
            }
        };

        $scope.deleteItem = function (items, item) {

            if (item.id) {
                $scope.setDirty(item);

                item.archived = true;
            }
            else {
                items.splice(items.indexOf(item), 1);
            }
        };

        $scope.setDirty = function (item) {

            if (item && !$scope.createContact) {
                item._dirty = true;
                $scope.personForm.$setDirty();
            }
        };

        $scope.addItem = function (items) {

            const item = {};

            if (!$scope.createContact) {
                item._dirty = true;
            }

            items.push(item);
        };

        $scope.addTag = function (addedTag) {

            if (addedTag && addedTag.name) {
                for (let i = 0; i < removedTags.length; ++i) {
                    if (addedTag.name === removedTags[i].name) {
                        removedTags.splice(i, 1);
                        break;
                    }
                }
            }

            return $scope.setDirty(addedTag);
        };

        $scope.searchContacts = function () {

            $scope.personForm.$setDirty();

            $timeout.cancel(searchContactsTimeout);

            searchContactsTimeout = $timeout(function () {

                $scope.personForm.email.$warning = $scope.personForm.email.$warning || {};
                $scope.personForm.account.$warning = $scope.personForm.account.$warning || {};

                $scope.personForm.email.$warning.existsInNetwork = false;
                $scope.personForm.account.$warning.existsInNetworkAndPartOfAccount = false;
                $scope.personForm.email.$setValidity('exists', true);

                if ($scope.person.email) {
                    network.get({ email: $scope.person.email }).then(function (response) {

                        $scope.dupeContact = response.data[0];

                        if ($scope.dupeContact) {
                            if ($scope.createContact) {
                                $scope.personForm.email.$warning.existsInNetwork = true;
                                $scope.personForm.account.$warning.existsInNetworkAndPartOfAccount = ($scope.person.email && $scope.dupeContact && $scope.person.account && $scope.dupeContact.account_id && $scope.person.account.id !== $scope.dupeContact.account_id);
                            }
                            else {
                                $scope.personForm.email.$setValidity('exists', false);
                            }
                        }
                    });
                }
                else {
                    $scope.dupeContact = undefined;
                }

                if (userEmails.length === 0 && $scope.person.email) {
                    return users.get().then(function (response) {

                        // eslint-disable-next-line no-shadow
                        response.data.forEach(function (user) {

                            userEmails.push(user.email);

                            if (user.data_sources) {
                                user.data_sources.forEach(function (dataSource) {
                                    userEmails.push(dataSource.email);
                                });
                            }
                        });

                        return $scope.personForm.email.$setValidity('userEmail', !userEmails.includes($scope.person.email));
                    });
                }

                return $scope.personForm.email.$setValidity('userEmail', !userEmails.includes($scope.person.email));
            }, 750);
        };

        $scope.searchContactsByName = function () {

            $scope.personForm.$setDirty();

            $timeout.cancel(searchContactsByNameTimeout);

            searchContactsByNameTimeout = $timeout(function () {

                $scope.personForm.name.$warning = $scope.personForm.name.$warning || {};

                $scope.personForm.name.$warning.existsInNetwork = false;

                if ($scope.person.firstname && $scope.person.firstname.length > 0 && $scope.person.picture.includes('avatar_tile_')) {

                    let avatarTileLetter = $scope.person.firstname[0].toLowerCase();

                    if (/[^a-zA-Z0-9]/.test(avatarTileLetter)) {
                        avatarTileLetter = 'a';
                    }

                    $scope.person.picture = 'https://lib.salesflare.com/avatars/avatar_tile_' + avatarTileLetter + '_80.png';
                }

                if ($scope.person.name && $scope.person.name.length > 3) {
                    return contactsService.get(null, { name: $scope.person.name }).then(function (response) {

                        if ($scope.id) {
                            // We are editing an existing contact so we filter out the current contact id from the response if present
                            const filteredContactResponse = response.data.filter(function (item) {

                                return item.id !== $scope.id;
                            });

                            $scope.personForm.name.$warning.existsInNetwork = (filteredContactResponse.length > 0);
                        }
                        else {
                            $scope.personForm.name.$warning.existsInNetwork = (response.data.length > 0);
                        }
                    });
                }
            }, 750);
        };

        $scope.onPhoneNumberChanged = function (phoneNumber, $index) {

            $scope.setDirty(phoneNumber);

            $timeout.cancel(onPhoneNumberChangedTimeout);

            if (!phoneNumber.number) {
                return $scope.personForm['phoneNumber' + $index].$setValidity('phoneNumber', true);
            }

            onPhoneNumberChangedTimeout = $timeout(function () {

                return validateService.phoneNumber(phoneNumber.number).then(function () {

                    $scope.personForm['phoneNumber' + $index].$setValidity('phoneNumber', true);
                }).catch(function () {

                    $scope.personForm['phoneNumber' + $index].$setValidity('phoneNumber', false);
                });
            }, 250);
        };

        $scope.searchTags = function (queryString) {

            if (!queryString || queryString === '') {
                return [];
            }

            return $q(function (resolve) {

                return tagsService.get({ search: queryString, limit: 50 }).then(function (response) {

                    const qst = { name: queryString };
                    let repeatedTag = null;

                    response.data.unshift(qst);

                    const filteredTags = response.data.filter(function (item, index) {

                        if (item.name.toLowerCase() === qst.name.toLowerCase() && index !== 0) {
                            qst.id = item.id;
                            return false;
                        }

                        for (let i = 0; i < $scope.person.tags.length; ++i) {
                            if (item.name.toLowerCase() === $scope.person.tags[i].name.toLowerCase()) {
                                if (index === 0) {
                                    repeatedTag = $scope.person.tags[i];
                                }

                                return false;
                            }
                        }

                        return true;
                    });

                    if (repeatedTag) {
                        filteredTags.unshift(repeatedTag);
                    }

                    return resolve(filteredTags);
                });
            });
        };

        $scope.editPicture = function () {

            return $mdDialog.show({
                controller: 'EditPictureDialogController',
                templateUrl: 'partials/editpicturedialog.html',
                multiple: true,
                scope: $scope,
                preserveScope: true,
                locals: {
                    picture: $scope.person.picture
                },
                bindToController: true,
                fullscreen: true,
                clickOutsideToClose: true
            }).then(function (croppedImageDataURI) {

                if (croppedImageDataURI) {
                    $scope.person.picture = croppedImageDataURI;
                    $scope.personForm.$setDirty();
                }
            });
        };

        $scope.clickName = function () {

            $scope.nameClicked = !$scope.nameClicked;
        };

        $scope.updateFullName = function () {

            let fullName = '';
            if ($scope.person.prefix && $scope.person.prefix !== '') {
                fullName += $scope.person.prefix + ' ';
            }

            if ($scope.person.firstname) {
                fullName += $scope.person.firstname + ' ';
            }

            if ($scope.person.middle && $scope.person.middle !== '') {
                fullName += $scope.person.middle + ' ';
            }

            if ($scope.person.lastname) {
                fullName += $scope.person.lastname;
            }

            if ($scope.person.suffix && $scope.person.suffix !== '') {
                fullName += ', ' + $scope.person.suffix;
            }

            $scope.person.name = fullName;
            $scope.searchContactsByName();
        };

        /**
         * When we update a contact with no email with an email that is already linked to an existing contact in the team
         * We allow the user to go to that contact.
         * In order to make sure the user has access we do a post on /contacts with the email.
         *
         * @returns {undefined}
         */
        $scope.goToDupeContact = function () {

            return contactsService.create({ email: $scope.person.email }).then(function () {

                return $state.go('contact', { id: $scope.dupeContact.id });
            });
        };

        $scope.onFilesChange = function () {

            return $scope.personForm.$setDirty();
        };

        $scope.onFilesDelete = function ($event) {

            filesToDelete.push($event.fileId);
        };

        $scope.getNotPredefinedCustomFields = function () {

            if (!$scope.customFields) {
                return [];
            }

            return $scope.customFields.filter(function (field) {

                return !field.predefined_customfield;
            });
        };

        $scope.onNewCustomFieldOption = function (customField) {

            return customfields.updateContactField(customField).then(function () {

                const customFieldId = customField.id;

                return customfields.getAllOptions(customFieldId, 'contact').then(function (response) {

                    const foundCustomField = $scope.customFields.find((function (field) {

                        return field.id === customFieldId;
                    }));

                    foundCustomField.options = response.data.map(function (option) {

                        return { id: option.id, name: option.name, order: option.order };
                    });
                });
            });
        };

        $scope.isFindEmailButtonDisabled = function () {

            return (!$scope.person || !$scope.person.firstname || !$scope.person.lastname || !$scope.person.account || !$scope.person.account.website || $scope.findingEmail);
        };

        function get() {

            //Adding watcher to tags model
            clearDeleteTagsWatcher = $scope.$watch('person.tags', deleteTag, true);

            if ($scope.type === 'contact') {
                contact.get($scope.id).then(personGetHandler);
            }

            if ($scope.type === 'user') {
                if ($scope.id !== model.me.id) {
                    $scope.editable = false;
                }

                user.get($scope.id).then(personGetHandler);
            }
        }

        function personGetHandler(response) {

            response.data.phone_numbers = response.data.phone_numbers.length > 0 ? response.data.phone_numbers : [{}];
            response.data.social_profiles = response.data.social_profiles.length > 0 ? response.data.social_profiles : [{}];
            response.data.positions = response.data.positions.length > 0 ? response.data.positions : [{}];
            response.data.addresses = response.data.addresses.length > 0 ? response.data.addresses : [{}];

            $scope.person = response.data;

            $scope.selectedAccount = $scope.person.account;
            $scope.searchAccount = $scope.person.account && $scope.person.account.name;

            originalAccountPersonIsPartOf = $scope.person.account;

            $scope.disableEmailField = !!$scope.person.email;

            if (!$scope.person.custom) {
                $scope.person.custom = {};
            }

            if ($scope.type !== 'user' && $scope.person.id !== model.me.id) {
                return loadCustomFields();
            }

            return datasources.getEmailSources().then(function (emailSourcesResponse) {

                const connectedEmails = emailSourcesResponse.data.map(function (emailSource) {

                    return emailSource.email;
                });

                if (!connectedEmails.includes($scope.person.email)) {
                    connectedEmails.push($scope.person.email);
                }

                $scope.connectedEmailsOfUser = connectedEmails;

            });
        }

        function loadCustomFields() {

            return customfields.getContactFields(false).then(function (response) {

                $scope.customFields = response.data;

                utils.setCustomFieldsOnEntity($scope.person, $scope.customFields);
            });
        }

        function deleteTag(newValue, oldValue) {

            if (!$scope.createContact && newValue && oldValue && oldValue.length > newValue.length) {
                for (const [i, element] of oldValue.entries()) {
                    if (!element._dirty
                        && (angular.isUndefined(newValue[i]) || element.name !== newValue[i].name)) {
                        element._deleted = true;

                        $scope.setDirty(element);
                        removedTags.push(element);

                        break;
                    }
                }
            }
        }

        function deleteFiles() {

            return $q.all(filesToDelete.map(function (fileToDelete) {

                return fileService.deleteFile(fileToDelete);
            }));
        }
    }
})();
