(function () {
    'use strict';

    angular
        .module('salesflare.components.bulkEditContacts', [])
        .component('sfBulkEditContacts', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/bulkeditcontacts/bulkeditcontacts.html',
            bindings: {
                options: '<'
            }
        });

    function controller($rootScope, $q, $mdMedia, $mdDialog, $exceptionHandler, $filter, utils, customfields, bulkService, tagsService) {

        const vm = this;
        const defaultContact = {
            custom: {},
            added_custom: {},
            removed_custom: {}
        };

        vm.$onInit = function () {

            if (!vm.options || Object.keys(vm.options).length === 0) {
                return vm.back();
            }

            vm.$mdMedia = $mdMedia;
            vm.updating = false;
            vm.fields = [
                {
                    name: 'Tags',
                    type: {}
                }
            ];

            return loadCustomFields();
        };

        vm.resetContact = function () {

            vm.contactForm.$setPristine();

            vm.contact = angular.copy(defaultContact);

            if (['text', 'large_text', 'url'].includes(vm.selectedField.type.type)) {
                if (vm.selectedField.predefined_customfield) {
                    vm.contact[vm.selectedField.api_field] = '';
                }
                else {
                    vm.contact.custom[vm.selectedField.api_field] = '';
                }
            }

            if (['number', 'date', 'select'].includes(vm.selectedField.type.type)) {
                if (vm.selectedField.predefined_customfield) {
                    vm.contact[vm.selectedField.api_field] = null;
                }
                else {
                    vm.contact.custom[vm.selectedField.api_field] = null;
                }
            }

            if (vm.selectedField.type.type === 'boolean') {
                if (vm.selectedField.predefined_customfield) {
                    vm.contact[vm.selectedField.api_field] = false;
                }
                else {
                    vm.contact.custom[vm.selectedField.api_field] = false;
                }
            }

            if (['tags', 'account', 'accounts', 'contact', 'contacts', 'user', 'users'].includes(vm.selectedField.type.type)) {
                vm.contact.added_custom[vm.selectedField.api_field] = [];
                vm.contact.removed_custom[vm.selectedField.api_field] = [];
            }

            if (vm.selectedField.name === 'Tags') {
                vm.contact.added_tags = [];
                vm.contact.removed_tags = [];
            }
        };

        vm.back = function () {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(false);
            }

            return $rootScope.back();
        };

        vm.save = function () {

            if (!vm.contactForm.$valid) {
                return;
            }

            vm.updating = true;

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('These changes will be applied to all the selected contacts!')
                .ok('I\'m sure')
                .cancel('cancel');

            return $mdDialog.show(confirm).then(function () {

                const contact = angular.copy(vm.contact);

                vm.fields.forEach(function (customField) {

                    if (customField.type.type === 'date') {
                        if (customField.predefined_customfield && contact[customField.api_field]) {
                            contact[customField.api_field] = $filter('date')(contact[customField.api_field], 'yyyy-MM-dd');
                        }
                        else if (contact.custom && !customField.predefined_customfield && contact.custom[customField.api_field]) {
                            contact.custom[customField.api_field] = $filter('date')(contact.custom[customField.api_field], 'yyyy-MM-dd');
                        }
                    }
                });

                // Remove non needed props
                if (contact.added_tags) {
                    contact.added_tags = contact.added_tags.map(function (tag) {

                        return { id: tag.id, name: tag.name };
                    });
                }

                if (contact.removed_tags) {
                    contact.removed_tags = contact.removed_tags.map(function (tag) {

                        return { id: tag.id };
                    });
                }

                // Remove all empty arrays
                utils.stripEmptyArray(contact);

                return bulkService.updateContacts({ filter: vm.options, update: contact })
                    .then(function () {

                        if ($mdMedia('gt-sm')) {
                            return $mdDialog.hide(true);
                        }

                        return vm.back();
                    }).catch(function (err) {

                        vm.updating = false;

                        $exceptionHandler(err);

                        return utils.showErrorToast();
                    });
            }).catch(function () {

                vm.updating = false;
            });
        };

        vm.searchTags = function (queryString, list) {

            if (!queryString || queryString === '') {
                return [];
            }

            return $q(function (resolve) {

                return tagsService.get({ search: queryString, limit: 50 }).then(function (response) {

                    const filteredTags = response.data.filter(function (item) {

                        return !list.some(function (tag) {

                            return item.name === tag.name;
                        });
                    });

                    // eslint-disable-next-line no-var, @hapi/no-var
                    for (var i = 0; i < filteredTags.length; ++i) {
                        if (filteredTags[i].name.toLowerCase() === queryString.toLowerCase()) {
                            const matchedTag = filteredTags[i];

                            filteredTags[i] = filteredTags[0];
                            filteredTags[0] = matchedTag;
                            break;
                        }
                    }

                    if (i >= filteredTags.length || filteredTags.length === 0) {
                        filteredTags.unshift({ name: queryString });
                    }

                    return resolve(filteredTags);
                });
            });
        };

        vm.onNewCustomFieldOption = function (customField) {

            return customfields.updateContactField(customField).then(function () {

                const customFieldId = customField.id;

                return customfields.getAllOptions(customFieldId, 'contact').then(function (response) {

                    const foundCustomField = vm.fields.find((function (field) {

                        return field.id === customFieldId;
                    }));

                    foundCustomField.options = response.data.map(function (option) {

                        return { id: option.id, name: option.name, order: option.order };
                    });
                });
            });
        };

        function loadCustomFields() {

            return customfields.getContactFields(false).then(function (response) {

                vm.fields = [...vm.fields, ...response.data].filter(function (field) {

                    return field.type.type !== 'file';
                });

                vm.fields.forEach(function (customField) {

                    if (customField.type.type === 'date') {
                        customField.min_date = utils.UTCDateStringToLocalDateObject(customField.min_date);
                        customField.max_date = utils.UTCDateStringToLocalDateObject(customField.max_date);
                    }

                    // Allow unsetting the custom field
                    if (customField.type.type === 'select') {
                        customField.options = [{ name: '' }, ...customField.options];
                    }
                });

                vm.contact = angular.copy(defaultContact);
            });
        }
    }
})();
