(function () {
    'use strict';

    angular
        .module('salesflare.components.filesCustomfield', [])
        .component('sfFilesCustomfield', {
            templateUrl: 'app-ajs/components/customfields/filescustomfield.html',
            controllerAs: 'vm',
            controller,
            bindings: {
                customfield: '<',
                entityForm: '<',
                entity: '<',
                onFilesChange: '&',
                onFilesDelete: '&'
            }
        });

    function controller($window, customfields) {

        const vm = this;

        vm.$onInit = function () {

            // Make sure the custom field on the entity object is initialized
            if (vm.customfield.predefined_customfield) {
                if (Object.prototype.hasOwnProperty.call(vm.entity, vm.customfield.api_field) && angular.isUndefined(vm.entity[vm.customfield.api_field])) {
                    vm.entity[vm.customfield.api_field] = [];
                }
            }
            else if (Object.prototype.hasOwnProperty.call(vm.entity.custom, vm.customfield.api_field) && angular.isUndefined(vm.entity.custom[vm.customfield.api_field])) {
                vm.entity.custom[vm.customfield.api_field] = [];
            }

            vm.files = vm.customfield.predefined_customfield ? vm.entity[vm.customfield.api_field] : vm.entity.custom[vm.customfield.api_field];
        };

        vm.download = function (downloadUrl) {

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        vm.upload = function (files) {

            if (!files || files.$error) {
                return;
            }

            if (!vm.files && vm.entity) {
                vm.files = [];
                if (vm.customfield.predefined_customfield) {
                    vm.entity[vm.customfield.api_field] = vm.files;
                }
                else {
                    vm.entity.custom[vm.customfield.api_field] = vm.files;
                }
            }

            vm.showDragOverlay = false;

            files.forEach(function (file) {

                customfields.uploadFile(file, function (newFile) {

                    if (newFile) {
                        vm.files.push(newFile);
                    }
                });
            });

            return vm.onFilesChange();
        };

        vm.drag = function (isDragging) {

            vm.$apply(function () {

                vm.showDragOverlay = isDragging;
            });
        };

        vm.deleteFile = function (fileId) {

            vm.files = vm.files.filter(function (f) {

                return f.file_id !== fileId;
            });

            vm.entity.custom[vm.customfield.api_field] = angular.copy(vm.files);

            // Set the model to null if there are no files left
            // Otherwise ng-required will not work correctly
            if (vm.files.length === 0) {
                vm.files = null;
            }

            vm.onFilesDelete({
                $event: {
                    fileId
                }
            });
            return vm.onFilesChange();
        };
    }
})();
