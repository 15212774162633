(function () {
    'use strict';

    angular
        .module('salesflare')
        .constant('config', {
            env: 'production',
            mode: '',
            apiUrl: 'https://api.salesflare.com/',
            appUrl: 'https://app.salesflare.com',
            notificationsUrl: 'https://pubsub.salesflare.com:443',
            segment: {
                key: 'zVGLbGQzXGVrjNwVG6T0peeRW4moR6Ob'
            },
            growsumo: {
                key: 'pk_T2QELbgOZZDMTr5k7QlffOhI8qN1tuZM'
            },
            refiner: {
                key: '38d29720-dc72-11ea-8588-91da2dac4ccb'
            },
            gcm: 128469695999,
            debugInfoEnabled: false,
            stripe: {
                key: 'pk_live_maQUR3DOkOnkpVw8iKlQVWzr',
                plans: {
                    monthly_GROWTH_USD: 'price_1HuKZkD7dHLLR45x7OOxS81H',
                    monthly_GROWTH_EUR: 'price_1HuKZbD7dHLLR45xCDcWPf7N',
                    annually_GROWTH_USD: 'price_1HuKZED7dHLLR45xV09soTO7',
                    annually_GROWTH_EUR: 'price_1HuKZ3D7dHLLR45xAIDVSPLM',
                    monthly_PRO_USD: 'monthly_PRO_USD',
                    monthly_PRO_EUR: 'monthly_PRO_EUR',
                    annually_PRO_USD: 'annually_PRO_USD',
                    annually_PRO_EUR: 'annually_PRO_EUR'
                }
            },
            intercom: {
                appId: 'nhqftro2',
                horizontal_padding: 110,
                alignment: 'right'
            },
            zapier: {
                clientId: 'iqBeMJF5oFzkEFilfacD4Evy7oexnNneVQGNQfR7'
            },
            ycbm: {
                videoCallUrl: 'https://salesflare-video-call.youcanbook.me?noframe=true&skipHeaderFooter=true',
                phoneCallUrl: 'https://salesflare-quick-call.youcanbook.me?noframe=true&skipHeaderFooter=true'
            },
            sentry: {
                enabled: true
            },
            tracking: {
                baseUnsubscribeUrl: 'https://mail.salesfla.re/optout',
                imgUrl: 'https://pixel.salesfla.re/img/',
                oldImgUrl: 'https://api.salesflare.com/img/'
            },
            linkedIn: {
                redirectUrl: 'https://api.salesflare.com/datasources/connect?type=linkedin', // When changing this, it also needs to be changed in the LinkedIn developer console and the server
                oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization',
                clientId: '78gm3u87vvyi9l'
            }
        });
})();
