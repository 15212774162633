(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('workflowService', workflowService);

    function workflowService($q, $timeout, sfSetupPanel, $filter, sfHttp, config, filterService, model, utils) {

        // A list of filters used to get the audience for a certain workflow analytic value
        const workflowAnalyticFilters = {
            total: {
                filter: {
                    condition: 'AND',
                    rules: [] // A rule that specifies that the audience needs to be part of the workflow, is added on the server
                }
            },
            in: {
                filter: {
                    condition: 'AND',
                    rules: [{
                        id: 'workflow.exited',
                        label: 'Exited',
                        type: 'boolean',
                        input: 'binaryradio',
                        display_entity: 'Workflow record',
                        entity: 'workflow_record',
                        value: 'false',
                        operator: 'equal'
                    }]
                }
            },
            exited: {
                filter: {
                    condition: 'AND',
                    rules: [{
                        id: 'workflow.exited',
                        label: 'Exited',
                        type: 'boolean',
                        input: 'binaryradio',
                        display_entity: 'Workflow record',
                        entity: 'workflow_record',
                        value: 'true',
                        operator: 'equal'
                    }],
                    order_by: 'exit_date DESC'
                }
            },
            met_goal: {
                filter: {
                    condition: 'AND',
                    rules: [{
                        id: 'workflow.met_goal',
                        label: 'Met goal',
                        type: 'boolean',
                        input: 'binaryradio',
                        display_entity: 'Workflow record',
                        entity: 'workflow_record',
                        value: 'true',
                        operator: 'equal'
                    }],
                    order_by: 'met_goal_date DESC'
                }
            }
        };

        // A list of filters and other information, used to get audiences of certain workflow steps
        // They need to be grouped by action step type
        const workflowStepAnalyticFilters = {
            send_email: {
                tabs: ['total', 'sent', 'interacted', 'replied', 'failed'],
                tabInfo: {
                    total: [
                        {
                            id: 'total',
                            filter: {
                                condition: 'AND',
                                rules: [] // A rule that specifies that the audience needs to be part of the workflow, is added on the server
                            },
                            emptyStateText: {
                                trackingEnabled: 'No one has received your email yet, hang tight!',
                                trackingDisabled: 'No one has received your email yet, hang tight!',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-people'
                        }],
                    sent: [{
                        id: 'sent',
                        filter: {
                            condition: 'AND',
                            rules: [{
                                id: 'workflow.send_email.received',
                                value: 'done',
                                operator: 'equal',
                                label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                            }], // A rule that specifies that the audience needs to be part of the workflow step, is added on the server
                            order_by: 'sent_date DESC'
                        },
                        emptyStateText: {
                            trackingEnabled: 'No one has received your email yet, hang tight!',
                            trackingDisabled: 'No one has received your email yet, hang tight!',
                            searching: 'No contacts found.'
                        },
                        icon: 'sf-icon-email'
                    }],
                    interacted: [
                        {
                            id: 'opened',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.opened',
                                        value: 'null',
                                        operator: 'not_equal',
                                        label: 'Opened step' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    },
                                    {
                                        id: 'workflow.send_email.received',
                                        value: 'done',
                                        operator: 'equal',
                                        label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    }
                                ], // A rule that specifies that the audience needs to be part of the workflow step, is added on the server
                                order_by: 'opened_date DESC'
                            },
                            text: 'Opened',
                            emptyStateText: {
                                trackingEnabled: 'No one has opened your email yet, hang tight!',
                                trackingDisabled: 'Enable tracking for future emails to see who opened them.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-email-opened'
                        },
                        {
                            id: 'not_opened',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.opened',
                                        value: 'null',
                                        operator: 'equal',
                                        label: 'Opened step' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    },
                                    {
                                        id: 'workflow.send_email.received',
                                        value: 'done',
                                        operator: 'equal',
                                        label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    }
                                ],
                                order_by: 'opened_date DESC'
                            },
                            text: 'Haven\'t opened',
                            emptyStateText: {
                                trackingEnabled: 'Everyone has opened your email. Nice work!',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-email-opened'
                        },
                        {
                            id: 'clicked',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.clicked',
                                        value: 'null',
                                        operator: 'not_equal',
                                        label: 'Clicked step' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    },
                                    {
                                        id: 'workflow.send_email.received',
                                        value: 'done',
                                        operator: 'equal',
                                        label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    }
                                ],
                                order_by: 'clicked_date DESC'
                            },
                            text: 'Clicked',
                            emptyStateText: {
                                trackingEnabled: 'No one has clicked a link in your email yet, hang tight!',
                                trackingDisabled: 'Enable tracking for future emails to see who clicked the link in your email.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-email-opened'
                        },
                        {
                            id: 'not_clicked',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.clicked',
                                        value: 'null',
                                        operator: 'equal',
                                        label: 'Clicked step' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    },
                                    {
                                        id: 'workflow.send_email.received',
                                        value: 'done',
                                        operator: 'equal',
                                        label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                    }
                                ],
                                order_by: 'clicked_date DESC'
                            },
                            text: 'Haven\'t clicked',
                            emptyStateText: {
                                trackingEnabled: 'Everyone clicked the link in your email. Nice work!',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-email-opened'
                        }
                    ],
                    replied: [{
                        id: 'replied',
                        filter: {
                            condition: 'AND',
                            rules: [
                                {
                                    id: 'workflow.send_email.replied',
                                    value: 'true',
                                    operator: 'equal',
                                    label: 'Replied to step' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                },
                                {
                                    id: 'workflow.send_email.received',
                                    value: 'done',
                                    operator: 'equal',
                                    label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                }
                            ], // A rule that specifies that the audience needs to be part of the workflow step, is added on the server
                            order_by: 'replied_date DESC'
                        },
                        text: 'Replied',
                        emptyStateText: {
                            trackingEnabled: 'No one has replied to your email yet, hang tight!',
                            trackingDisabled: 'No one has replied to your email yet, hang tight!',
                            searching: 'No contacts found.'
                        },
                        icon: 'sf-icon-reply'
                    },
                    {
                        id: 'not_replied',
                        filter: {
                            condition: 'AND',
                            rules: [
                                {
                                    id: 'workflow.send_email.replied',
                                    value: 'false',
                                    operator: 'equal',
                                    label: 'Replied to step' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                },
                                {
                                    id: 'workflow.send_email.received',
                                    value: 'done',
                                    operator: 'equal',
                                    label: 'Step status' // Needed for humanReadableAdvancedFilterService when starting a new flow from analytics
                                }
                            ],
                            order_by: 'replied_date DESC'
                        },
                        text: 'Haven\'t replied',
                        emptyStateText: {
                            trackingEnabled: 'Everyone has replied to your email. Nice work!',
                            searching: 'No contacts found.'
                        },
                        icon: 'sf-icon-forward'
                    }],
                    failed: [
                        {
                            id: 'failed',
                            filter: {
                                condition: 'AND',
                                rules: [{
                                    condition: 'OR',
                                    rules: [
                                        {
                                            condition: 'AND',
                                            rules: [{
                                                id: 'workflow.send_email.bounced',
                                                value: 'true',
                                                operator: 'equal'
                                            }]
                                        },
                                        {
                                            condition: 'AND',
                                            rules: [{
                                                id: 'workflow.send_email.unsubscribed',
                                                value: 'true',
                                                operator: 'equal'
                                            }]
                                        },
                                        {
                                            condition: 'AND',
                                            rules: [
                                                {
                                                    id: 'workflow.send_email.received',
                                                    value: 'error',
                                                    operator: 'equal'
                                                }
                                            ]
                                        }
                                    ] // A rule that specifies that the audience needs to be part of the workflow step, is added on the server
                                }],
                                order_by: 'failed_date DESC'
                            },
                            text: 'Failed',
                            emptyStateText: {
                                trackingEnabled: 'Good news! No emails have failed yet.',
                                trackingDisabled: 'Good news! No emails have failed yet.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        },
                        {
                            id: 'not_failed',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.bounced',
                                        value: 'false',
                                        operator: 'equal'
                                    },
                                    {
                                        id: 'workflow.send_email.unsubscribed',
                                        value: 'false',
                                        operator: 'equal'
                                    }
                                ],
                                order_by: 'failed_date DESC'
                            },
                            text: 'Haven\'t failed',
                            emptyStateText: {
                                trackingEnabled: 'Mmm... all emails have failed so far.',
                                trackingDisabled: 'Mmm... all emails have failed so far.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        }, {
                            id: 'unsubscribed',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.unsubscribed',
                                        value: 'true',
                                        operator: 'equal'
                                    }
                                ],
                                order_by: 'unsubscribed_date DESC'
                            },
                            text: 'Unsubscribed',
                            emptyStateText: {
                                trackingEnabled: 'Good news! No-one has unsubscribed yet.',
                                trackingDisabled: 'Good news! No-one has unsubscribed yet.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        },
                        {
                            id: 'not_unsubscribed',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.unsubscribed',
                                        value: 'false',
                                        operator: 'equal'
                                    }
                                ],
                                order_by: 'unsubscribed_date DESC'
                            },
                            text: 'Haven\'t unsubscribed',
                            emptyStateText: {
                                trackingEnabled: 'Mmm... everyone has unsubscribed so far.',
                                trackingDisabled: 'Mmm... everyone has unsubscribed so far.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        },
                        {
                            id: 'bounced',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.bounced',
                                        value: 'true',
                                        operator: 'equal'
                                    }
                                ],
                                order_by: 'bounced_date DESC'
                            },
                            text: 'Bounced',
                            emptyStateText: {
                                trackingEnabled: 'Good news! No emails have bounced yet.',
                                trackingDisabled: 'Good news! No emails have bounced yet.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        },
                        {
                            id: 'not_bounced',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.bounced',
                                        value: 'false',
                                        operator: 'equal'
                                    }
                                ],
                                order_by: 'bounced_date DESC'
                            },
                            text: 'Haven\'t bounced',
                            emptyStateText: {
                                trackingEnabled: 'Mmm... all emails bounced so far.',
                                trackingDisabled: 'Mmm... all emails bounced so far.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        },
                        {
                            id: 'failed_sending',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.received',
                                        value: 'error',
                                        operator: 'equal'
                                    }
                                ],
                                order_by: 'failed_date DESC'
                            },
                            text: 'Failed sending',
                            emptyStateText: {
                                trackingEnabled: 'Good news! No emails have failed sending yet.',
                                trackingDisabled: 'Good news! No emails have failed sending yet.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        },
                        {
                            id: 'not_failed_sending',
                            filter: {
                                condition: 'AND',
                                rules: [
                                    {
                                        id: 'workflow.send_email.received',
                                        value: 'error',
                                        operator: 'not_equal'
                                    }
                                ],
                                order_by: 'failed_date DESC'
                            },
                            text: 'Haven\'t failed sending',
                            emptyStateText: {
                                trackingEnabled: 'Mmm... all emails failed sending so far.',
                                trackingDisabled: 'Mmm... all emails failed sending so far.',
                                searching: 'No contacts found.'
                            },
                            icon: 'sf-icon-warning'
                        }
                    ]
                }
            }
        };

        this.get = function (id) {

            const url = config.apiUrl + 'workflows/' + id;

            return sfHttp.get(url);
        };

        this.archive = function archive(id) {

            const url = config.apiUrl + 'workflows/' + id;

            return sfHttp.delete(url);
        };

        this.update = function (id, flow) {

            const url = config.apiUrl + 'workflows/' + id;

            const workflowCopy = angular.copy(flow);

            if (workflowCopy.filter && !workflowCopy.filter?.search) {
                workflowCopy.filter = filterService.cleanAdvancedFilterForHttp(workflowCopy.filter.rules);
            }

            if (workflowCopy.schedule_date) {
                workflowCopy.schedule_date = new Date(workflowCopy.schedule_date);
            }

            return sfHttp.put(url, workflowCopy).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.getActionTriggers = function () {

            const url = config.apiUrl + 'workflows/actiontriggers';

            return sfHttp.get(url);
        };

        /**
         * Returns a list of entities, where the type of entity is determined by the workflow record type
         *
         * @param {Number} id
         * @param {Object} requestConfig
         * @param {Object} requestConfig.filter
         * @param {Number} requestConfig.limit
         * @param {Number} requestConfig.offset
         * @param {String} requestConfig.orderBy
         * @param {Object} options
         * @param {Boolean} options.returnCountOnly
         * @param {Boolean} options.includeCount
         * @returns {Promise.<Array.<Object>>}
         */
        this.getAudience = function (id, requestConfig, options) {

            const url = config.apiUrl + 'workflows/' + id + '/audience';

            const filterCopy = angular.copy(requestConfig.filter);

            const request = {
                params: {
                    q: filterService.cleanAdvancedFilterForHttp(filterCopy)
                }
            };

            if (requestConfig.filter?.search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.q.search = requestConfig.filter.search === '' ? undefined : requestConfig.filter.search;
            }

            if (requestConfig.limit) {
                request.params.limit = requestConfig.limit;
            }

            if (requestConfig.offset) {
                request.params.offset = requestConfig.offset;
            }

            if (requestConfig.orderBy) {
                request.params.q.order_by = requestConfig.orderBy;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(url, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(url, request);
        };

        this.getStep = function (stepId) {

            const url = config.apiUrl + 'workflows/steps/' + stepId;

            return sfHttp.get(url);
        };


        /**
         * Returns a list of entities, where the type of entity is determined by the workflow record type
         *
         * @param {Number} workflowId
         * @param {Number} stepId
         * @param {Object} requestConfig
         * @param {Object} requestConfig.filter
         * @param {Number} requestConfig.limit
         * @param {Number} requestConfig.offset
         * @param {Object} options
         * @param {Boolean} options.returnCountOnly
         * @param {Boolean} options.includeCount
         * @returns {Promise.<Array.<Object>>}
         */
        this.getStepAudience = function (workflowId, stepId, requestConfig, options) {

            const url = config.apiUrl + 'workflows/' + workflowId + '/steps/' + stepId + '/audience';

            const filterCopy = angular.copy(requestConfig.filter);

            const request = {
                params: {
                    q: filterService.cleanAdvancedFilterForHttp(filterCopy)
                }
            };

            if (requestConfig.filter?.search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.q.search = requestConfig.filter.search === '' ? undefined : requestConfig.filter.search;
            }

            if (requestConfig.limit) {
                request.params.limit = requestConfig.limit;
            }

            if (requestConfig.offset) {
                request.params.offset = requestConfig.offset;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(url, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(url, request);
        };

        this.updateWorkflowRecord = function (workflowId, recordId, payload, apiOptions) {

            const url = config.apiUrl + 'workflows/' + workflowId + '/audience/' + recordId;

            const apiConfig = {};

            if (apiOptions) {
                apiConfig.noToast = apiOptions.noToast;
                apiConfig.noToastForStatusCode = apiOptions.noToastForStatusCode;
            }

            return sfHttp.put(url, payload, apiConfig);
        };

        this.addToIndividualRecordFilter = (workflowId, filter) => {

            const cleanedFilter = filterService.cleanAdvancedFilterForHttp(filter);

            return sfHttp.post(`${config.apiUrl}workflows/${workflowId}/records`, { filter: cleanedFilter });
        };

        /**
         * Picks a function to get the workflow step metrics depending on the action type of the step
         *
         * @param {{}} step
         * @returns {[{ icon: String, text: String, tooltip: String}]} Array containing metrics data
         */
        this.getStepTypeMetrics = function (step) {

            let metricsArray = [];
            const stepData = step.metrics_data;

            if (angular.isDefined(stepData) && angular.isDefined(step.action_type)) {
                switch (step.action_type) {
                    case 'send_email':
                        if (
                            angular.isUndefined(stepData.creation_date) ||
                            angular.isUndefined(stepData.creator) ||
                            angular.isUndefined(stepData.tracked)
                        ) {
                            break;
                        }

                        metricsArray = getEmailMetrics(stepData.creation_date, stepData.creator, stepData.tracked, stepData.last_sent_date);
                        break;
                    default:
                        break;
                }
            }

            return metricsArray;
        };

        // METRICS START

        /**
         * Returns information to display above a workflow step, on the workflow details view
         *
         * @param {Date} creationDate
         * @param {{name: String}} creator
         * @param {Boolean} tracked
         * @param {Date} lastSentDate
         * @returns {[{name: String, icon: String, text: String, tooltip: String}]}
         */
        function getEmailMetrics(creationDate, creator, tracked, lastSentDate) {

            return [
                {
                    name: 'creation-date',
                    icon: 'sf-icon-calendar',
                    text: 'Created ' + $filter('timeFromNow')(creationDate) + ' by ' + creator.name, // Creation date should be shown as timefromnow filter
                    tooltip: $filter('date', 'MMMM d, yyyy \'at\' h:mma')(creationDate)
                },
                {
                    name: 'tracking-status',
                    icon: 'sf-icon-email-opened',
                    text: tracked ? 'Email tracking on' : 'Email tracking off'
                },
                {
                    name: 'last-sent',
                    icon: 'sf-icon-send',
                    text: lastSentDate ? 'Last sent ' + $filter('timeFromNow')(lastSentDate) : 'No emails have been sent yet',
                    tooltip: lastSentDate ? $filter('date', 'MMMM d, yyyy \'at\' h:ma')(lastSentDate) : ''
                }
            ];
        }
        // METRICS END

        // Returns the filter associated with a certain analytic value (e.g. the filter for the 'interacted' tab)
        this.getActiveFilter = function (currentTab) {

            return workflowAnalyticFilters[currentTab];
        };

        // TODO: Set correct title for empty states (should happen in a generic way instead of specifying in the html, use options object?)
        // Used to get all the information needed on the workflow step analytic view, for a certain metric (e.g. 'interacted' for the 'send_email' action type)
        this.getActionTypeTabInfo = function (actionType) {

            return workflowStepAnalyticFilters[actionType].tabInfo;
        };

        // Gets a list of all the possible metrics for a certain workflow step action type
        this.getStepTabs = function (actionType) {

            return workflowStepAnalyticFilters[actionType].tabs;
        };

        // When creating analytics for a new workflow step action, don't forget to add a total property on the object (server side)
        // This will be used to calculate analytic data that needs be represented as a percentage
        // The name of the data can be different than total, the analytic object property name just needs to be total
        // e.g. analytics = { total: {name: 'entered', value: '132', type: 'absolute'} }
        //
        // This function turns absolute analytic values into percentages where necessary
        this.prepareAnalyticData = function (analytics) {

            if (angular.isUndefined(analytics) || angular.isUndefined(analytics.total)) {
                return analytics;
            }

            const analyticNames = Object.keys(analytics);
            const totalAmount = analytics.total.value;
            const preparedAnalytics = {};
            for (const i in analyticNames) {
                const name = analytics[analyticNames[i]].name;
                let value = analytics[analyticNames[i]].value;
                const type = analytics[analyticNames[i]].type;
                if (type === 'percentage') {
                    value = $filter('number')((value / (totalAmount || 1)) * 100, 0);
                }

                preparedAnalytics[analyticNames[i]] = {
                    name,
                    value,
                    type
                };
            }

            return preparedAnalytics;
        };

        this.sendTestEmail = function (data) {

            const url = config.apiUrl + 'workflows/sendtest';
            const testData = {
                from: data.from,
                data_source: data.data_source,
                to: data.to,
                subject: data.subject,
                body: data.body,
                filter: data.filter,
                individual_record_filter: data.individual_record_filter,
                tracked: data.tracked,
                attachments: data.attachments,
                mergeInSampleContactData: data.mergeInSampleContactData
            };

            utils.showInfoToast('Sending test email');

            return sfHttp.post(url, testData).then(function () {

                return utils.showSuccessToast('A test email has been sent');
            });
        };
    }
})();
